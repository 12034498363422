.modal-okay-cblack {
    width: unset !important;
    height: fit-content !important;
    padding: 0 !important;
    background: #141414 !important;
    border: 1px solid #ffffff !important;
    border-radius: 35px !important;
    color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    -webkit-box-shadow: none;

    &.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
        background: #141414 !important;
        text-shadow: none;
        opacity: 0.5 !important;
        box-shadow: none;
        -webkit-box-shadow: none;
    }

    &:hover {
        background: #141414 !important;
        border: 1px solid #ffffff !important;
        color: #ffffff !important;
    }

    &:focus {
        outline: none !important;
        box-shadow: none;
        -webkit-box-shadow: none;
        color: #ffffff !important; /* Ensure text color remains white on focus */
    }

    &.square {
        border-radius: 4px !important;

        &:hover {
            background: #141414 !important;
            border: 1px solid #ffffff !important;
            color: #ffffff !important;
        }

        &.invert {
            background: #1f1f1f !important;
            border: 1px solid #ffffff !important;
            color: #ffffff !important;
        }

        &.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
            background: #141414 !important;
            opacity: 0.5 !important;
            text-shadow: none;
            box-shadow: none;
            -webkit-box-shadow: none;
        }

        &.ant-tooltip-disabled-compatible-wrapper {
            background: #141414 !important;
            opacity: 0.5 !important;
            text-shadow: none;
            box-shadow: none;
            -webkit-box-shadow: none;
        }

        &.font-14 {
            font-size: 14px !important;
            line-height: 120%;
            padding: 11px 32px !important;
            font-family: Manrope;
            font-style: normal;
            font-weight: 500;
            vertical-align: middle;
            display: flex;
            align-items: center;
            text-align: center;
            text-transform: capitalize;

            &.pd {
                padding: 13px 75px !important;

                @media (max-width: 1366px) {
                    padding: 13px 25px !important;
                    font-size: 12px !important;
                }

                @media (max-width: 1440px) {
                    font-size: 14px !important;
                    padding: 13px 50px !important;
                }
            }
        }
    }
}

#uniqueSidebar .ant-menu-item{
    display: flex !important;
    align-items: center !important;
    height: 22px !important;
    margin-top: 10px ;
    margin-bottom: 10px ;
  padding-left: 24px;    
}
.home-menu-item {
  align-items: baseline !important;
}
#uniqueSidebar .ant-dropdown-menu {
padding-top: 0px !important;
}
// .ant-menu-item-selected {
//   border-left: 3px solid #D93025 !important;
//   padding-left: 11px !important;
//   margin-left: 10px !important;
//   width: auto !important;
//   border-top-left-radius: 2px !important;
//   border-bottom-left-radius: 2px !important;
// }

.gx-app-layout {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  .gx-app-header {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
  }
  
  .gx-app-main {
    display: flex;
    margin-top: 60px;
    height: calc(100vh - 60px);
  }
  
  .gx-layout-content {
    flex: 1;
    padding: 0px 20px 10px 20px;
    overflow: auto;
  }
  .customer-main-layout-pd-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  
#uniqueSidebar{
    flex:none !important;
    max-width: none !important;
    min-width: auto !important;
    width: 165px !important;
    z-index: 100;
    position: relative;
    background: #fff;
    margin: 0;
    min-height: calc(100vh - 75px);
}


.create-new-button-container {
  margin-top: 20px;
  width: 100%;
  text-align: center;
}

.create-new-button {
  margin-left: 6px;
  margin-right: 20px;
  width: calc(100% - 40px);
}