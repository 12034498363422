.scene-render-lifestyleimage-viewer-modal-twod-images {
  top: 2% !important;
  /* Adjust the top position if needed */

  //height: 50vh;
  //width: 100%;

  .ant-modal-content {
    height: 100% !important;
    //width: 100%;
  }

  .ant-modal-body {
    height: 100%;
    padding: 0px 20px 10px 20px;
    //overflow-y: auto;
  }

  .modal-content {
    display: flex;
    align-items: flex-start;
    /* Align items at the start of the container */
    //padding: 20px;
  }

  .image-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* Two columns with equal width */
    gap: 10px;
    /* Gap between grid items */
    max-height: calc(65vh - 40px);
    /* Set the maximum height for the scrollable area, subtracting padding and margins */
    overflow-y: auto;
    /* Enable vertical scrolling */
    width: 20%;
    /* Adjust width as needed */
    margin-right: 10px;
    /* Space between image grid and selected image */
  }

  .image-grid-item {
    width: 100%;
    /* Full width of each grid item */
    padding-top: 100%;
    /* Maintain aspect ratio for square grid items (1:1 ratio) */
    position: relative;
  }

  .grid-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    /* Ensure images take full width of their container */
    height: 100%;
    /* Maintain aspect ratio */
    object-fit: cover;
    /* Cover the container maintaining aspect ratio */
    cursor: pointer;
    /* Add cursor pointer */
  }

  .selected-image-container {
    width: 80%;
    /* Adjust width of the container for selected image */
    display: flex;
    flex-direction: column;
    /* Stack image and info vertically */
    align-items: center;
    position: relative;
    /* Ensure proper stacking of image and overlay */
    padding-left: 20px;
    /* Add padding on the left */
  }

  .image-info {
    background-color: rgba(255, 255, 255, 0.7);
    /* Semi-transparent white background */
    padding: 10px;
    border-radius: 5px;
    text-align: left;
    /* Align text to the left */
    width: 100%;
    /* Occupy full width */
    box-sizing: border-box;
    /* Include padding in width calculation */

    h3,
    p {
      margin: 0;
      line-height: 1.2;
      /* Adjust line height as needed */
    }

    p {
      margin-top: 5px;
    }
  }

  .selected-image {
    max-width: 100%;
    max-height: calc(65vh - 40px);
    /* Set the maximum height for the selected image, subtracting padding and margins */
    object-fit: cover;
    /* Ensure the selected image fits inside its container */
    margin-top: 20px;
    /* Add margin between image and info */
  }
}

.life-style-images-carousel-modal {
  .image-list-col {
    .image-list {
      display: flex;
      flex-direction: column;

      .image-item {
        margin-bottom: 16px;
        cursor: pointer;
        position: relative;

        img {
          max-width: 100%;
          height: auto;
        }

        .delete-button {
          position: absolute;
          top: 8px;
          right: 8px;
        }

        &.selected {
          border: 2px solid #1890ff;
        }
      }
    }
  }

  .carousel-col {
    .carousel-content {
      display: flex;
      align-items: center;

      .carousel-nav-button {
        background: transparent;
        border: none;
        font-size: 24px;
        color: #1890ff;
        cursor: pointer;

        &:hover {
          color: #40a9ff;
        }
      }

      .carousel {
        flex: 1;

        .carousel-card {
          .image-magnify {
            width: 100%;
          }
        }
      }
    }
  }
}

.life-style-images-carousel-modal {
  .image-list {
    .image-item {
      position: relative;
      cursor: pointer;

      img {
        width: 100%;
        height: auto;
        border-radius: 8px;
      }

      &.selected {
        border: 2px solid #1890ff;
        /* Example selected border color */
      }

      .delete-button {
        position: absolute;
        top: 8px;
        right: 8px;
        z-index: 10;
      }
    }
  }

  .carousel-content {
    display: flex;
    align-items: center;

    .carousel-nav-button {
      border: none;
      background: none;
      font-size: 24px;
      color: #000;

      &:hover {
        color: #1890ff;
      }
    }

    .carousel {
      flex: 1;

      .carousel-card {
        padding: 0;

        .image-magnify {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  .image-list-col {
    overflow-y: auto;
    height: 100%;
    width: 200px;
    border-right: 1px solid #ddd;
    /* Example border */
  }

  .carousel-col {
    .carousel-content {
      display: flex;
      align-items: center;
    }
  }
}


.image-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.image-item {
  position: relative;
  border: 2px solid transparent;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  transition: border-color 0.3s ease-in-out;
}

.image-item.selected {
  border-color: #1890ff; // Ant Design primary color
}

.image-item img {
  width: 100%;
  height: auto;
  display: block;
}

.delete-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: rgba(255, 255, 255, 1);
  }
}

.carousel-col {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.carousel-content {
  position: relative;
  width: 100%;
}

.carousel-nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }

  &:first-child {
    left: 10px;
  }

  &:last-child {
    right: 10px;
  }
}

.lifestyle-carousel-image {
  //width: 100% !important;
  //height: calc(90vh - 140px) !important;
  // margin: auto;
  //padding-top: 0px;
  object-fit: contain;
  width: 100%;
  height: 80vh;
}

.lifestyle-carousel-card {
  // margin-bottom: 16px;
  // margin-right: 16px;
  //height: calc(80vh - 150px) !important;
  text-align: center;
  border-radius: 4px;
  /////////////////////
  //margin: 8px; /* Space around each item */
  box-sizing: border-box;
  /* Include padding and border in item's total width */
  position: relative;
  /* Positioning context for absolute buttons */
  overflow: hidden;
  /* Ensures buttons don't overflow item */
  height: 72vh;
  /* Limit item height */
  object-fit: contain;

  .ant-card-body {
    height: 100%;
  }
}

/* Loader Styles */
.loader-container {
  position: absolute;
  /* Position the loader absolutely inside the card */
  top: 50%;
  /* Center it vertically */
  left: 50%;
  /* Center it horizontally */
  transform: translate(-50%, -50%);
  /* Center the loader exactly */
  z-index: 10;
  /* Make sure the loader is on top of other content */
}

.lifestyle-image-collaborate-icon {
  align-items: center;
  padding: 5px;
  text-align: center;
  width: 120px;
  display: flex;
  cursor: pointer;
  position: absolute;
  top: 20px;
  z-index: 1;
  left: 20px;
  border-radius: 4px;
  background: #262626;

  //box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
  &.right {
    right: 8px;
  }

  &.green {
    background: #52C41A;
  }

  &.white {
    background: white;
    //box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.08);
  }
}

.overlay-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 20px;
  right: 20px;
  // width: calc(100% - 40px);
  z-index: 1;
  gap: 10px;
}

.lifestyle-image-expand-modal {
  padding: 8px;
  font-size: 24px;
  cursor: pointer;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
}


.text-white {
  color: white;
}

.resolutions-tab {
  background: #ffff;
  padding: 8px;

  .resolutions-tab-span {
    margin: 5px;
  }
}

.carousel-index-display {
  position: absolute;
  bottom: 0;
  left: 100;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  /* Adjust background color and opacity as needed */
  color: white;
  /* Text color */
  padding: 5px 10px 5px 10px;
  /* Padding around the text */
  display: flex;
  justify-content: center;
  /* Center align text horizontally */
  align-items: center;
  /* Center align text vertically */
  text-align: center;
  /* Center align text within the banner */
  pointer-events: none;
  /* Prevent the banner from capturing mouse events */
  z-index: 1;
  /* Ensure the banner is above the image */
  width: auto;
  border-radius: 4px;
  margin: 20px;
}

// .carousel-index-display-text {
//   margin: 0;
//   font-size: 10px; /* Adjust font size */
//   white-space: nowrap; /* Prevent text from wrapping */
// }

.lifestyle-img-auto-white-switch {
  // position: absolute;
  // top: 65px;
  // left: 20px;
  z-index: 1;
  display: flex;

  .ant-switch-checked {
    background-color: rgba(0, 0, 0, 0.8) !important;
    min-width: 55px;
    height: 26px;

    .ant-switch-handle {
      top: 4px;
    }

    .ant-switch-handle::before {
      background-color: rgb(255, 255, 255) !important;
    }
  }

  .ant-switch {
    .ant-switch-handle::before {
      background-color: rgb(0, 0, 0);
    }

    .ant-switch-handle {
      top: 4px;
    }

    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 1px 1px rgba(0, 0, 0, 0.2);
    min-width: 55px;
    height: 26px;
  }
}


.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #f5f5f5;
  //color: #000;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  color: #8c8c8c;
}

.ant-picker-range {
  background-color: #f5f5f5;
}

.ant-picker-input {
  color: #8c8c8c;
}

.img-details-container {
  position: absolute;
  left: 0px;
  bottom: 0px;
  margin-left: 20px;
  margin-bottom: 20px;
  background: #FFFFFF;
  border-radius: 4px;
  padding: 8px;
  line-height: 100%;
  border: 1px solid #E3E2E2;
}

.img-modal {
  .ant-modal-body {
    min-height: 256px;
  }

  .ant-modal-close-icon {
    color: #FFFFFF;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 2px;
    padding: 5px;
  }
}

.img-loader {
  height: 100%;
  min-height: 256px;
  background: white;
}

.render-img {
  max-height: 90vh;
  min-width: 25vw;
}

.ant-select-tree-title {
  color: black !important;
  /* Override the default grey color */
}

.menu-item-disabled {
  color: #ccc;
  /* Greyed out color */
  pointer-events: none;
  /* Disable click events */
  opacity: 0.5;
  /* Reduced opacity */
}

.image-banner {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Adjust background color and opacity as needed */
  color: white;
  /* Text color */
  padding: 10px;
  /* Padding around the text */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* Center align text horizontally */
  align-items: center;
  /* Center align text vertically */
  text-align: center;
  /* Center align text within the banner */
  pointer-events: none;
  /* Prevent the banner from capturing mouse events */
  z-index: 1;
  /* Ensure the banner is above the image */
  width: 100%;
}

.image-banner-text {
  margin: 0;
  // font-size: 10px; /* Adjust font size */
  white-space: nowrap;
  /* Prevent text from wrapping */
}

.justified-grid-gallery {
  --space: 4px;
  --min-height: 190px;
  --last-row-background: rgb(188, 234, 153);

  display: flex;
  flex-wrap: wrap;
  gap: var(--space);
  list-style: none;
  margin: 0 !important;
  padding: 0 !important;
}

.justified-grid-gallery>* {
  position: relative;
  overflow: hidden;
  margin: 0 !important;
  padding: 0 !important;
}

.justified-grid-gallery>*>img {
  position: absolute;
  width: 100%;
  height: 100%;
}

.justified-grid-gallery::after {
  content: " ";
  flex-grow: 1000000000;
  //background: var(--last-row-background);
}

.image-setting-feedback {
  width: 70px;
  height: 70px;
  border-radius: 4px;
  margin-right: 10px;
  object-fit: scale-down;
}

.ReactGridGallery_tile-viewport {
  border-radius: 4px;
  border: 1px solid rgba(204, 204, 204, 1);

}


.ReactGridGallery_custom-overlay {
  opacity: 1 !important;
}

.pointer-event-auto {
  pointer-events: auto !important;
}


.custom-overlay {
  position: relative;
  width: 100%;
  height: 100%;
}

.hover-info {
  position: absolute;
  bottom: 0px;
  /* Adjust as needed */
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 0;
  width: 100%;
  opacity: 0;
  transition: opacity 0.3s ease;
  // pointer-events: none;
}

.custom-overlay:hover .hover-info {
  opacity: 1;
}

.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 9999;
  /* Ensure it is above all other elements */
}

.fullscreen .ant-carousel {
  height: 100%;
}

.fullscreen-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
  /* Ensure it's above the carousel */
}

.fullscreen .lifestyle-carousel-card {
  height: 90vh; // Full height in fullscreen
}

.image-info-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* Ensures everything aligns vertically */
  width: 100%;
  margin-bottom: 10px;
}

.image-info-left {
  display: flex;
  align-items: center;
  /* Ensure elements in the left section align vertically */
  gap: 20px;
}

.image-info-size,
.image-info-date {
  display: flex;
  align-items: center;
  /* Ensure vertical alignment within each section */
}

.white-balance-toggle {
  display: flex;
  align-items: center;
  /* Ensures vertical alignment of the switch and its label */
  gap: 10px;
}

.white-balance-title {
  margin-right: 10px;
}

.clamp-text-400 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.button-container {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  /* Distribute items to the edges */
  align-items: center;
  /* Align items vertically */
  width: 100%;
  /* Ensure it spans the available width */
}

.left-buttons,
.right-buttons {
  display: flex;
  gap: 10px;
  /* Space between buttons */
}

.left-buttons {
  justify-content: flex-start;
  /* Align items to the left */
}

.right-buttons {
  justify-content: flex-end;
  /* Align items to the right */
}

.date-picker {
  font-family: Manrope;
  font-family: 14px;
  .ant-picker-large {
    padding: 10.5px 11px 10.5px;
  }
}

.twod-image-info-more{
  background: rgba(255, 255, 255, 1);
  border-radius: 2px;
  padding: 3px 4px 3px 4px;
  cursor: pointer;
  // position: absolute;
  // top: 12px;
  z-index: 1;
  // right: 12px;
  border-radius: 4px;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
}


.fav-and-more-icons {
  display: flex;
  flex-direction: row-reverse;
  padding-top: 12px;
  padding-right: 10px;
}

.view-360-icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 60px;
  height: 60px;
  z-index: 1;
  opacity: 0.8;
}

.ai-overlay {
  position: absolute;
  z-index: 10;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FAFAFAE5;
  height: 40px;
  border: 1px solid #80808061;
  border-radius: 4px;
  border-top: none;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  .ai-overlay-text {
    background: linear-gradient(to right, #7883DC, #276DD7, #91D5FF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;  
    img {
      height: 12px;
    }
  }
}

#container-360 {
  height: 100%;
}