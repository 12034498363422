.scene-controls {
  //max-height: calc(100vh - 300px);
  max-height: calc(100vh - 370px);
  overflow-y: hidden;
  // max-width: 350px;
  overflow-x: hidden;
}
/* width */
.scene-controls-card::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.scene-controls-card::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.scene-controls-card::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.81);
  border-radius: 16px;
}

/* Handle on hover */
.scene-controls-card::-webkit-scrollbar-thumb:hover {
  background: rgba(85, 85, 85, 0.73);
}
