.transparent-btn {
    background-color: transparent;
    border: 1px solid #595959;
    color: #595959;
    height: 44px;
}

.ai-refine-modal {
  width: 580px !important;
}

.button-blue {
    background-color: #276DD7 !important;
    border: 1px solid #276DD7;
    color: #fff;
    height: 44px;
}

.ai-modal-btn-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    gap: 10px;
}

.transparent-btn-blue {
    background-color: transparent;
    border: 1px solid #276DD7;
    color: #276DD7;
    height: 44px;
}

.ai-prompt-input {
    padding: 12px;
    margin-bottom: 20px;
}

////////////////////// AI PRODUCT BADGE //////////////////////

.ai-badge {
    background: linear-gradient(to right, #7883DC, #276DD7, #4ab4f6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;    
  }

  .ai-badge-container {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
    gap: 0px;
    width: 60px;
    padding: 3px;
    padding-right: 6px;
    border-radius: 6px;
    background-color: #f3f3f3;

    img {
      height: 16px;
    }
  }

  .transparent {
    background-color: transparent !important;
  }

  .ai-badge-container-absolute {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 1000;
  }

////////////////////// AI PRODUCT BADGE //////////////////////
