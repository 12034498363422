#generate-title {
    color: var(--character-title-85, rgba(0, 0, 0, 0.85));
    text-align: center;
    font-family: Manrope;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    /* 140% */
}

#generate-options  {
    // max-width: 698px;
    border-radius: 8px;
    border: 1px solid var(--Neutral-5, #D9D9D9);
    background: var(--character-primary-inverse, #FFF);

    /* drop-shadow/button-secondary */
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
    display: flex;
    height: 70px;
    padding: 4px 0px;
    justify-content: space-around;
    align-items: center;
    align-self: stretch;
    color: black;
}

.divider {
    width: 1px;
    height: 40px;
    background: #D9D9D9;
}


.ai-background-container {
    background-image: url('/img/ai_creator/Banner.png');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    color: white;
    padding: 0% 0% 0 0%;
    flex-wrap: nowrap;
    flex-direction: column;

    @media (max-width: 990px) {
        padding: 5% 0% 0 0%;
        justify-content: space-around;
        flex-wrap: wrap;
    }
}

.ai-content-container {
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.upload-btn-container {
    align-items: center;
    margin: 0 25%;
    background: #FFFFFF;
    text-align: center;
    padding: 16px 16px;
    border-radius: 8px;
    border: 1px solid #D9D9D9;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.016);
}

.tip-container {
    cursor: pointer;
    width: fit-content;

    padding: 1px 16px 1px 16px;
    gap: 16px;
    border-radius: 32px;
    background: rgba(230, 247, 255, 1);
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.043);

    &:hover {
        background: white;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    }
}


.btn-container {
    margin-top: 10px;
    padding: 0 25%;
}

.img-grid-container {
    align-items: center;
    background: #FFFFFF;
    text-align: center;
    padding: 16px 16px;
    border-radius: 8px;
    border: 1px solid #D9D9D9;
    // display: flex;
    justify-content: space-between;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.016);
}


.tips-popover {
    .ant-popover-inner-content {
        width: fit-content;
        border-radius: 4px;
        background: white;
        padding: 12px;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    }
}

.image-grid-padding {
    padding: 0 30px;
}

.blue-on-hover {
    cursor: pointer;
}

.blue-on-hover:hover {
    color: #276DD7
}

.blue-on-hover:hover .ai-upload-image {
    color: #276DD7
}

.ant-upload.ant-upload-drag.upload-height-170 {
    height: 170px;
}

.ant-upload.ant-upload-drag.dragger-height-132 {
    height: 132px;
}

.file-list-container {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.ai-uploaded-file-card {
    width: 132px;
    height: 132px;
    border: 1px solid #d9d9d9;
    background: white;
    border-radius: 8px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    overflow: hidden;

    &.selected {
        border: 2px solid #276DD7;
    }
}

.ai-file-card-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}

.product-grid-row-product {
    height: calc(100vh - 250px);
    overflow-y: scroll;
}

.add-product-modal-ai {
    top: 20px;
    width: 100% !important;
    height: 90% !important;
  
    .ant-modal-footer {
      padding: 0 !important;
      padding: 0px 30px 30px 30px !important;
      border-top: none !important;
    }
  
    .ant-modal-body {
      height: 70%;
    }
  }


.product-card-ai {
    height: 412px;
    margin: 10px;
    cursor: pointer;
}

.activity-product-card {
    object-fit: contain;
    width: 101px;
    height: 96px;
}


 
.your-activity-img{
    object-fit: cover;
    width: 100% !important;
    height: 240px !important;
}

.your-activity-card {
    // max-width: 200px;
    cursor: pointer;
    margin-right: 16px;

    border: unset;
    .ant-card-body {
        .ant-image {
            border: 1px solid #c4c4c4;
            border-radius: 4px;
        }
    }

    &.border {
        border: 1px solid #c4c4c4;
    }
    
    

    &.loading {
        height: 250px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px
    }
}
  
.activity-progress {
    padding: 20px 0px;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 20px;

    .activity-progress-indicator {
        width: 90%;

        .ant-progress-text {
            display: none;
        }
    }
}

.pdr-8 {
    padding-right: 8px;
}

.generate-lifestyle-button {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    height: 50px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ai-loader-custom {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 400px);
    font-size: 52px;
    color: grey;
}

.capitalize-first-word::first-letter {
    text-transform: uppercase;
}


.image-wrapper {
    position: relative;
    display: inline-block;
    cursor: default;
  }
  
.ai-img-delete-button {
    position: absolute;
    bottom: 8px;
    right: 8px;
    background-color: white;
    border: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.ai-uploaded-file-card:hover .ai-img-delete-button {
    opacity: 1;
}
  

.failed-ai-job-container {
    padding: 55px 0px;
    border-radius: 4px;
    border: 1px solid #CCCCCC;
    padding-right: 18px;
}

.failed-ai-job-textual-content{
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.failed-ai-job-button-container {
    display: flex;
    justify-content: center;
    gap: 16px;
}

.ai-failed-job-button {
    border-radius: 4px !important;
    border: 1px solid #D9D9D9 !important;
    transition: all 0.3s ease;
}
