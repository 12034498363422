.home-txt-heading {
    font-size: 24px;
    font-weight: 600;
    font-family: Manrope;
    line-height: 24px;
    color: black;
    margin-top: 25px;
    margin-bottom: 25px;
}

.stats-txt-heading {
    font-size: 18px;
    font-weight: 400;
    font-family: Manrope;
    line-height: 28px;
    color: black;
}

.view-btn {
    font-size: 14px;
    font-weight: 600;
    font-family: Manrope;
    line-height: 22px;
    color: #276DD7;
    &.black {
        color: #000000;
    }
    &.green {
        color: #237804;
    }
}
  
.view-btn.black::before {
    content: "● ";   
    color: red;
}

.info-text {
    font-size: 16px;
    font-weight: 400;
    font-family: Manrope;
    line-height: 20px;
    color: #595959;
    &.size-14 {
        font-size: 14px;
    }
    &.w-600 {
        font-weight: 600;
    }
    &.black {
        color: #000000;
    }
    &.light-gray {
        color: #8C8C8C;
    }
    &.green {
        color: #237804;
    }
}

.add-model-button {
    border: 1px solid #276DD7 !important;
    background-color: #276DD7;
    color: white;
    width: 100%;
    font-family: Manrope;
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 20px;
    letter-spacing: 2%;
    height: 40px;
    border-radius: 4px !important;
    @media(max-width:1140px){
        font-size: 12px !important;
    }
    &.font-75-em {
        font-size: 0.75em !important;
    }
    &.font-115-em {
        font-size: 1.15em !important;
    }
}

.model-type-btn {
  border-radius: 4px; 
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-family: Manrope;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  &.blue {
    color:#276DD7; 
    background-color: #E6F7FF;
  }
  &.yellow {
    color: #D48806;
    background-color: #FFFBE6;
  }
  &.green {
    color: #08979C;
    background-color: #E6FFFB;
  }
  &.red {
    color: red;
    background-color: #FFE6E6;
  }
  &.black {
    color: #000000D9;
    background-color: #F0F0F0;
  }
}

.carousel-container {
    position: relative;
    overflow: hidden;
}

.prev-button,
.next-button {
    position: absolute;
    // top: 50%;
    background-color: #00000052;
    border: none;
    font-size: 25;
    cursor: pointer;
    z-index: 10;
    width: 30px;
    color: white;
}

.prev-button {
    left: 1px;
    height: 100%;
}

.next-button {
    right: 1px;
    height: 100%;
}

.limit-line {
    -webkit-line-clamp: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &.w-350 {
        width: 350px;
    }
    &.w-280 {
        width: 280px;
    }
    &.w-250 {
        width: 250px;
    }
    &.w-200 {
        width: 200px;
    }
    &.w-150 {
        width: 150px;
    }
    &.w-120 {
        width: 120px;
    }
}

.loading-center {
    color: #276DD7;
    justify-content: center;
    position: relative;
    left: 48%;
    top: 30%;
}

.comment-annotation-circle {
    position: relative;
    width: 25px; 
    height: 25px;
  }
  
  .comment-annotation-circle img {
    width: 100%;
    height: 100%;
  }
  
  .comment-count {
    position: absolute;
    top: -7px; 
    right: -5px;
    background-color: red; 
    color: white;
    border-radius: 50%;
    padding: 2px 7px; 
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .comment-col {
    display: flex;
    justify-content: flex-end;
    padding-right: 12px;
    padding-top: 12px;
  }

  .ring-card-height {
    height: 350px;
  }

  .your-images-legend {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 30px;
  }

  .your-images-legend .info-text {
      cursor: pointer;
      /* Change cursor to pointer */
      transition: background-color 0.3s, color 0.3s;
      /* Smooth transition for background and text color */
  }

  .your-images-legend .info-text:hover {
      background-color: rgba(39, 109, 215, 0.1);
      /* Light background on hover */
      color: #1a4f8d;
      /* Darker text color on hover */
  }

.pie-chart-container {
    margin: auto;
}

/* Styles for 100% scaling (DPR 1) */
@media only screen and (min-resolution: 1dppx) and (max-resolution: 1.24dppx) {
    .pie-chart-container {
        width: 75%;
    }
}

/* Styles for 125% scaling (DPR 1.25) */
@media only screen and (min-resolution: 1.25dppx) and (max-resolution: 1.49dppx) {
    .pie-chart-container {
        width: 100%;
    }
}

/* Styles for 150% scaling (DPR 1.5) */
@media only screen and (min-resolution: 1.5dppx) and (max-resolution: 1.74dppx) {
    .pie-chart-container {
        width: 92%;
    }
}
@media (max-width: 1220px) {
    .recentScenes .scene {
        margin-left: 7% !important;
        }
    .recentScenes .entityName {
        max-width: 45%;
    }
    .recentScenes .scene .scenespan {
        padding: 4px;
    }
    .assetBTN{
        padding: 2px 0px !important;
        margin-right: 10px;
    }
}

.ai-creator-cta {
    background-image: linear-gradient(to right, #7883DC , #276DD7, #91D5FF);
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:16px;
    margin: 30px 20px;
    border-radius: 4px;
    color: white;
    font-size: 14px;

    a {
        text-decoration: none;
        color: white;
    }

    .ai-redirect-link {
        margin-top: 10px;
    }
}