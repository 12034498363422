.reset-sun-container{
    position: absolute;
    width: 100%;
    margin-bottom:3%;
  }
  
  .reset-sun-button {
    font-size:0.75vw;
    font-weight: 500;
    background: #276DD7;
    width:50%;
    border-radius: 4px;
    transform: translateX(50%);
    position: absolute;
    bottom:20px;
    color: #FFFFFF;
  }
  
  .reset-sun-button:hover {
    background:#205FCF !important;
    border: none !important;
    color: #ffffff !important;
  }

  .sun-mode-switch-container {
    margin: auto;
    margin-top: 20%;
    display: flex;
    justify-content: center;
    align-items: center; 
  }

  .transform-move-icon {
    cursor: move;
    margin-top: -12px;
  }

 .vertical-correction-switch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  margin-bottom: 16px;
 }

 .vertical-correction-switch button.ant-switch.clipping-switch.ant-switch-checked {
  background-color: #276dd7 !important;
  }