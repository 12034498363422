.hide-on-mobile {
  @media (max-width: 768px) {
    display: none;
  }
}

.show-on-mobile {
  display: none !important;
  @media (max-width: 768px) {
    display: flex !important;
  }
}

.menu-icon {
  font-size: 24px;
  color: #fff;
}

.mobile-menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
}

@media (max-width: 768px) {
  .gx-app-header {
    padding: 10px 20px !important;
  }
}
