
.mySlides {
  display: flex;
}
/* On hover, add a black background color with a little bit see-through */
.prev:hover, .next:hover {
  background-color: rgba(0,0,0,0.8);
}

.ant-input:focus, .ant-input-focused {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.ant-input:hover {
  border-color: #40a9ff;
  border-right-width: 1px !important;
}

.prev, .next {
  cursor: pointer;
  align-self: center;
  width: auto;
  padding: 5px;
  color: blue;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

.warning-message{
  font-family: Manrope;
  font-size: 12px;
  color: #D83025;
  padding:5px;
  .no-padding {
    padding:0px;
  }
  &.font-adjust {
    @media (max-width: 1060px) {
      font-size: 10px;
    }
  }

}

.variation-price{
position: absolute;
right: 15px;
font-family: Manrope;
font-size: 14px;
}

.fixed-row{
  position: fixed;
  left: 0;
  bottom: 0;
  flex-direction: row;
  width: calc(100vw);
  background: #FFFFFF;
  padding: 20px 40px;
  height: 90px;
  box-shadow: 0px -4px 24px rgba(0, 0, 0, 0.08);
  .row-margins {
    position: absolute;
    left: 0;
    right: 0;
    width: 75%;
    margin-top: 15px;
    margin-right: 5%;
    margin-left: 15%;
    margin-bottom: 100px;
  }
}



.warning-wrapper{
  position: absolute;
  bottom:10px;
  left:20px;
}

/* Fading animation */
.mySlides {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes mySlides {
  from {opacity: .4}
  to {opacity: 1}
}

@keyframes mySlides {
  from {opacity: .4}
  to {opacity: 1}
}

@font-face {
  font-family: 'Avenir-Light';
  src: local('Avenir'), url('/fonts/Avenir-Light.otf') format('opentype');
}

@font-face {
    font-family: 'Avenir-Roman';
    src: local('Avenir'), url('/fonts/Avenir-Roman.otf') format('opentype');
}
@font-face {
  font-family: 'Avenir';
  font-weight: 500;
  src: local('Avenir'), url('/fonts/Avenir-Medium.otf') format('opentype');
}

@font-face {
    font-family: 'Avenir-Heavy';
    font-weight: 900;
    src: local('Avenir'), url('/fonts/Avenir-Heavy.otf') format('opentype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  src: local('Roboto'), url('/fonts/Roboto-Regular.ttf') format('opentype');
}

@font-face {
   font-family: 'Manrope';
   src: url('/fonts/Manrope-VariableFont_wght.ttf') format("truetype-variations");
   font-weight: 1 999;
   font-stretch: 0% 100%;
   font-optical-sizing: 0 100;
}

.float-right {
  float: right;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.d-inline {
  display: inline-block;
}
.d-flex {
  display: flex;
}
.d-none {
  display: none;
  &.important {
    display: none !important;
  }
}
.d-block {
  display: block;
}

.error-id {
  display: none;
}

.card-shadow {
  box-shadow: 0 2px 4px 0 lightgray !important;
  border: 1px solid lightgray;
}
.card-shadow-product {
  border: solid 1px #e3e3e3;
  background-color: #ffffff;
}
.highcharts-container {
  width:100% !important;
  height:100% !important;
}

.ant-form-item, .ant-legacy-form-item {
    margin-bottom: 10px!important;
}
.ant-legacy-form-item-required::before{
  color: #333 !important;
}

.color-black {
  color: #141414 !important;
}

.search-all-scenes {
  width: calc(100vw - 430px);
}
.text-red {
  color: #ff4d4f;
}
#floor-helper {
   .ant-upload-list {
        position: absolute !important;
        left: 249px !important;
        top: -25px !important;
  }
}

#artist-uploader {
  .ant-upload-list {
       position: absolute !important;
       top: 0px !important;
 }
}
.full-img {
  max-height:100vh;
  &.img-width{
    width: 100vw;
  }
  &.img-width-pc {
    width: 100%;
  }
}

.ant-input-number-input-wrap{
  height: 100%;
}
.ant-input-number-input{
  height: 100%;
}
.control-button span{
  display: block;
  line-height: 2.5;
  width: 100%;
}

.card-img {
  max-height: calc(100vh - 200px);
}
.panorama-view {
  // height: 390px;
  height: calc(100vh - 280px);
  position: relative;
  width: 100%;
}

.room-panorama-view {
  // height: 390px;
  height: calc(100vh - 230px);
  position: relative;
  width: 100%;
  &.small {
    height: calc(100vh - 230px);
  }
}

.room-viewer-normal {
  height: calc(100vh - 200px);
}

.room-full-screen-icon {
  position: absolute;
  right: 12px;
  bottom: 12px;
}

.room-full-screen {
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100vh !important;
}

.room-panorama-full-screen {
  // height: calc(100vh - 100px);
  height: 100vh;
  position: absolute;
  width: 100vw;
}

.panorama-full-screen {
  // height: calc(100vh - 100px);
  height: calc(100vh - 0px);
  position: absolute;
  width: 100%;
}

.panorama-default-styles {
  position: relative;
  width: 100%;
  height: 300px;
  &.large{
    height: 520px;
    border: 1px solid #f0f0f0;
  }
  &.auto-height {
    height: auto;
    border: 1px solid #f0f0f0;
  }
  &.image {
    height: 560px;
  }

  &.complaint{
    display: flex;
    height: 485px;
    padding-left:15px;
    margin-bottom:15px;
  }
}


.panorama-thumbnail-styles {
  position: relative;
  width: 100%;
  height: 240px;
  cursor: pointer;
}

.home-panorama-thumbnail-styles {
  position: relative;
  width: 100%;
  height: 280px;
  cursor: pointer;
  border-radius: 4px;
  z-index: 1;
}

.public-panorama-default-styles {
  position: relative;
  width: 100%;
  height: 100vh
}

.custom_color_picker {
  margin-top: 39px;
}


.rc-color-picker-trigger-custom {
  border: 1px solid #999;
  display: inline-block;
  padding: 2px;
  border-radius: 2px;
  -webkit-user-select: none;
  user-select: none;
  width: 50px;
  height: 50px;
  cursor: pointer;
  box-shadow: 0 0 0 2px #fff inset;

}

.modal-viewer-tabs {
  padding: '0px 24px';
}

/* marzipano viewer css */

.info-hotspot {
  line-height: 1.2em;
  opacity: 0.9;
  -webkit-transition: opacity 0.2s 0.2s;
  transition: opacity 0.2s 0.2s;
}

.no-touch .info-hotspot:hover {
  opacity: 1;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.info-hotspot.visible {
  opacity: 1;
}

.info-hotspot .info-hotspot-header {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: rgb(103,115,131);
  cursor: pointer;
  -webkit-transition: width 0.3s ease-in-out 0.5s,
                      border-radius 0.3s ease-in-out 0.5s;
  transition: width 0.3s ease-in-out 0.5s,
              border-radius 0.3s ease-in-out 0.5s;
}

.mobile .info-hotspot .info-hotspot-header {
  width: 50px;
  height: 50px;
  border-radius: 25px;
}

.desktop.no-touch .info-hotspot .info-hotspot-header:hover {
  width: 260px;
  border-radius: 5px;
  -webkit-transition: width 0.3s ease-in-out,
                      border-radius 0.3s ease-in-out;
  transition: width 0.3s ease-in-out,
              border-radius 0.3s ease-in-out;
}

.desktop .info-hotspot.visible .info-hotspot-header,
.desktop.no-touch .info-hotspot.visible .info-hotspot-header:hover {
  width: 260px;
  border-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  -webkit-transition: width 0.3s ease-in-out,
                      border-radius 0.3s ease-in-out;
  transition: width 0.3s ease-in-out,
              border-radius 0.3s ease-in-out;
}

.info-hotspot .info-hotspot-icon-wrapper {
  width: 40px;
  height: 40px;
}

.mobile .info-hotspot .info-hotspot-icon-wrapper {
  width: 50px;
  height: 50px;
}

.info-hotspot .info-hotspot-icon {
  width: 90%;
  height: 90%;
  margin: 5%;
}

.info-hotspot .info-hotspot-title-wrapper {
  position: absolute;
  left: 40px;
  top: 0;
  width: 0;
  height: 40px;
  padding: 0;
  overflow: hidden;
  -webkit-transition: width 0s 0.4s,
                      padding 0s 0.4s;
  transition: width 0s 0.4s,
              padding 0s 0.4s;
}

.desktop .info-hotspot.visible .info-hotspot-title-wrapper,
.desktop.no-touch .info-hotspot .info-hotspot-header:hover .info-hotspot-title-wrapper {
  width: 220px;
  padding: 0 5px;
  -webkit-transition: width 0s 0.4s,
                      padding 0s 0.4s;
  transition: width 0s 0.4s,
              padding 0s 0.4s;
}

.info-hotspot .info-hotspot-title-wrapper:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}

.info-hotspot .info-hotspot-title {
  display: inline-block;
  vertical-align: middle;

  -moz-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.info-hotspot .info-hotspot-close-wrapper {
  position: absolute;
  left: 260px;
  top: 0;
  height: 40px;
  width: 40px;
  border-top-right-radius: 5px;
  background-color: rgb(78,88,104);
  visibility: hidden;
  -ms-transform: perspective(200px) rotateY(90deg);
  -webkit-transform: perspective(200px) rotateY(90deg);
  transform: perspective(200px) rotateY(90deg);
  -ms-transform-origin: 0 50% 0;
  -webkit-transform-origin: 0 50% 0;
  transform-origin: 0 50% 0;
  -webkit-transition: -ms-transform 0.3s 0.3s,
                      -webkit-transform 0.3s 0.3s,
                      transform 0.3s 0.3s,
                      visibility 0s 0.6s;
  transition: -ms-transform 0.3s 0.3s,
              -webkit-transform 0.3s 0.3s,
              transform 0.3s 0.3s,
              visibility 0s 0.6s;
}

.desktop .info-hotspot.visible .info-hotspot-close-wrapper {
  visibility: visible;
  -ms-transform: perspective(200px) rotateY(0deg);
  -webkit-transform: perspective(200px) rotateY(0deg);
  transform: perspective(200px) rotateY(0deg);
  -webkit-transition: -ms-transform 0.3s,
                      -webkit-transform 0.3s,
                      transform 0.3s,
                      visibility 0s 0s;
  transition: -ms-transform 0.3s,
              -webkit-transform 0.3s,
              transform 0.3s,
              visibility 0s 0s;
}

.info-hotspot .info-hotspot-close-icon {
  width: 70%;
  height: 70%;
  margin: 15%;
}

.info-hotspot .info-hotspot-text {
  position: absolute;
  width: 300px;
  height: auto;
  max-height: 200px;
  top: 40px;
  left: 0;
  padding: 10px;
  background-color: rgb(58,68,84);
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  overflow-y: auto;
  visibility: hidden;
  /* rotate(90deg) causes transition flicker on Firefox 58 */
  -ms-transform: perspective(200px) rotateX(-89.999deg);
  -webkit-transform: perspective(200px) rotateX(-89.999deg);
  transform: perspective(200px) rotateX(-89.999deg);
  -ms-transform-origin: 50% 0 0;
  -webkit-transform-origin: 50% 0 0;
  transform-origin: 50% 0 0;
  -webkit-transition: -ms-transform 0.3s,
                      -webkit-transform 0.3s,
                      transform 0.3s,
                      visibility 0s 0.3s;
  transition: -ms-transform 0.3s,
              -webkit-transform 0.3s,
              transform 0.3s,
              visibility 0s 0.3s;

  -moz-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.desktop .info-hotspot.visible .info-hotspot-text {
  visibility: visible;
  -ms-transform: perspective(200px) rotateX(0deg);
  -webkit-transform: perspective(200px) rotateX(0deg);
  transform: perspective(200px) rotateX(0deg);
  -webkit-transition: -ms-transform 0.3s 0.3s,
                      -webkit-transform 0.3s 0.3s,
                      transform 0.3s 0.3s,
                      visibility 0s 0s;
  transition: -ms-transform 0.3s 0.3s,
              -webkit-transform 0.3s 0.3s,
              transform 0.3s 0.3s,
              visibility 0s 0s;
}

/* Info hotspot modal */

.desktop .info-hotspot-modal {
  display: none;
}

.info-hotspot-modal {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 11000 !important;
  background-color: rgba(0,0,0,.5);
  line-height: 1.2em;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.2s ease-in-out 0.5s,
                      visibility 0s 0.7s;
  transition: opacity 0.2s ease-in-out 0.5s,
              visibility 0s 0.7s;
}

.info-hotspot-modal.visible {
  opacity: 1;
  visibility: visible;
  -webkit-transition: opacity 0.2s ease-in-out,
                      visibility 0s 0s;
  transition: opacity 0.2s ease-in-out,
              visibility 0s 0s;
}

.info-hotspot-modal .info-hotspot-header {
  position: absolute;
  top: 60px;
  left: 10px;
  right: 10px;
  width: auto;
  height: 50px;
  background-color: rgb(103,115,131);
  background-color: rgba(103,115,131,0.8);
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-in-out 0.2s;
  transition: opacity 0.3s ease-in-out 0.2s;
}

.info-hotspot-modal.visible .info-hotspot-header {
  opacity: 1;
  -webkit-transition: opacity 0.3s ease-in-out 0.2s;
  transition: opacity 0.3s ease-in-out 0.2s;
}

.info-hotspot-modal .info-hotspot-icon-wrapper {
  width: 50px;
  height: 50px;
}

.info-hotspot-modal .info-hotspot-icon {
  width: 90%;
  height: 90%;
  margin: 5%;
}

.info-hotspot-modal .info-hotspot-title-wrapper {
  position: absolute;
  top: 0;
  left: 50px;
  right: 50px;
  width: auto;
  height: 50px;
  padding: 0 10px;
}

.info-hotspot-modal .info-hotspot-title-wrapper:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}

.info-hotspot-modal .info-hotspot-title {
  display: inline-block;
  vertical-align: middle;

  -moz-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.info-hotspot-modal .info-hotspot-close-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  background-color: rgb(78,88,104);
  background-color: rgba(78,88,104,0.8);
  cursor: pointer;
}

.info-hotspot-modal .info-hotspot-close-icon {
  width: 70%;
  height: 70%;
  margin: 15%;
}

.info-hotspot-modal .info-hotspot-text {
  position: absolute;
  top: 110px;
  bottom: 10px;
  left: 10px;
  right: 10px;
  padding: 10px;
  background-color: rgb(58,68,84);
  background-color: rgba(58,68,84,0.8);
  overflow-y: auto;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;

  -moz-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.info-hotspot-modal.visible .info-hotspot-text {
  opacity: 1;
  -webkit-transition: opacity 0.3s ease-in-out 0.4s;
  transition: opacity 0.3s ease-in-out 0.4s;
}

.scene-creator-header {
  width: 100%;
  height: 70px;
  border-radius: 4px;
  border: solid 1px #e3e3e3;
  background-color: #f4f4f4;
  display: flex;
}

.scene-creator-room-name {
  height: 32px;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #a3a3a3;
  margin-top: 18px;
  text-align: center;
  margin-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.edit-icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin-top: 24px;
  margin-left: 8px;
}

#edit-resolution-icon {
  position: relative;
  bottom: 47px;
  padding-left: 15px;
}

.separator {
  width: 1px;
  height: 30px;
  background-color: #d8d8d8;
  margin-top: 18px;
  margin-left: 14px;
}

.separator-no-margin {
  width: 1px;
  height: 30px;
  background-color: #d8d8d8;;
  position: relative;
  display: inline-block;
  top: 5px;
}

.container-box {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #e3e3e3;
  background-color: #ffffff;
  margin-top: 8px;
  &.disabled {
    background-color: #dbdbdb;
  }
}

.double-container-box {
  width: 86px;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #e3e3e3;
  background-color: #ffffff;
  display: inline-block;
  &.scene-creator-zoom {
    margin-top: 8px;
  }
}

.contained-icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin: 7px;
  font-size: 22px;
  color: #6b6b6b;
}

.contained-icon-medium {
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin: 3px;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
}

.icon-scene-creator-camera-controls {
  width: 15%;
  object-fit: contain;
  margin: 3px;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
}

.contained-icon-large {
  width: 32px;
  height: 32px;
  object-fit: contain;
  margin: 3px;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
}

.contained-icon-x-large {
  width: 40px;
  height: 40px;
  object-fit: contain;
  margin: 3px;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
}


.contained-icon-bigger {
  width: 32px;
  display: inline;
  height: 32px;
  margin: 0 4px;
  position: relative;
  top: -5px;
}

.contained-icon-grid {
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin: 10px;
}

.icon-label-text {
  // width: 17px;
  // height: 16px;
  font-family: Manrope;
  font-size: 10px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  // color: #276dd7;
  color: #6b6b6b;
  margin-top: 4px;
  //margin-left: 11px;
  &.text-wrap {
    width: max-content;
    text-align: justify;
  }
}

.icon-label-text2 {
  // width: 17px;
  // height: 16px;
  font-family: Manrope;
  font-size: 10px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  // color: #276dd7;
  color: #6b6b6b;
  margin-top: 3px;
  //margin-left: 5px;
  text-align: center;
  width: 100%;
}

.silo-zoom-pan{
  &.pan{
    width:30px;
    height:30px;
  }
  &.zoom{
    width:15px;
    height:15px;
  }
}

.icon-label-text3 {
  // width: 17px;
  // height: 16px;
  font-family: Manrope;
  font-size: 10px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  // color: #276dd7;
  color: #6b6b6b;
  margin-top: 3px;
 // margin-left: -8px;
}

.icon-label-text4 {
  // width: 72px;
  // height: 16px;
  font-family: Manrope;
  font-size: 10px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #6b6b6b;
  margin-top: 2px;
  //margin-left: -14px;
}

.icon-label-text5 {
  // width: 66px;
  // height: 16px;
  font-family: Manrope;
  font-size: 10px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #6b6b6b;
  margin-top: 3px;
 // margin-left: -13px;
}

.icon-label-text6 {
  // width: 17px;
  // height: 16px;
  font-family: Manrope;
  font-size: 10px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #6b6b6b;
  margin-top: 3px;
 // margin-left: 6px;
}

.icon-label-text7 {
  font-family: Manrope;
  font-size: 10px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #6b6b6b;
  margin-top: 3px;
  width: max-content;
  margin-left: -20px;

  &.m-12 {
    margin-left: -12px;
  }
}

.scene-note {
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;
  //padding: 10px 16px;
  color: #276DD7;
  &.flag {
    font-size: 12px;
    &.red{
      color:  #d93025;
    }
  }
  &.mp4 {
    font-size: 16px;
    color: #333333;
  }
}

#scene-creator {
  height: calc(100vh - 140px);
  background: rgba(219,219,219,1);
  background: -moz-radial-gradient(center, ellipse cover, rgba(219,219,219,1) 0%, rgba(219,219,219,1) 3%, rgba(226,226,226,1) 30%, rgba(214,214,214,1) 80%, rgba(209,209,209,1) 100%);
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(219,219,219,1)), color-stop(3%, rgba(219,219,219,1)), color-stop(30%, rgba(226,226,226,1)), color-stop(80%, rgba(214,214,214,1)), color-stop(100%, rgba(209,209,209,1)));
  background: -webkit-radial-gradient(center, ellipse cover, rgba(219,219,219,1) 0%, rgba(219,219,219,1) 3%, rgba(226,226,226,1) 30%, rgba(214,214,214,1) 80%, rgba(209,209,209,1) 100%);
  background: -o-radial-gradient(center, ellipse cover, rgba(219,219,219,1) 0%, rgba(219,219,219,1) 3%, rgba(226,226,226,1) 30%, rgba(214,214,214,1) 80%, rgba(209,209,209,1) 100%);
  background: -ms-radial-gradient(center, ellipse cover, rgba(219,219,219,1) 0%, rgba(219,219,219,1) 3%, rgba(226,226,226,1) 30%, rgba(214,214,214,1) 80%, rgba(209,209,209,1) 100%);
  background: radial-gradient(ellipse at center, rgba(219,219,219,1) 0%, rgba(219,219,219,1) 3%, rgba(226,226,226,1) 30%, rgba(214,214,214,1) 80%, rgba(209,209,209,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dbdbdb', endColorstr='#d1d1d1', GradientType=1 );
}



#overlay-blur-bottom {
  position: absolute;
  bottom:0.1px;
  // height: 50px;
  width: 100%;
  background: white;
  box-shadow: white;
  opacity: 0.3;
}

#overlay-blur-top {
  position: absolute;
  top:0.1px;
  // height: 50px;
  width: 100%;
  background: white;
  box-shadow: white;
  opacity: 0.3;
}

#overlay-blur-left {
  position: absolute;
  left:0.1px;
  top:50px;
  height: calc(100% - 100px);
  // width: 50px;
  background: white;
  box-shadow: white;
  opacity: 0.3;
}
#overlay-blur-right {
  position: absolute;
  // right:0.1px;
  top:50px;
  // width: 50px;
  height: calc(100% - 100px);
  background: white;
  box-shadow: white;
  opacity: 0.3;
}

.overlay-blur{
  display:none;
}

.sun-icon {
  width: 40px;
  height: 40px;
  object-fit: contain;
  top: 0;
  left: 0;
}

.movement-icons {
  width: 27px;
  height: 37px;
  object-fit: contain;
}

.movement-icons2 {
  width: 38px;
  height: 27px;
  object-fit: contain;
}
#sun-control-div{
  position: absolute;
  top: 10%;
  left: 15%;
  /* bring your own prefixes */
}

.sun-positioning {
  width: 200px;
  height: 200px;
  border-radius: 6px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  font-family: Avenir;
  font-size: 13px;
  font-weight: 900;
  text-align: center;
  color: #6b6b6b;
}

.bottom-fixed-buttons {
  position: absolute;
  bottom: 2px;
  width: calc(100vw - 285px);
  background-color: #D8D8D8;
  padding: 20px;
  border-radius: 5px;
}

.store-button {
  width: max-content;
  height: unset;
  border-radius: 4px;
  border: none;
  &.grey {
    background-color: rgba(255, 255, 255, 0.2);
    &:hover {
      background-color: rgba(255, 255, 255, 0.308);
      border-color: rgba(255, 255, 255, 0.308);;
    }
    &:focus {
      background-color: rgba(255, 255, 255, 0.308);
      border-color: rgba(255, 255, 255, 0.308);;
    }
  }
  &.yellow {
    background: rgba(255, 180, 0, 0.12);
    &:hover {
      background: rgba(255, 180,0, 0.29);
      border-color: rgba(255, 180,0, 0.29);
    }
    &:focus {
      background: rgba(255, 180,0, 0.29);
      border-color: rgba(255, 180,0, 0.29);
    }
  }
  margin-right: 20px !important;
  cursor: pointer;
  &.blue {
    background-color: #276DD7;
    &:hover {
      background-color: #1e5dbd;
      border-color: #1e5dbd;
    }
    &:focus {
      background-color: #1e5dbd;
      border-color: #1e5dbd;
    }
  }
}
.store-button-text {

  width: max-content;
  height: unset;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  &.text-white {
    color: #ffffff;
    &:hover {
      color: #ffffff;
    }
    &:focus {
      color: #ffffff;
    }
  }

  &.text-yellow {
    color: #FFB400;
    &:hover {
      color: #FFB400;
    }
    &:focus {
      color: #FFB400;
    }
  }


  padding: 6px 10px;
  font-family: Manrope;
  line-height: 24px;
  &:hover {
    color: #ffffff;
  }
  &:focus {
    color: #ffffff;
  }
}

//Following four properties is a hack to fix collections thumbnails, would have to change implementation in refactoring
.sc-AxirZ {
  border: None !important;
}

.sc-AxhCb {
  background-size: contain !important;
}

.sc-fzpans {
  border: None !important;
}

.sc-fznyAO{
  background-size: contain !important;
}

.product-view-icon {
  // width: 64px;
  // height: 64px;
  // border-radius: 2px;
  // -webkit-backdrop-filter: blur(4px);
  //backdrop-filter: blur(4px);
  //background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
}

.justify-in-start {
  display: flex;
  justify-content: flex-start !important;
  align-items: center;
  &.wrap {
    flex-wrap: wrap;
  }
  &.align-start {
    align-items: flex-start;
  }
}

.justify-in-between {
    display: flex;
    justify-content: space-between !important;
    align-items: center;
    &.wrap {
      flex-wrap: wrap;
    }
    &.align-start {
      align-items: flex-start;
    }
  }

.align-in-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.wrap {
    flex-wrap: wrap;
  }
}

.justify-buttons {
  .ant-legacy-form-item-control-wrapper {
    .ant-legacy-form-item-control {
      .ant-legacy-form-item-children {
        display: flex !important;
      }

    }
  }
  &.end {
    .ant-form-item-control {
      .ant-form-item-control-input {
        .ant-form-item-control-input-content {
          display: flex !important;
          justify-content: flex-end;
        }

      }
    }
  }
}

.material-library-v-scroll {
  height: calc(100vh - 413px);
  overflow-y: scroll;
}

.justify-in-center {
  display: flex;
  justify-content: center;
  align-items: center;
  &.flex-col {
    flex-direction: column;
  }
  &.align-start {
    align-items: flex-start;
  }
}

.justify-in-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.justify-in-end-flex {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.flex-column-flex-start {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.shared-models-tab{
  display: flex;
  align-items: center;
  background: #F6F6F7;
  padding: 8px;
  .shared-models-tab-span{
    margin: 5px 15px;
  }
}

.j-end-a-end {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.j-start-a-start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.j-center-a-baseline {
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.j-end-a-baseline {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}


.justify-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.align-start {
    align-items: flex-start;
  }
}

.img-upload-text {
  height: 15px;
  width: 15px;
}


.img-upload-text {
  height: 15px;
  width: 15px;
  &.large {
    height: 25px;
    width: 25px;
    margin-bottom: 10px;
  }
}

.justify-align-start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  &.wrap {
    flex-wrap: wrap;
  }
}

.space-between-start {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.a-end-j-sb {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.site-collapse-custom-collapse .site-collapse-custom-panel {
  background: white;
  border-radius: 2px;
  overflow: hidden;
  border-bottom: 1px solid #E3E3E3;
}

.site-collapse-custom-collapse .site-collapse-custom-panel-main {
  background: white;
  border-radius: 2px;
  border: 0px !important;
  overflow: hidden;
}

#basicinfo-centered-panel .ant-collapse-header{
  width: 200px;
  margin-left: 40%;
}

#licensing-panel .ant-collapse-header{
  cursor: default !important;
}

#advanceinfo-centered-panel  .ant-collapse-header{
  width: 200px;
  margin-left: 40%;
}

#advanceinfo-centered-panel .site-collapse-custom-panel .ant-collapse-header{
  width: 100%;
  margin-left: 0px;
}

#dimensions-centered-panel  .ant-collapse-header{
  width: 220px;
  margin-left: 40%;
}

#dimensions-centered-panel .site-collapse-custom-panel .ant-collapse-header{
  width: 100%;
  margin-left: 0px;
}

#color-centered-panel  .ant-collapse-header{
  width: 200px;
  margin-left: 40%;
}

#color-centered-panel .site-collapse-custom-panel .ant-collapse-header{
  width: 100%;
  margin-left: 0px;
}

#material-centered-panel  .ant-collapse-header{
  width: 200px;
  margin-left: 40%;
}

#material-centered-panel .site-collapse-custom-panel .ant-collapse-header{
  width: 100%;
  margin-left: 0px;
}

#dimensions_table tr  td {
  border: 1px solid black;
}

#dimensions_table tr  td {
  padding: 10px;
}

.review-heading {
  font-family: Manrope;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #333333;
  white-space: nowrap;
  &.product-model-info-h{
    font-size: 32px;
  }
}

.product-info-heading {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  color: #276DD7;
  white-space: nowrap;
}

.home-screen-entity-label {
  float: right;
  background: rgba(217, 48, 37, 0.04);
  border: 1px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 4px;
  padding-left: 5px;
  padding-right: 5px;
  color: #D93025;
  margin-bottom: 5px;
  font-style: italic;
}

.ant-transfer-list-content-item-text {
  display: inline-block !important;
}

.small-card-name {
  margin-top: 10px;
  width: 150px;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.custom-mask{
  color: rgba(0, 0, 0, 0.25)
}

.ant-carousel .slick-slide {
  text-align: center;
  background: white;
  overflow: hidden;
}


.ant-carousel .slick-dots li {
  background: #ba716c;
  // opacity: 0.5;
}

.ant-carousel .slick-dots li.slick-active button {
  background: #D93025 !important;
  // opacity: 1;
}

.model-cross-button {
  position: absolute;
  right: -8px;
  top: -11px;
  border-radius: 50%;
  background: #bebebe;
  padding-left: 4px;
  padding-right: 4px;
  cursor: pointer;
}


.box{
  height:auto;
  margin: auto;
  text-align:center;
  position:relative;
  z-index: 1;
  overflow: hidden;
}
.box {
  .text{
    position: absolute;
    z-index: 1;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    height: fit-content;
    text-align: center;
    font-size: larger;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 4px;
    color: #fff;
    width: 80%;
    padding: 12px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin: 100px auto;

    &.error {
      background: rgba(157, 10, 0, 0.5);
    }
  }
  .btn {
    position: absolute;
    z-index: 1;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    height: fit-content;
    text-align: center;
    font-size: larger;
    color: #fff;
    width: 80%;
    padding: 12px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin: 100px auto;
  }
}
.home-panorama-thumbnail-styles {
  canvas {
    position: relative !important;

  }
}

.home-entity-label{

  float: left;
  text-align: center;
  flex: none;
  order: 0;
  align-self: center;
  top: 0;
  left: 0;
  position: absolute;
  margin: 12px;
  .entity-label-bg {
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    border-radius: 4px;
    padding: 6px;
  }

}

.silo-status-message{
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-size: 18px;
  text-align: center;
  color: #D93025;
  flex: none;
  order: 0;
  align-self: center;
  padding: 6px 20px;
  top: 35%;
  left: 41%;
  position: absolute;
}

#entity-container {
  .ant-card-body {
    padding: 0px !important;
    &:before{
     display: table;
    content: '';
    }
  }
}
.zoom {
  transition: transform .2s;
}

.zoom:hover {
  -ms-transform: scale(1.05); /* IE 9 */
  -webkit-transform: scale(1.05); /* Safari 3-8 */
  transform: scale(1.05);
}

.green-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #4DD2AC !important;
  border-color: #4DD2AC !important;
}

.yellow-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #EAA500 !important;
  border-color: #EAA500 !important;
}

.red-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #D93025 !important;
  border-color: #D93025 !important;
}

.blue-checkbox {
  font-size: 20px !important;
}

.blue-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #276DD7 !important;
  border-color: #276DD7 !important;
  font-size: 20px !important;
}

.pricing-note-label {
  font-size: 16px;
  color: #D93025;
  font-weight: 700;
  margin-bottom: 10px;
}

.pricing-note-text {
  padding: 6px;
  background-color: #FFF0F1;
  border-radius: 5px;
}

.edit-icon-pencil {
  margin-right: 12px;
  float: right;
  font-size: 16px;
  cursor: pointer;
}

.ant-card-body{
  padding: 0px !important;
}

.product-style {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 10px;
}

.group-settings {
  top: 0;
  right: 0;
  position: absolute;
  align-self: center;
  margin: 20px;
  z-index: 10;
  &.plus-icon {
    float: right;
    cursor: pointer;
    z-index: 2;
    color: black;
    background: #EEEEEE;
    border-radius: 40px;
    width: 40px;
    height: 40px;
    position: absolute;
    padding: 13px;
  }
  &.check-icon {
    float: right;
    cursor: pointer;
    z-index: 2;
    color: #276DD7;
    background: rgba(39, 109, 215, 0.12);
    border-radius: 40px;
    width: 40px;
    height: 40px;
    position: absolute;
    padding: 10px;
  }
  &.more-icon {
    float: right;
    cursor: pointer;
    z-index: 2;
    position: absolute;
    min-width: 38px;
  }

  &.scene-more-icon{
    float: right;
    cursor: pointer;
    z-index: 2;
    position: absolute;
    min-width: 32px;
    opacity: 0.8;
  }
  &.materials {
    right: 20px;
  }
  &.left {
    left: 0px;
    z-index: 12;
  }
  &.left-low-z-index {
    left: 0px;
    z-index: 2;
  }
  &.variant-icon{
    z-index: 2;
  }
}

.group-settings-checkbox {
  top: 20px;
  right: 28px;
  position: absolute;
  cursor: pointer;
  z-index: 2;
  width: 14px;
  height: 14px;
}

.group-settings-checkbox-reorder-items {
  top: 20px;
  left: 28px;
  position: absolute;
  cursor: pointer;
  z-index: 2;
  width: 14px;
  height: 14px;
  &.scenery-icon {
    top: 24px;
    left: 24px;
  }
  .bg-white {
    padding: 4px 6px 4px 6px;
    background: #ececec;
    &.black {
      background: black;
    }
    border-radius: 4px;
    display: flex;
    width: fit-content;
    align-items: center;
  }
}

.card-icon {
  object-fit: contain;
  width: 20px;
  height: 15px;
}
div.shadow {
  position:absolute;
  max-width:30%;
  max-height:45%;
  top:53%;
  left:59%;
  overflow:visible;
}
img.logo {
  position:relative;
  width:35%;
  height: 80%;
  margin-top: -20%;
  margin-left:-50%;
}
img.logo-loader-screen {
  position:absolute;
  width:35%;
  height: 80%;
  margin-top: 0%;
  margin-left:0%;
}
.text-home-page-default{
  position: relative;
  left: -82%;
  margin-top: 10%;
  text-align: center;
}


#empty-section-image{
  width:"15%";
}
.empty-section-text{
  max-width: "60%";

}
.cost-container{
  width:100%;
  height:100%;
  display:inline-flex
}
.cost-lifestyle{
  width: 49%;
  height:100%
}
.cost-silo{
  width: 49%;
  float: left;
  margin-left:1%;
  height:100%
}

.incomplete-request-text {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  color: #737373;
  z-index: 999;
  margin: auto;
  text-align: center;
  position: absolute;
  top: 225px;
  right: 0;
  bottom: 0;
  left: 0;
}

.incomplete-submission-text {
  z-index: 999;
  margin: auto;
  text-align: center;
  position: absolute;
  top: 193px;
  right: 0;
  bottom: 0;
  left: 0;
}

.search-heading-justify {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.create-button-box {
  object-fit: scale-down;
  top: 0;
  right: 0;
  background-color: rgb(250, 250, 250);
  text-align: center;
  display: block;
  margin: auto;
  left: 0;
  bottom: 0;
  position: absolute;
}

.create-button-text {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  color: #000000;;
  z-index: 1;
  margin: auto;
  text-align: center;
  position: absolute;
  top: 293px;
  right: 0;
  bottom: 0;
  left: 0;
}

.model-application-card {
  padding: 24px !important;
}

#scroll-style {
  .ant-spin-nested-loading {
    overflow: hidden;
    overflow-y: scroll;
    height: 200px;
  }
  .adjust-overflow{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    overflow-y: scroll;
    width: 100%;
    height: 350px;
    @media (max-width: 1800px) {
      height: 300px;
    }
  }
}

#canvas-dimension {
  max-width: 100vw;
  max-height: 500px;
  background: #FAFAFC;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #333333 !important;
}

.black-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #333333 !important;
  border-color: #333333 !important;
}
.black-checkbox{
  font-size: 12px;
  font-family: Manrope;
}
.green-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #009D19 !important;
  border-color: #009D19 !important;
}

.light-green-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #29B862 !important;
  border-color: #29B862 !important;
}

.checkbox-pos {
  position: absolute;
  top: 10px;
  left: 10px;
}

.scene-creator-space .ant-checkbox-checked .ant-checkbox-inner{
  height: 20px !important;
  width: 20px !important;
}

.ant-checkbox-inner {
  width: 22px;
  height: 22px;

  &::after {
    left: 28%;
  }
}


#space-panorama-card {
  .ant-card-body {
    padding: 24px !important;
  }
}

.round-small-btn {
  background-color: #d93025 !important;
  border-color:  #d93025 !important;
  color: white;
  width: 24px;
  height: 24px;
  border-radius: 50% !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.sc-action-button, sc-action-button:active {
  width: unset !important;
  height: unset !important;
  padding: 0 !important;
  border-radius: 35px !important;
  color: white;
  background-color: #d93025 !important;
  border-color:  #d93025 !important;
  font-size: 1.7rem;
  padding: 0;
  &:hover {
    background:#D2281F !important;
    border-color:#D2281F !important;
    color: #ffffff !important;
  }
  &.round {
    border-radius: 50% !important;
    min-width: 64px;
    min-height: 64px;
    color: white;
    @media (max-width: 1440px) {
      min-width: 55px;
      min-height: 55px;
    }
  }
  &.square {
    border-radius: 5px !important;
  }

  &.round-24 {
    border-radius: 50% !important;
    min-width: 24px;
    min-height: 24px;
    font-size: 12px;
    vertical-align: middle;
    align-items: center;
    color: white;
  }
  &.ant-btn-danger[disabled], .ant-btn-danger[disabled]:hover, .ant-btn-danger[disabled]:focus, .ant-btn-danger[disabled]:active {
    color: white;
    opacity: 0.5;
    background-color: #d93025 !important;
    border-color:  #d93025 !important;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  &.font-14 {
    font-size: 14px !important;
    line-height: 120%;
    padding: 11px 32px !important;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    vertical-align: middle;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    &.pd {
      padding: 13px 75px !important;
      @media (max-width: 1366px) {
        padding: 13px 25px !important;
        font-size: 12px !important;
      }
      @media (max-width: 1440px) {
        font-size: 14px !important;
        padding: 13px 50px !important;

      }
    }
  }
  &.w-900 {
    font-weight: 900;
  }
}

.create-new-color {
  background-color: #d93025 !important;
  border-color:  #d93025 !important;
}

.sc-modal-action-button, sc-modal-action-button:active {
  width: unset !important;
  height: unset !important;
  padding: 0 !important;
  border-radius: 35px !important;
  background-color: #d93025 !important;
  border-color:  #d93025 !important;
  font-size: 1.7rem;
}

.sc-exit-button, sc-exit-button:active {
  width: unset !important;
  height: unset !important;
  padding: 0 !important;
  border-radius: 35px !important;
  font-size: 1.7rem;
  &.square{
    border-radius: 5px !important;
  }
}
.sc-exit-button {
  color: #737373;
  padding: 0;
  &.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
    color: #737373;
    background: #f5f5f5;
    opacity: 0.5;
    border-color: #d9d9d9;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  &.square{
    border-radius: 5px !important;
  }
}

.sc-exit-button-pd {
  margin: 11px 32px 11px 32px;
  font-size: 14px;
  line-height: 100%;
  font-weight: 900;
  font-family: Avenir;
  &.medium {
    margin: 10px 24px 10px 24px;
    font-size: 12px;
  }
}
.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  border-right-color: #276DD7;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #276DD7;
}


.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
  background: #276DD7;
  border-color: #276DD7;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #276DD7;
  border-color: #276DD7;
}

.ant-radio-button-wrapper:hover {
  position: relative;
  color: #276DD7;
}



.ar-radio{
  display: flex;
  margin-bottom: 5px;
  .ant-radio-wrapper{
    padding: 6px;
    margin-bottom: 2px;
  }
}

//css for virtual show room

.hotspot {
	position: absolute;
	width: 30px;
	height: 30px;
}

.hotspotImage {
	text-align: center;
	width: 100%;
	user-select: none;
	-moz-user-select: none;
	-webkit-user-drag: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}

.tooltiptext {
  visibility: hidden;
  font-family: Manrope;
  font-size: 12px;
  width: 100px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 5px 0;
  position: absolute;
  z-index: 999;
  bottom: 150%;
  left: 0%;
  margin-left: -50px;

  /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
  opacity: 0;
  transition: opacity 1s;
}

.tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

#loading-container{
  position:relative;
  // width: 100vw;
  // height: 100vh;
}

.loadingImage {
  // width: 100%;
  // height: 100%;
  filter: blur(8px);
  -webkit-filter: blur(8px);
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.logo {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  margin: 0 auto;
}

.profile-main-loader{
    left: 50% !important;
    margin-left:-40px;
    position: fixed !important;
    top: 50% !important;
    margin-top: -40px;
    width: 45px;
    z-index: 10 !important;
    &.tour {
      position: absolute !important;
      top: 0 !important;
      left: 50% !important;
      margin: 0 !important;
      transform: translate(-50%, -50%) !important;
      width: 45px;
    }
    &.ai-section {
      left: 54% !important;
      top: 60% !important;
    }
}

.ant-select-selection-item-content > p > .suggested-by-artist-tag {
 display: none;
}

.profile-main-loader .loader {
  position: relative;
  margin: 0px auto;
  width: 80px;
  height:80px;
}
.profile-main-loader .loader:before {
  content: '';
  display: block;
  padding-top: 100%;
}

.profile-main-loader.container-position {
  position: relative !important;
  margin-top: -80px !important;
}

.dotted-loader-main-div {
  display: inline !important;
}

.circular-loader {
  -webkit-animation: rotate 2s linear infinite;
          animation: rotate 2s linear infinite;
  height: calc(100vh - 165px);
  -webkit-transform-origin: center center;
      -ms-transform-origin: center center;
          transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin: auto;
}

.loader-path {
  stroke-dasharray: 150,200;
  stroke-dashoffset: -10;
  -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
          animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}
@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124;
  }
}
@-webkit-keyframes color {
  0% {
    stroke: #808080;
  }
  40% {
    stroke: #808080;
  }
  66% {
    stroke: #808080;
  }
  80%, 90% {
    stroke: #808080;
  }
}
@keyframes color {
  0% {
    stroke: #808080;
  }
  40% {
    stroke: #808080;
  }
  66% {
    stroke: #808080;
  }
  80%, 90% {
    stroke: #808080;
  }
}

.my_font {
  font-family: Avenir;
  color: black;
}

#vs-container {
  height: calc(100vh - 165px);
}

.tour-fullscreen {
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100vh !important;
}


  .ant-tabs-nav .ant-tabs-tab {
    margin: 0 80px 0 0;
    font-size: 18px;
    font-family: Avenir;
  }


.tabAttr1 {
  font-size: 14px;
  font-weight: 700;
  font-family: Manrope;
  .ant-tabs-nav .ant-tabs-tab {
    font-size: 14px;
    font-family: Manrope;
    font-weight: 700;
  }
}

.ant-tabs-nav .ant-tabs-tab {
  font-size: 14px;
  font-weight: 600;
  font-family: Manrope;
}

.apply-Avenir {
  font-family: Avenir;
}

.product-h2 {
  font-family: Avenir;
  font-size: 16px;
  color: #999999;
  font-weight: normal;
  &.red {
    color: #ff4d4f;
  }
  &.black {
    color: black;
  }
}

.product-name-h3 {
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 100%;
  text-transform: capitalize;

  &.grey {
    color:#999999
  }
  &.black {
    color: #333333;
  }
}

.product-name-h3-id {
  font-family: Avenir;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  &.grey {
    color:#999999
  }
  &.black {
    color: #333333;
  }
}

.product-detail-list {
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  text-align: center;
  color: #999999;
  // text-transform: uppercase;

  &.mp4-confirm {
    color: #737373;
    text-align: left;
  }
}

.position-ar-link {
  position: absolute;
  left: 0;
  right: 0;
  top: 110px;
}

.scene-side-margins {
  margin: 0px 20px 40px 20px;
}
.space-side-margins {
  margin: 0px 20px 0px 20px;
}

.product-side-margins {
  margin: 20px 20px 0px 20px;
  // margin: "60px 1% 0px 1%"
}

@media (max-width: 1640px) {
  .product-name-h3 {
    font-size: 32px;
  }
  .scene-side-margins {
    margin: 0px 16px 40px 16px;
  }
  .space-side-margins {
    margin: 0px 16px 0px 16px;
  }
  .product-side-margins {
    margin: 20px 16px 0px 16px;
  }
}
.fullscreen-embed {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  border: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  z-index: 999999;
}

.ant-image-preview-operations {

  top: unset !important;
  bottom: 0 !important;
}


.share-modal-heading {
   font-style: normal;
   font-weight: 900;
   font-size: 14px;
   line-height: 140%;
   vertical-align: middle;
   color: #333333;
   margin: 0;
   margin-bottom: 10px;
   font-family: Manrope;
   &.greyish-black {
     color: #555555;
     display: flex;
     justify-content: space-between;
     &.large {
       text-align: center;
       justify-content: center;
       margin-bottom: 10px;
     }
   }
 }

 .share-modal-input {
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: #999999;
  &.black {
    color: #555555;
  }
 }
 .share-input-ph {
  background: rgba(244, 244, 244, 0.5);
  border: 1px solid #E3E3E3;
  box-sizing: border-box;
  border-radius: 4px;
 }

 .email-ph {
  .ant-select-selector{
    height: auto;
    min-height: 50px;
  }
 }

 .modal-text {
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: #333333;
  &.light {
    color: #999999;
  }
  &.green {
    color: #009D19;
  }
  &.yellow {
    color:#EAA500;
  }
  &.red {
    color: #D93025;
  }
  &.orange {
    color: #FF5F15;
  }
  &.font-16 {
    font-size: 16px;
  }
  &.font-12 {
    font-size: 12px;
  }
  &.font-14 {
    font-size: 14px;
  }
  &.gray {
    color: #777777;
    text-align: center;
  }
  &.small {
    font-size: 16px;
    color: #555555;
    &.gray{
      color: #777777;
      text-align: left;
    }
  }
  &.bold {
    font-weight: 700;

  }
  &.grey-black {
    color: #737373;
    &.font-14 {
      font-size: 14px;
    }
    &.font-16 {
      font-size: 16px;
    }
  }
  &.black {
    color: #000000D9;
  }
 }


 .radio-btn-change {
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  color: #999999;
  &.f-14 {
    font-size: 14px;
  }
  &.scene-res {
    // font-size: 14px;
  }
 }

 .modal-radio-text {
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 25px;
  color: #999999;
  &.heavy {
    font-weight: 900;
    color: #333333;
  }
 }

 .modal-radio-anchor {
  font-family: Manrope;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 120%;
  color: #276DD7 !important;
  &.bg {
    background-color: #f4f4f4;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    &.font-12{
      font-family: Avenir;
      font-weight: 300;
      font-size: 12px;
    }
  }
 }

 a.ant-typography:focus, .ant-typography a:focus, a.ant-typography:hover, .ant-typography a:hover {
  color: #276DD7 !important;
}
a.ant-typography, .ant-typography a {
  color: #276DD7 !important;
}

.ant-typography-expand, .ant-typography-edit, .ant-typography-copy {
  color: #276DD7 !important;
}

.modal-skip {
  width: unset !important;
  height: fit-content !important;
  border: none !important;
  background:  #FAFAFA !important;
  border-radius: 35px !important;
  padding: 0 !important;
  color: #555555;
  &:hover {
    background: #c7c7c7 !important;
    border: none !important;
    color: #555555 !important;
  }
  &:focus {
    border: none !important;
    background:  #FAFAFA !important;
    color: #555555;
  }
  &.square {
    border-radius: 4px !important;
    &:hover {
    background:#FAFAFA !important;
    border: none !important;
    color: #555555 !important;
  }
  &:hover {
    background: #c7c7c7 !important;
    border: none !important;
    color: #555555 !important;
  }
  &:focus {
    border: none !important;
    background: #FAFAFA !important;
    color: #555555;
  }

  &.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
    background: #FAFAFA !important;
    opacity: 0.5 !important;
    text-shadow: none;
    color: #555555 !important;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  &.ant-tooltip-disabled-compatible-wrapper {
    background: #FAFAFA !important;
    opacity: 0.5 !important;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  }
  &.font-16 {
  font-size: 16px !important;
  line-height: 120%;
  padding: 12px 24px !important;
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  vertical-align: middle;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  &.w-900 {
    font-weight: 900;
  }
}
&.font-12 {
  font-size: 12px !important;
  line-height: 120%;
  padding: 12px 24px !important;
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  vertical-align: middle;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  &.small {
    padding: 12px 15px !important;
  }
}
&.font-18 {
  font-size: 18px !important;
  line-height: 120%;
  padding: 16px 32px !important;
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  vertical-align: middle;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  &.w-900 {
    font-weight: 900;
  }
}
&.font-14 {
  font-size: 14px !important;
  line-height: 120%;
  padding: 11px 32px !important;
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  vertical-align: middle;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  &.pd {
    padding: 13px 75px !important;
    @media (max-width: 1366px) {
      padding: 13px 25px !important;
      font-size: 12px !important;
    }
    @media (max-width: 1440px) {
      font-size: 14px !important;
      padding: 13px 50px !important;

    }
  }
}

}

.modal-okay {
  font-family: Manrope;
  width: unset !important;
  height: fit-content !important;
  border: none !important;
  background: #276DD7 !important;
  border-radius: 35px !important;
  padding: 0 !important;
  color: white;
  &.round {
    border-radius: 50% !important;
    padding: 6px 10px !important;
  }
  &:hover {
    background:#205FCF !important;
    border: none !important;
    color: #ffffff !important;
  }
  &:focus {
    border: none !important;
    background: #276DD7 !important;
    color: white !important;
  }
  &:active {
    border: none !important;
    background: #276DD7 !important;
    color: white !important;
  }
  &.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
    background: #276DD7 !important;
    opacity: 0.5 !important;
    text-shadow: none;
    color: white !important;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  &.ant-tooltip-disabled-compatible-wrapper {
    background: #276DD7 !important;
    border: 1px solid #276DD7 !important;
    opacity: 0.5 !important;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  &.square {
    border-radius: 4px !important;
      &:hover {
      background:#205FCF !important;
      border: none !important;
      color: #ffffff !important;
      }
    &:focus {
      border: none !important;
      background: #276DD7 !important;
      color: white;
    }

    &:active {
      border: none !important;
      background: #276DD7 !important;
      color: white;
    }

    &.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
      background: #276DD7 !important;
      opacity: 0.5 !important;
      text-shadow: none;
      color: white !important;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    &.ant-tooltip-disabled-compatible-wrapper {
      background: #276DD7 !important;
      opacity: 0.5 !important;
      text-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    &.font-16 {
      font-size: 16px !important;
      line-height: 120%;
      padding: 12px 24px !important;
      font-family: Manrope;
      font-style: normal;
      font-weight: 500;
      vertical-align: middle;
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: capitalize;
      &.w-900 {
        font-weight: 900;
      }
      &.center-btn {
        text-transform: none !important;
        width: 100% !important;
        justify-content: center;
        font-weight: 700;

        &.pdt-20 {
          padding: 20px 24px !important;
        }
    
        &.pdt-16 {
            padding: 16px 24px !important;
        }
    
        &.width-fit {
            width: fit-content !important;
        }
    }
    }
    &.font-14 {
      font-size: 14px !important;
      line-height: 120%;
      padding: 12px 24px !important;
      font-family: Manrope;
      font-style: normal;
      font-weight: 500;
      vertical-align: middle;
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: capitalize;
    }
  }


&.font-14 {
  font-size: 14px !important;
  line-height: 120%;
  padding: 11px 32px !important;
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  vertical-align: middle;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  &.w-900 {
    font-weight: 900;
  }
  &.mat-pd {
    padding: 14px 35px !important;
  }
  &.pd {
    padding: 13px 75px !important;
    @media (max-width: 1366px) {
      padding: 13px 25px !important;
      font-size: 12px !important;
    }
    @media (max-width: 1440px) {
      font-size: 14px !important;
      padding: 13px 50px !important;

    }
  }
  &.pd-10-14 {
    padding: 10px 14px !important;
  }
  &.pd-10-16 {
    padding: 10px 16px !important;
  }
}
&.font-12 {
  font-size: 12px !important;
  line-height: 20px;
  padding: 12px 24px !important;
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  vertical-align: middle;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  &.small {
    padding: 12px 15px !important;
  }
}
&.font-18 {
  font-size: 18px !important;
  line-height: 20px;
  padding: 16px 32px !important;
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  vertical-align: middle;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  &.w-900 {
    font-weight: 900;
  }
}
&.font-14 {
  font-size: 14px !important;
  line-height: 20px;
  padding: 11px 32px !important;
  font-family: Manrope;
  font-style: normal;
  font-weight: 200;
  vertical-align: middle;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  &.mat-pd {
    padding: 14px 35px !important;
  }
  &.pd {
    padding: 13px 75px !important;
    @media (max-width: 1366px) {
      padding: 13px 25px !important;
      font-size: 12px !important;
    }
    @media (max-width: 1440px) {
      font-size: 14px !important;
      padding: 13px 50px !important;

    }
  }
  &.pd-10-14 {
    padding: 10px 14px !important;
  }
  &.pd-10-16 {
    padding: 10px 16px !important;
  }
  &.pd-6-14 {
    padding: 6px 14px !important;
  }
}
&.font-12 {
  font-size: 12px !important;
  line-height: 120%;
  padding: 12px 22px !important;
  font-family: Manrope;
  font-style: normal;
  font-weight: 400;
  vertical-align: middle;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  &.w-900 {
    font-weight: 900;
  }
}
&.h-40 {
  height: 40px !important;
}
}

.modal-okay-bg-black {
  font-family: Manrope;
  width: unset !important;
  height: fit-content !important;
  border: none !important;
  background: #000000 !important;
  border-radius: 35px !important;
  padding: 0 !important;
  color: white;
  &.round {
    border-radius: 50% !important;
    padding: 6px 10px !important;
  }
  &:hover {
    background:#2a2a2a !important;
    border: none !important;
    color: #ffffff !important;
  }
  &:focus {
    border: none !important;
    background: #2a2a2a !important;
    color: white !important;
  }
  &:active {
    border: none !important;
    background: #2a2a2a !important;
    color: white !important;
  }
  &.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
    background: #2a2a2a !important;
    opacity: 0.5 !important;
    text-shadow: none;
    color: white !important;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  &.square {
    border-radius: 4px !important;
      &:hover {
      background:#2a2a2a !important;
      border: none !important;
      color: #ffffff !important;
      }
    &:focus {
      border: none !important;
      background: #2a2a2a !important;
      color: white;
    }

    &:active {
      border: none !important;
      background: #2a2a2a !important;
      color: white;
    }

    &.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
      background: #2a2a2a !important;
      opacity: 0.5 !important;
      text-shadow: none;
      color: white !important;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    &.ant-tooltip-disabled-compatible-wrapper {
      background: #2a2a2a !important;
      opacity: 0.5 !important;
      text-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    &.font-16 {
      font-size: 16px !important;
      line-height: 120%;
      padding: 12px 24px !important;
      font-family: Manrope;
      font-style: normal;
      font-weight: 500;
      vertical-align: middle;
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: capitalize;
      &.w-900 {
        font-weight: 900;
      }
    }
    &.font-14 {
      font-size: 14px !important;
      line-height: 120%;
      padding: 12px 24px !important;
      font-family: Manrope;
      font-style: normal;
      font-weight: 500;
      vertical-align: middle;
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: capitalize;
    }
  }


&.font-14 {
  font-size: 14px !important;
  line-height: 120%;
  padding: 11px 32px !important;
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  vertical-align: middle;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  &.w-900 {
    font-weight: 900;
  }
  &.mat-pd {
    padding: 14px 35px !important;
  }
  &.pd {
    padding: 13px 75px !important;
    @media (max-width: 1366px) {
      padding: 13px 25px !important;
      font-size: 12px !important;
    }
    @media (max-width: 1440px) {
      font-size: 14px !important;
      padding: 13px 50px !important;

    }
  }
  &.pd-10-14 {
    padding: 10px 14px !important;
  }
  &.pd-10-16 {
    padding: 10px 16px !important;
  }
}
&.font-12 {
  font-size: 12px !important;
  line-height: 20px;
  padding: 12px 24px !important;
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  vertical-align: middle;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  &.small {
    padding: 12px 15px !important;
  }
}
&.font-18 {
  font-size: 18px !important;
  line-height: 20px;
  padding: 16px 32px !important;
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  vertical-align: middle;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  &.w-900 {
    font-weight: 900;
  }
}
&.font-14 {
  font-size: 14px !important;
  line-height: 20px;
  padding: 11px 32px !important;
  font-family: Manrope;
  font-style: normal;
  font-weight: 200;
  vertical-align: middle;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  &.mat-pd {
    padding: 14px 35px !important;
  }
  &.pd {
    padding: 13px 75px !important;
    @media (max-width: 1366px) {
      padding: 13px 25px !important;
      font-size: 12px !important;
    }
    @media (max-width: 1440px) {
      font-size: 14px !important;
      padding: 13px 50px !important;

    }
  }
  &.pd-10-14 {
    padding: 10px 14px !important;
  }
  &.pd-10-16 {
    padding: 10px 16px !important;
  }
  &.pd-6-14 {
    padding: 6px 14px !important;
  }
}
&.font-12 {
  font-size: 12px !important;
  line-height: 120%;
  padding: 12px 22px !important;
  font-family: Manrope;
  font-style: normal;
  font-weight: 400;
  vertical-align: middle;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  &.w-900 {
    font-weight: 900;
  }
}
&.h-40 {
  height: 40px !important;
}
}

.modal-okay-blue {
  width: unset !important;
  height: unset !important;
  padding: 0 !important;
  color: #276DD7;
  background: #FFFFFF !important;
  color: #276DD7 !important;
  border: 1px solid #276DD7 !important;
  border-radius: 35px !important;
  &.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
    background: #FFFFFF !important;
    text-shadow: none;
    opacity: 0.5 !important;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  &:hover {
    background:#276DD7 !important;
    border: 1px solid #276DD7 !important;
    color: white !important;
  }
  &.square {
    border-radius: 4px !important;
    &.medium{
      padding: 6px 14px !important;
      font-size: 14px !important;
      line-height: 100%;
      font-family: Manrope;
      font-style: normal;
      font-weight: 500;
    }
    &.invert {
      color: #FFFFFF !important;
      background: #276DD7 !important;
      padding: 8px 18px !important;
      &:hover{
        background:white !important;
        color:#276DD7 !important;
      }
      &.font-14 {
        font-size: 14px !important;
        line-height: 120%;
        padding: 11px 32px !important;
        font-family: Manrope;
        font-style: normal;
        font-weight: 400;
        vertical-align: middle;
        display: flex;
        align-items: center;
        text-align: center;
        text-transform: capitalize;
      }
    }
    &:hover {
     background:white !important;
     border: 1px solid #276DD7 !important;
    //  color: #ffffff !important;
   }
   &:hover {
    background:#276DD7 !important;
    border: 1px solid #276DD7 !important;
    color: white !important;
  }
   &.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
     background: white !important;
     opacity: 0.5 !important;
     text-shadow: none;
     -webkit-box-shadow: none;
     box-shadow: none;
   }
   &.ant-tooltip-disabled-compatible-wrapper {
     background:white !important;
     opacity: 0.5 !important;
     text-shadow: none;
     -webkit-box-shadow: none;
     box-shadow: none;
   }
  }
  &.font-16 {
    font-size: 16px !important;
    line-height: 20px;
    padding: 12px 24px !important;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    vertical-align: middle;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    &.w-900 {
      font-weight: 900;
    }
    &.center-btn {
      width: 100% !important;
      justify-content: center;
      font-weight: 700;

      &.pdt-20 {
        padding: 20px 24px !important;
      }
  
      &.pdt-16 {
          padding: 16px 24px !important;
      }
  
      &.width-fit {
          width: fit-content !important;
      }
    }
    &.border-none {
      border: none !important;
    }
  }

  &.font-14 {
    font-size: 14px !important;
    line-height: 20px;
    padding: 11px 32px !important;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    vertical-align: middle;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    &.pd {
      padding: 13px 75px !important;
      @media (max-width: 1366px) {
        padding: 13px 25px !important;
        font-size: 12px !important;
      }
      @media (max-width: 1440px) {
        font-size: 14px !important;
        padding: 13px 50px !important;

      }
    }
  }

  &.font-16 {
    font-size: 16px !important;
    line-height: 20px;
    padding: 12px 24px !important;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    vertical-align: middle;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    &.w-900 {
      font-weight: 900;
    }
  }

}

.outline-red-btn {
  width: unset !important;
  height: fit-content !important;
  padding: 0 !important;
  background: #FFFFFF !important;
  color: #d93025 !important;
  border: 1px solid #d93025 !important;
  border-radius: 35px !important;
  &.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
    background: #FFFFFF !important;
    text-shadow: none;
    opacity: 0.5 !important;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  &:hover {
    background:#d93025 !important;
    border: 1px solid #d93025 !important;
    color: white !important;
  }
  &.square {
    border-radius: 4px !important;
    &:hover {
      background:#d93025 !important;
      border: 1px solid #d93025 !important;
      color: white !important;
    }
    &:active {
      background:#d93025 !important;
      border: 1px solid #d93025 !important;
      color: white !important;
    }
    &:focus {
      background:#d93025 !important;
      border: 1px solid #d93025 !important;
      color: white !important;
    }
   &.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
     background: white !important;
     opacity: 0.5 !important;
     text-shadow: none;
     -webkit-box-shadow: none;
     box-shadow: none;
   }
   &.ant-tooltip-disabled-compatible-wrapper {
     background:white !important;
     opacity: 0.5 !important;
     text-shadow: none;
     -webkit-box-shadow: none;
     box-shadow: none;
   }
  }

  &.font-16 {
    font-size: 16px !important;
    line-height: 20px;
    padding: 10px 32px !important;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    vertical-align: middle;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    &.pd {
      padding: 13px 75px !important;
      @media (max-width: 1366px) {
        padding: 13px 25px !important;
        font-size: 12px !important;
      }
      @media (max-width: 1440px) {
        font-size: 14px !important;
        padding: 13px 50px !important;

      }
    }
    
    &.pd-10-24 {
      padding: 10px 24px !important;
    }

    &.pd-10-18{
      padding: 10px 18px !important;
    }
  }

  &.font-14 {
    font-size: 14px !important;
    line-height: 20px;
    padding: 11px 32px !important;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    vertical-align: middle;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    &.pd {
      padding: 13px 75px !important;
      @media (max-width: 1366px) {
        padding: 13px 25px !important;
        font-size: 12px !important;
      }
      @media (max-width: 1440px) {
        font-size: 14px !important;
        padding: 13px 50px !important;

      }
    }

    &.pd-10-24 {
      padding: 10px 24px !important;
    }

    &.pd-10-18{
      padding: 10px 18px !important;
    }
  }

  &.font-12 {
    font-size: 12px !important;
    line-height: 120%;
    padding: 12px 24px !important;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    vertical-align: middle;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    &.small {
      padding: 12px 15px !important;
    }
  }

  &.w-900 {
    font-weight: 900;
  }

  &.w-600 {
    font-weight: 600;
  }

  &.adjust-size{
    font-size: 15px;
    line-height: 120%;
    padding: 7px 7px !important;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    vertical-align: middle;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    @media (max-width: 1600px) {
      font-size: 12px;
      padding: 4px 4px !important;
    }
    @media (max-width: 1350px) {
      font-size: 10px;
      padding: 4px 4px !important;
    }
    @media (max-width: 1200px) {
      font-size: 8px;
      padding: 4px 4px !important;
    }
  }

}

.modal-okay-invert {
  width: unset !important;
  height: fit-content !important;
  border: 1px solid #276DD7!important;
  background: white !important;
  border-radius: 35px !important;
  padding: 0 !important;
  color: #276DD7;

  &:hover {
    background:#276DD7 !important;
    border: 1px solid #276DD7!important;
    color: white !important;
  }
  &:focus {
    background:#276DD7 !important;
    border: 1px solid #276DD7!important;
    color: white !important;
  }
  &:active {
    background:#276DD7 !important;
    border: 1px solid #276DD7!important;
    color: white !important;
  }
  &.ant-tooltip-disabled-compatible-wrapper {
    background: white !important;
    opacity: 0.5 !important;
    text-shadow: none;
    color: #276DD7 !important;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  &.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
    background: white !important;
    opacity: 0.5 !important;
    text-shadow: none;
    color: #276DD7 !important;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  &.square-disabled {
    border-radius: 4px !important;
  }
  &.square {
    border-radius: 4px !important;
    &:hover {
      background:#276DD7 !important;
      border: 1px solid #276DD7!important;
      color: white !important;
    }
    &:focus {
      background:#276DD7 !important;
      border: 1px solid #276DD7!important;
      color: white !important;
    }

    &:active {
      background:#276DD7 !important;
      border: 1px solid #276DD7!important;
      color: white !important;
    }

    &.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
      background: white !important;
      opacity: 0.5 !important;
      text-shadow: none;
      color: #276DD7 !important;
      -webkit-box-shadow: none;
      box-shadow: none;
      border: none !important;
    }
    &.ant-tooltip-disabled-compatible-wrapper {
      background: white !important;
      opacity: 0.5 !important;
      text-shadow: none;
      color: #276DD7 !important;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    &.red{
      color:#D93025
    }
  }
  &.font-16 {
  font-size: 16px !important;
  line-height: 120%;
  padding: 12px 24px !important;
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  vertical-align: middle;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
}
&.font-18 {
  font-size: 18px !important;
  line-height: 120%;
  padding: 16px 32px !important;
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  vertical-align: middle;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
}
&.font-12 {
  font-size: 12px !important;
  line-height: 120%;
  padding: 12px 24px !important;
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  vertical-align: middle;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  &.small {
    padding: 12px 15px !important;
  }
  &.pd-10-10 {
    padding: 10px 10px !important;
  }
}
  &.font-14 {
    font-size: 14px !important;
    line-height: 120%;
    padding: 11px 32px !important;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    vertical-align: middle;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    &.pd-11 {
      padding: 11px 11px !important;
    }
    &.pd-15 {
      padding: 11px 15px !important;
    }
    &.pd-28 {
      padding: 11px 28px !important;
    }
    &.pd-35 {
      padding: 11px 35px !important;
    }
    &.pd-24 {
      padding: 11px 24px !important;
    }
    &.pd {
      padding: 13px 75px !important;
      @media (max-width: 1366px) {
        padding: 13px 25px !important;
        font-size: 12px !important;
      }
      @media (max-width: 1440px) {
        font-size: 14px !important;
        padding: 13px 50px !important;

      }
    }
    &.pd-16 {
      padding: 16px 75px !important;
    }
    &.pd-10{
      padding: 8px 0px !important;
    }
    &.pd-10-16{
      padding: 10px 16px !important;
    }
    &.pd-10-14{
      padding: 10px 14px !important;
    }
    &.w-900 {
      font-weight: 900;
    }
    &.w-700 {
      font-weight: 900;
    }
    &.w-600 {
      font-weight: 600;
    }
  }
}

.modal-okay-red {
  // width: fit-content !important;
  // height: 46px !important;
  width: unset !important;
  height: fit-content !important;
  padding: 0 !important;
  border-color:#D93025 !important;
  background: #D93025 !important;
  color: white !important;
  border-radius: 35px !important;
  &.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active{
    opacity: 0.5 !important;
    background: #d93025 !important;
    border-color:  #d93025 !important;
    text-shadow: none;
    color: #ffffff !important;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  &:hover {
    background:#D2281F !important;
    border-color:#D2281F !important;
    color: #ffffff !important;
  }
  &:focus {
    border: none !important;
    background: #D2281F!important;
    color: white;
  }
  &:active {
    border: none !important;
    background: #D2281F !important;
    color: white;
  }
  &.square {
    border-radius: 4px !important;
    &:hover {
     background: #D2281F !important;
     border: 1px solid #D2281F !important;
     color: #ffffff !important;
   }
   &:focus {
    border: none !important;
    background: #D2281F!important;
    color: white;
  }
  &:active {
    border: none !important;
    background: #D2281F !important;
    color: white;
  }
   &.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
     background: #D2281F !important;
     opacity: 0.5 !important;
     text-shadow: none;
     -webkit-box-shadow: none;
     box-shadow: none;
     color: #ffffff !important;
   }
   &.ant-tooltip-disabled-compatible-wrapper {
     background:#D2281F !important;
     opacity: 0.5 !important;
     text-shadow: none;
     -webkit-box-shadow: none;
     color: #ffffff !important;
     box-shadow: none;
   }
   &.invert {
    border-color:#D93025 !important;
    background: white !important;
    color: #D93025 !important;
    &:hover{
      color: white !important;
      background:#D93025 !important;
    }
    &:focus{
      color: white !important;
      background:#D93025 !important;
    }
    &:active {
      background: #D2281F !important;
      color: white;
    }
  }
}

  &.font-12 {
    font-size: 12px !important;
    line-height: 120%;
    padding: 12px 24px !important;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    vertical-align: middle;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    &.small {
      padding: 12px 15px !important;
    }
  }
  div {
    &:hover {
      color: #ffffff !important;
    }
  }
  &.font-16 {
    font-size: 16px !important;
    line-height: 120%;
    padding: 12px 24px !important;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    vertical-align: middle;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
  }
  &.font-14 {
    font-size: 14px !important;
    line-height: 120%;
    padding: 11px 32px !important;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    vertical-align: middle;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    &.w-900 {
      font-weight: 900;
    }
    &.pd-20{
      padding: 11px 20px !important;
    }
  }
}


 .modal-okay-orange {
  // width: fit-content !important;
  // height: 46px !important;
  width: unset !important;
  height: fit-content !important;
  padding: 0 !important;
  border-color:#D93025 !important;
  background: #D93025 !important;
  color: white !important;
  border-radius: 35px !important;
  &.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active{
    opacity: 0.5 !important;
    background: #d93025 !important;
    border-color:  #d93025 !important;
    text-shadow: none;
    color: #ffffff !important;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  &:hover {
    background:#D2281F !important;
    border-color:#D2281F !important;
    color: #ffffff !important;
  }
  &:focus {
    border: none !important;
    background: #D2281F!important;
    color: white;
  }
  &:active {
    border: none !important;
    background: #D2281F !important;
    color: white;
  }
  &.square {
    border-radius: 4px !important;
    &:hover {
     background: #D2281F !important;
     border: 1px solid #D2281F !important;
     color: #ffffff !important;
   }
   &:focus {
    border: none !important;
    background: #D2281F!important;
    color: white;
  }
  &:active {
    border: none !important;
    background: #D2281F !important;
    color: white;
  }
   &.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
     background: #D2281F !important;
     opacity: 0.5 !important;
     text-shadow: none;
     -webkit-box-shadow: none;
     box-shadow: none;
     color: #ffffff !important;
   }
   &.ant-tooltip-disabled-compatible-wrapper {
     background:#D2281F !important;
     opacity: 0.5 !important;
     text-shadow: none;
     -webkit-box-shadow: none;
     color: #ffffff !important;
     box-shadow: none;
   }
   &.invert {
    border-color:#D93025 !important;
    background: white !important;
    color: #D93025 !important;
    &:hover{
      color: white !important;
      background:#D93025 !important;
    }
    &:focus{
      color: white !important;
      background:#D93025 !important;
    }
    &:active {
      background: #D2281F !important;
      color: white;
    }
  }
  }

  &.font-12 {
    font-size: 12px !important;
    line-height: 120%;
    padding: 12px 24px !important;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    vertical-align: middle;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    &.small {
      padding: 12px 15px !important;
    }
  }
  div {
    &:hover {
      color: #ffffff !important;
    }
  }
  &.font-16 {
    font-size: 16px !important;
    line-height: 120%;
    padding: 12px 24px !important;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    vertical-align: middle;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
  }
  &.font-14 {
    font-size: 14px !important;
    line-height: 120%;
    padding: 11px 32px !important;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    vertical-align: middle;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    &.w-900 {
      font-weight: 900;
    }
    &.pd-20{
      padding: 11px 20px !important;
    }
  }
}


.modal-okay-gray {
  width: unset !important;
  height: unset !important;
  padding: 0 !important;
  background: #FFFFFF !important;
  border: 1px solid #737373 !important;
  border-radius: 35px !important;
  color: #737373;
  &.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
    background: #FFFFFF !important;
    color: #737373 !important;
    text-shadow: none;
    opacity: 0.5 !important;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  &:hover {
    background:#737373 !important;
    border: 1px solid #737373 !important;
    color: white !important;
  }
  &:active {
    background:#737373 !important;
    border: 1px solid #737373 !important;
    color: white !important;
  }
  &:focus {
    background:#737373 !important;
    border: 1px solid #737373 !important;
    color: white !important;
   }
   &.square-disabled {
    border-radius: 4px !important;
  }
  &.square {
    border-radius: 4px !important;
    &:hover {
      background:white !important;
      border: 1px solid #737373 !important;
      color: black !important;
   }
   &.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
     background: white !important;
     opacity: 0.5 !important;
     text-shadow: none;
     -webkit-box-shadow: none;
     box-shadow: none;
   }
   &:focus {
    background:#737373 !important;
    border: 1px solid #737373 !important;
    color: white !important;
   }
   &:active {
    background:#737373 !important;
    border: 1px solid #737373 !important;
    color: white !important;
  }
   &.ant-tooltip-disabled-compatible-wrapper {
     background:white !important;
     opacity: 0.5 !important;
     text-shadow: none;
     -webkit-box-shadow: none;
     box-shadow: none;
   }
   &.font-14 {
    font-size: 14px !important;
    line-height: 120%;
    padding: 11px 24px !important;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    vertical-align: middle;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    &.mat-pd {
      padding: 14px 35px !important;
    }
    &:hover {
      background:#737373 !important;
      border: 1px solid #737373 !important;
      color: white !important;
    }
    &.pd-20{
      padding: 11px 20px !important;
      &:hover {
        background:#737373 !important;
        border: 1px solid #737373 !important;
        color: white !important;
      }
    }
   }
  }
  &.font-18 {
    font-size: 18px !important;
    line-height: 120%;
    padding: 16px 32px !important;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    vertical-align: middle;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
  }

  &.font-16 {
    font-size: 16px !important;
    line-height: 120%;
    padding: 12px 24px !important;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    vertical-align: middle;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
  }
  &.font-14 {
    font-size: 14px !important;
    line-height: 120%;
    padding: 11px 32px !important;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    vertical-align: middle;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    &.w-900 {
      font-weight: 900;
    }
    &.pd-20{
      padding: 11px 20px !important;
      &:hover {
        background:#737373 !important;
        border: 1px solid #737373 !important;
        color: white !important;
      }
    }
  }
   &.font-12 {
    font-size: 12px !important;
    line-height: 120%;
    padding: 10px 25px !important;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    vertical-align: middle;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
   }

  div {
    &:hover {
      color: #737373 !important;
    }
  }
}

.modal-okay-light-gray {
  width: unset !important;
  height: fit-content !important;
  border: none !important;
  background: #F7F7F7 !important;
  border-radius: 35px !important;
  padding: 0 !important;
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  vertical-align: middle;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  color: #737373 !important;
  .ant-btn:hover, .ant-btn:focus, .ant-btn:active {
    color: #737373 !important;
  }

   &:hover {
     background: #E4E4E4 !important;
     border: none !important;
     color: #737373 !important;
   }
   &.square {
     border-radius: 4px !important;
     &:hover {
      background: #E4E4E4 !important;
      border: none !important;
      color: #737373 !important;
    }
    &.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
      background: #F7F7F7 !important;
      opacity: 0.5 !important;
      text-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    &.ant-tooltip-disabled-compatible-wrapper {
      background: #F7F7F7 !important;
      opacity: 0.5 !important;
      text-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    &.font-16 {
      font-size: 16px !important;
      line-height: 120%;
      padding: 12px 24px !important;
      font-family: Manrope;
      font-style: normal;
      font-weight: 500;
      vertical-align: middle;
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: capitalize;
      &.w-900 {
        font-weight: 900;
      }
    }

    &.font-14 {
      font-size: 14px !important;
      line-height: 120%;
      padding: 12px 24px !important;
      font-family: Manrope;
      font-style: normal;
      font-weight: 500;
      vertical-align: middle;
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: capitalize;
      &.w-900 {
        font-weight: 900;
      }

    }
   }
 }

.modal-okay-black {
  width: unset !important;
  height: fit-content !important;
  padding: 0 !important;
  background: #FFFFFF !important;
  border: 1px solid #333333 !important;
  border-radius: 35px !important;
  &.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
    background: #FFFFFF !important;
    text-shadow: none;
    opacity: 0.5 !important;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  &:hover {
    background: #FFFFFF !important;
    border: 1px solid #333333 !important;
    color: #333333 !important;
  }
  &.square {
    border-radius: 4px !important;
    &:hover {
     background: white !important;
     border: 1px solid #333333 !important;
     color: #ffffff !important;
   }
   &.invert {
    background: #1f1f1f !important;
    border: 1px solid #333333 !important;
    color: #ffffff !important;
   }
   &.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
     background:white !important;
     opacity: 0.5 !important;
     text-shadow: none;
     -webkit-box-shadow: none;
     box-shadow: none;
   }
   &.ant-tooltip-disabled-compatible-wrapper {
     background:white !important;
     opacity: 0.5 !important;
     text-shadow: none;
     -webkit-box-shadow: none;
     box-shadow: none;
   }
   &.font-14 {
    font-size: 14px !important;
    line-height: 120%;
    padding: 11px 32px !important;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    vertical-align: middle;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    &.pd {
      padding: 13px 75px !important;
      @media (max-width: 1366px) {
        padding: 13px 25px !important;
        font-size: 12px !important;
      }
      @media (max-width: 1440px) {
        font-size: 14px !important;
        padding: 13px 50px !important;

      }
    }
  }
  &.font-12 {
    font-size: 12px !important;
    line-height: 120%;
    padding: 10px 24px !important;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    vertical-align: middle;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
   }
  }
  span {
    &:hover {
      color: #333333 !important;
    }
  }
}

.modal-okay-custom {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  padding: 10px 12px !important;
  line-height: 80%;
  text-align: center;
  font-family: Manrope;
  color: #FFFFFF;
  &.blue {
   color: #276DD7;
   font-weight: 500;
   line-height: 120%;
   }
   &.outline-red {
     color: #E54547;
   }
  &.gray {
   color: #737373;
   &.medium {
     margin: 10px 24px 10px 24px;
     font-size: 12px;
   }
   &.medium-margin {
     font-size: 12px;
   }
   &.large {
     font-size: 15px;
   }
   &:hover {
     color: #737373;
   }
  }
  &.black {
   color: #333333;
   &.medium {
     margin: 10px 24px 10px 24px;
     font-size: 12px;
   }
   &.medium-margin {
     font-size: 12px;
     &:hover {
       color:#333333;
     }
   }
   &.large {
     font-size: 15px;
   }
   &:hover {
     color: #333333;
   }
  }
  &.red {
   color: #D93025;
   &.medium {
     margin: 10px 24px 10px 24px;
     font-size: 12px;
   }
   &:hover {
     color: #d93025;
   }
  }
  &:hover {
   color:#ffffff;
 }
 &.medium-margin {
   font-size: 12px;
   &:hover {
     color:#333333;
   }
 }
 }

 .modal-okay-text {
   font-style: normal;
   font-weight: 600;
   font-size: 14px;
   margin: 13px 32px 13px 32px;
   line-height: 80%;
   text-align: center;
   font-family: Manrope;
   color: #FFFFFF;
   &.white{
    &:hover{
      color: #FFFFFF !important;
    }
   }
   &.blue {
    color: #276DD7;
    font-weight: 500;
    line-height: 120%;
    }
    &.outline-red {
      color: #E54547;
    }
   &.gray {
    color: #737373;
    &.medium {
      margin: 10px 24px 10px 24px;
      font-size: 12px;
    }
    &.medium-margin {
      font-size: 12px;
    }
    &.large {
      font-size: 15px;
    }
    &:hover {
      color: #737373;
    }
   }
   &.black {
    color: #333333;
    &.medium {
      margin: 10px 24px 10px 24px;
      font-size: 12px;
    }
    &.medium-margin {
      font-size: 12px;
      &:hover {
        color:#333333;
      }
    }
    &.large {
      font-size: 15px;
    }
    &:hover {
      color: #333333;
    }
   }
   &.red {
    color: #D93025;
    &.medium {
      margin: 10px 24px 10px 24px;
      font-size: 12px;
    }
    &:hover {
      color: #d93025;
    }
   }
   &:hover {
    color:#ffffff;
  }
  &.medium-margin {
    font-size: 12px;
    &:hover {
      color:#333333;
    }
  }
 }

 .share-btn {
  width: unset !important;
  height: unset !important;
  padding: 0 !important;
  background: #F4F4F4 !important;
  border: 1px solid #E3E3E3 !important;
  border-radius: 4px !important;
  &.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
    opacity: 0.5;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  &:hover {
    background:#F4F4F4 !important;
    border: 1px solid #E3E3E3 !important;
    color: #333333 !important;
  }
}

.share-btn-txt {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  padding: 13px 32px 11px 32px;
  line-height: 100%;
  text-align: center;
  font-family: Avenir;
  color: #333333;
  &:hover {
    color:#333333 !important;
  }
}
 .modal-share {
  .ant-modal-footer {
    border-top: unset;
    padding-bottom: 40px;
    padding-right: 30px;
    padding-left: 30px;
  }
 }

.modal-footer-settings {
  .ant-modal-footer {
    border-top: unset;
    padding-bottom: 40px;
    padding-right: 40px;
    padding-left: 40px;
  }
}

 .modal-embed {
  .ant-modal-footer {
   display: none !important;
   border-top: unset;
  padding-bottom: 40px;
  padding-right: 30px;
  }
 }
 .modal-flag {
  width: 800px;
  position: absolute;
  right: 0;
  left: 0;
  top: 10px;
  .ant-modal-content {
    .ant-modal-body {
      padding: 32px;
      padding-bottom: 22px;
    }
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-modal-close {
    display: none;
  }
 }

 .help-override {
  width: 60% !important;
  position: absolute;
  right: 0;
  left: 0;
  top: 50px;
  &.reject {
    width: 30% !important;
  }
  .ant-modal-content {
    .ant-modal-body {
      padding: 32px;
      // padding-bottom: 0px;
    }
  }
  .ant-modal-footer {
    // border-top: 1px solid white !important;
    // padding: 32px;
    display: none;
  }
  @media(max-width: 1536px) {
    width: 80% !important;

  }
 }

 .modal-flag-override{
  width: 60% !important;
  position: absolute;
  right: 0;
  left: 0;
  top: 50px;
  &.reject {
    width: 30% !important;
  }
  .ant-modal-content {
    .ant-modal-body {
      padding: 32px;
      // padding-bottom: 0px;
    }
  }
  .ant-modal-footer {
    // border-top: 1px solid white !important;
    // padding: 32px;
    display: none;
  }
  .ant-modal-close {
    display: none;
  }
  @media(max-width: 1536px) {
    width: 80% !important;

  }
 }

 .modal-share-success {
   width: 30%;
  .ant-modal-footer {
    border-top: unset;
    padding-bottom: 40px;
    text-align: center;
  }
 }

 .success-modal-setting {
  width: 60% !important;
  .ant-modal-body {
    padding: 0 !important;
  }

  .ant-modal-footer {
    border-top: unset;
    padding-bottom: 32px;
    text-align: center;
  }
 }
 .success-modal-text {
  font-family: Avenir;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 38px;
  text-align: center;
  color: #276DD7;
  &.normal {
    font-weight: 500;
    font-size: 14px;
    text-align: left;
  }
 }

.upload-box-height {
  // line-height: 120% !important;
  background-color: rgba(244, 244, 244, 0.5) !important;
  border: 1px dashed #d8d7d7 !important;
  border-radius: 4px !important;
  padding-bottom: 40px;
  text-align: center;
  &:hover {
    border: 1px dashed #276DD7 !important;
  }
  .ant-legacy-form-item-control {
    line-height: 120% !important;
  }
  .ant-upload-select {
    background-color: transparent !important;
  }
  .ant-upload.ant-upload-select {
    min-height: 0px !important;
    border: none!important;
    &:hover {
      border: none !important;
    }
  }
  .ant-upload-list {
    // position: unset !important;
    position: relative !important;
    left: 0;
    right: 0;
    top: 40px;

    margin: auto;
    width: 250px !important;
    // margin-top: 35px;
    .ant-upload-list-item {
      margin-bottom: 18px;
    }
  }
}

.m-b {
  margin-bottom: 0px !important;
  .ant-legacy-form-item-control-wrapper {
    line-height: 120% !important;
    .ant-legacy-form-item-control {
      line-height: 120% !important;
      .ant-legacy-form-item-children {
        line-height: 120% !important;
      }
    }
  }
}

.upload-box-height-confirm {
  margin-left: 0px;
  height: auto;
  min-height: 140px;
  border-radius: 4px !important;
  border: 1px dashed #d8d7d7 !important;
  background-color: #f9f9f9 !important;
  .ant-upload.ant-upload-select {
    min-height: 140px !important;
    background-color: #f9f9f9 !important;

    border: none!important;
    &:hover {
      border: none !important;
    }
  }
  .ant-upload-select {
    padding: 16px 10px 0px 10px !important;
    height: auto !important;

  }

  .ant-upload-list {
    position: relative !important;
    top: -50px !important;
    left: 50%;
    margin-left: -125px;
    width: 250px !important;
    margin-top: 30px;
    line-height: 32px !important;
    .ant-upload-list-item {
      margin-top: 20px;
    }
  }

}

.safe-zone-wrapper .ant-input-number-lg input {
  font-size: 12px;
  font-family: Manrope;
}

.confirm-height {
  .ant-legacy-form-item-control-wrapper {
    //   line-height: 0px !important;
      .ant-legacy-form-item-control {
        line-height: 0px !important;
      }
    }
}

.upload-box-pos {
  .ant-upload.ant-upload-select {
    min-height: 200px !important;
    border: 1px dashed #d8d7d7 !important;
    &:hover {
      border: 1px dashed #276DD7 !important;
    }
  }
  .ant-upload-list {
    position: relative !important;
    text-align: center;
    top: -60px !important;
    /* right: -19%; */
    /* margin: auto; */
    left: 50%;
    margin-left: -125px;
    width: 250px !important;
    /* margin-top: 5px; */
  }
}

.modal-archive-3d{
  height: 150px !important;
  &.h-174{
    height: 174px !important;
  }
}
.upload-box-hover-color{
&:hover {
    border: 1px dashed #276DD7 !important;
  }
}

.upload-box-color {
  // line-height: 120% !important;
  background-color: rgba(244, 244, 244, 0.5) !important;
  border: 1px dashed #d8d7d7 !important;
  border-radius: 4px !important;
  padding-bottom: 40px;
  text-align: center;
  &:hover {
    border: 1px dashed #276DD7 !important;
  }
  .ant-legacy-form-item-control {
    line-height: 120% !important;
  }
  .ant-upload-select {
    background-color: transparent !important;
  }
  .ant-upload.ant-upload-select {
    min-height: 0px !important;
    border: none!important;
    &:hover {
      border: none !important;
    }
  }
  .ant-upload-list {
    // position: unset !important;
    position: relative !important;
    left: 0;
    right: 0;
    top: 40px;

    margin: auto;
    width: 250px !important;
    // margin-top: 35px;
    .ant-upload-list-item {
      margin-bottom: 18px;
    }
  }
}

.upload-box-color-adjust-form-heights {
  // line-height: 120% !important;
  background-color: rgba(244, 244, 244, 0.5) !important;
  border: 1px dashed #d8d7d7 !important;
  border-radius: 4px !important;
  padding-bottom: 40px;
  text-align: center;

  &.h-176{
    height: 176px !important;
  }

  &:hover {
    border: 1px dashed #276DD7 !important;
  }
  .ant-legacy-form-item-control {
    line-height: 120% !important;
  }
  .ant-upload-select {
    background-color: transparent !important;
  }
  .ant-upload.ant-upload-select {
    min-height: 0px !important;
    border: none!important;
    &:hover {
      border: none !important;
    }
  }
  .ant-upload-list {
    // position: unset !important;
    position: relative !important;
    left: 0;
    right: 0;
    top: 20px;

    margin: auto;
    width: 250px !important;
    // margin-top: 35px;
    .ant-upload-list-item {
      margin-bottom: 10px;
    }
  }
}

.upload-box-color-admin {
  background-color: rgba(244, 244, 244, 0.5) !important;
  border: 1px dashed #d8d7d7 !important;
  border-radius: 4px !important;
  padding-bottom: 0px;
  text-align: center;
  &:hover {
    border: 1px dashed #276DD7 !important;
  }
  .ant-upload-select {
    background-color: transparent !important;
  }
  .ant-upload.ant-upload-select {
    min-height: 0px !important;
    border: none!important;
    &:hover {
      border: none !important;
    }
  }
  .ant-upload-list {
    position: unset !important;
    margin: auto;
    width: 250px !important;
    margin-top: 5px;
    .ant-upload-list-item {
      margin-bottom: 18px;
    }
  }
}

.ant-upload-text-w {
  width: 100%;
}

.ant-upload-list-item .ant-upload-list-item-card-actions {
  opacity: 1;
}

.ant-upload-list-item-info .ant-upload-text-icon .anticon {
  left: 0;
}

.assign-artist-dropdown > div {
  height: 30px;
  padding-top: 9px
}

.ant-select-selection-item > .model_count_row {
  display: none;
}

.assign-artist-dropdown {
  height: 50px;
  display: flex;
  align-items: center;
}

.assign-artist-dropdown > .ant-select-item-option-content {
  height: 100%;
  margin-top: 2px;
}
.model_count {
  margin-top: 6px;
  margin-right: 8px;
  background-color: #E7E7E7;
   padding: 4px 4px 4px 4px;
  border-radius: 4px;
}

.ant-upload-list-item-progress {
  top: 22px !important;
}
.ant-upload-list-item-card-actions-btn.ant-btn-sm {
  opacity: 1;
}

// .ant-progress-inner {
//   background-color: #c3a7a7 !important;
// }

.ant-radio-checked .ant-radio-inner{
  border-color: black !important ;
}

.ant-radio-checked .ant-radio-inner:after{
  background-color: black;
}

.ant-radio:hover .ant-radio-inner {
  border-color: black ;
}

.ant-radio-checked .ant-radio-inner:focus{
  border-color: black;
}

.ant-switch-checked {
  background-color: black;
}

.blue-color.ant-switch-checked {
  background-color: #276DD7 !important
}

.card-360-btn {
  background: #333333;
  border: none;
  border-radius: 4px;
  padding: 36px;
  height: 94px;
  width: 160px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  // &::selection {
  //   border: 1px solid #FFFFFF;
  // }
}

.card-360-btn-text {
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  color: #FFFFFF;
}

.modal-select-text {
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: #999999;
}


.pulse {
  margin:100px;
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background:white;
  cursor: pointer;
  box-shadow: 0 0 0 white;
  animation: pulse 2s infinite;
}
.pulse:hover {
  animation: none;
}

// .cursorLink img{
//   animation: pulse 2s infinite, magnified 2s 1;
// }

// .cursorLink:hover img{
//   animation: none;
// }

@keyframes magnified{
  0%{
    transform: scale(1.1,1.1);
  }
  70%{
    transform: scale(1.1,1.1);
  }
  100%{
    transform: scale(1,1);
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 white;
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 white;
    box-shadow: 0 0 0 0 white;
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
      box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
      box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}

@keyframes pulse-pointer {
  0% {
    transform: scale(0.75);
    box-shadow: 0 0 0 0 rgb(0, 60, 255);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 255, 0.0);
  }

  100% {
    transform: scale(0.75);
    box-shadow: 0 0 0 0 rgb(0, 68, 255);
  }
}

.continue-cta-green {
  background: #25D6A4 !important;
  border-radius: 35px !important;
  border: 1px solid #25d6a4 !important;
  height: unset !important;
  width: unset !important;
  padding: 0 !important;
  &:hover {
    background: #25d6a4 !important;
    border: 1px solid #25d6a4 !important;
  }

  &.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
    background:rgba(217, 48, 37, 0.04) !important;
    opacity: 0.5;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.continue-btn-green-text {
  font-style: normal;
  font-weight: 600;
  font-family: Avenir;
  padding: 11px 32px 11px 32px;
  font-size: 14px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 1px;
  color: #FFFFFF !important;
  &:hover{
    color:#FFFFFF !important;
  }
}

.continue-cta-red {
  background: white !important;
  border-radius: 35px !important;
  border: 1px solid #D93025 !important;
  height: unset !important;
  width: unset !important;
  padding: 0 !important;
  &:hover {
    background: white !important;
    border: 1px solid #D93025 !important;
  }
}

.continue-btn-red-text {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  padding: 11px 32px 11px 32px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 1px;
  font-family: Avenir;
  color:#D93025 !important;
  &:hover{
    color: #D93025 !important;
  }
}

.continue-cta-blue {
  background: white !important;
  border-radius: 35px !important;
  border: 1px solid #276DD7 !important;
  height: unset !important;
  width: unset !important;
  padding: 0 !important;
  &:hover {
    background: white !important;
    border: 1px solid #276DD7 !important;
  }
}
.continue-btn-blue-text {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  padding: 10px 24px 10px 24px;
  line-height: 100%;
  text-align: center;
  font-family: Manrope;
  letter-spacing: 1px;
  color:#276DD7 !important;
  &:hover{
    color: white !important;
  }
  &.font-14{
    font-weight: 600;
    font-size: 14px;
    font-family: Manrope;
  }
}

.modal-space {
  .ant-modal-footer {
    padding: 0 !important;
    padding: 0px 30px 30px 30px !important;
    border-top: none !important;
  }
  .ant-modal-close {
    display: none;
  }
}

.modal-space-closable {
  .ant-modal-footer {
    padding: 0 !important;
    padding: 0px 40px 40px 40px !important;
    border-top: none !important;
  }
}

.grid-controls-modal{
  width: 80% !important;

  .ant-modal-footer {
    padding: 0 !important;
    padding: 0px 40px 40px 40px !important;
    border-top: none !important;
  }
  .ant-modal-close {
    display: none;
  }
}

.safe-zone-modal {
    width: 80% !important;
    height: 90% !important;

  .ant-modal-footer {
    padding: 0 !important;
    padding: 0px 40px 40px 40px !important;
    border-top: none !important;
  }
  .ant-modal-close {
    display: none;
  }

  @media screen and (min-width: 2000) {
    width: 50% !important;
  }
}

@media (min-width: 2000px) {
  .safe-zone-modal {
    width: 65% !important;
  }
}

.overlay-controls-modal {
  width: 80% !important;
  height: 90% !important;

  .ant-modal-footer {
    padding: 0 !important;
    padding: 0px 40px 40px 40px !important;
    border-top: none !important;
  }
  .ant-modal-close {
    display: none;
  }
}

.modal-space-closable {
  .ant-modal-footer {
    padding: 0 !important;
    padding: 0px 40px 40px 40px !important;
    border-top: none !important;
  }
}

.modal-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  color: #333333;
  font-family: Manrope;
  margin: 0;
  margin-bottom: 8px;
  &.gray {
    color: #616060
  }
}

.orientation-heading {
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #737373;
}
.ori-img-bg {
  width: 193px;
  height: 160px;
  cursor: pointer;
  background: #FAFAFA;
  margin-right: 16px;
  position: relative;
  border-radius: 4px;
  // padding: 20px;
  &.aspect {
    width: 193px;
    height: 193px;
  }
  &.m-modal {
    margin-right: 7px;
    width: 130px;
    height: 90px;
  }
  &.s-modal {
    margin-right: 7px;
    width: 100px;
    height: 70px;
  }
  &.s-image-modal {
    margin-right: 7px;
    width: 100px;
    height: 100px;
  }
}

.ori-img {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  &.landscape {
    // width: 120px;
    // height: 80px;
    max-width: 80%;
    height: auto;
    // object-fit: cover;
  }
  &.portrait {
    // width: 80px;
    // height: 120px
    max-width: 40%;
    height: auto;
    // object-fit: cover;
  }
  &.square {
    // width: 120px;
    // height: 120px;
    max-width: 60%;
    height: auto;
    // object-fit: cover;
  }
  &.custom {
    // width: 168px;
    // height: 112px;
    max-width: 80%;
    height: auto;
    // object-fit: cover;
  }
  &.web {
    // width: 168px;
    // height: 112px;
    max-width: 80%;
    height: auto;;
    // object-fit: contain;
  }
  &.monitor {
    // width: 168px;
    // height: 126px;
    max-width: 80%;
    height: auto;
    // object-fit: contain;
  }
  &.screen-16-9{
    // width: 168px;
    // height: 94.5px;
    max-width: 80%;
    height: auto;
    // object-fit: contain;
  }
  &.screen-16-10{
    // width: 168px;
    // height: 105px;
    max-width: 80%;
    height: auto;
    // object-fit: contain;
  }
  &.screen-1-9-1 {
    // width: 168px;
    // height: 88.42px;
    max-width: 80%;
    height: auto;
    // object-fit: contain;
  }
  &.screen-2-3 {
    // width: 168px;
    // height: 112px;
    max-width: 40%;
    height: auto;
    // object-fit: contain;
  }
  &.screen-3-4 {
    // width: 168px;
    // height: 126px;
    max-width: 40%;
    height: auto;
    // object-fit: contain;
  }
  &.screen-9-16 {
    // width: 168px;
    // height: 94.5px;
    max-width: 30%;
    height: auto;
    // object-fit: contain;
  }
  &.screen-10-16 {
    // width: 168px;
    // height: 105px;
    max-width: 30%;
    height: auto;
    // object-fit: contain;
  }
  &.screen-1-1-9 {
    // width: 168px;
    // height: 88.42px;
    max-width: 30%;
    height: auto;
    // object-fit: contain;
  }
}

.width-height-label {
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  color: #737373;
  &.f-14 {
    font-size: 14px;
  }
  &.greyish-black {
    color: #555555;
  }
  &.black {
    color: #262626;
  }
  &.s-modal{
    font-size: 12px;
    &.gray {
      color: #777777;
    }
  }
}

.custom-res-row {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.note-bg {
  background: #F2F6FD;
  border: 1px solid #E9F0FB;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 13px;
  margin-top: 30px;

  &.config {
    margin-top: 0;
    padding: 16px;
  }
  &.red {
    background: #FEF6F6;
    border: 1px solid #FEF6F6;
  }
}

.model-space-alert {
  position: absolute;
  right: 0;
  left: 0;
  top: 90px;
  .ant-modal-content {
    .ant-modal-footer {
      padding: 0 !important;
      text-align: center;
      padding: 0px 30px 30px 30px !important;
      border-top: none !important;
    }
    .ant-modal-close {
      display: none;
    }
  }
}

.modal-lifestyle-request {
  .ant-modal-content {
    .ant-modal-footer {
      padding: 0 !important;
      text-align: center;
      padding: 0px 40px 40px 40px !important;
      border-top: none !important;
    }
    .ant-modal-close {
      display: none;
    }
  }
}

.silo-success-modal {
  padding: 0 !important;
  .ant-modal-content {
    .ant-modal-footer {
      padding: 0 !important;
      text-align: center;
      padding: 0px 30px 30px 30px !important;
      border-top: none !important;
    }
  }
}

.modal-lifestyle-request-right {
  .ant-modal-content {
    .ant-modal-footer {
      padding: 0 !important;
      text-align: right;
      padding: 0px 40px 40px 40px !important;
      border-top: none !important;
    }
    .ant-modal-close {
      display: none;
    }
  }
}

.ant-modal-wrap, .ant-image-preview-wrap {
  //overflow: hidden !important;
}

.review-room-modal{
  .ant-modal-content {
    .ant-modal-body {
      padding: 40px 40px 40px 40px !important;
    }
    .ant-modal-footer {
      padding: 0 !important;
      padding: 0px 40px 40px 40px !important;
      border-top: none !important;
    }
    .ant-modal-close {
      display: none;
    }
  }
}
.aspect-ratio-modal{
  width: 80% !important;
  &.medium {
    width: 70% !important;
  }
  &.small {
    width: 40% !important;
  }


  .ant-modal-content {
    .ant-modal-body {
      padding: 0 !important;
    }
    .ant-modal-footer {
      padding: 0 !important;
      padding: 0px 40px 40px 40px !important;
      border-top: none !important;
    }
    .ant-modal-close {
      display: none;
    }
  }
}

.scrollable-modal{
  width: 80% !important;
  top: 10px !important;
  &.medium {
    width: 70% !important;
  }
  &.small {
    width: 40% !important;
  }


  .ant-modal-content {
    .ant-modal-body {
      padding: 0 !important;

    }
    .ant-modal-footer {
      display: none !important;
    }
    .ant-modal-close {
      display: none;
    }
  }
}

.aspect-ratio-modal-closable{
  width: 65% !important;
  &.large{
    width: 80% !important;
  }
  .ant-modal-content {
    .ant-modal-body {
      padding: 0 !important;
    }
    .ant-modal-footer {
      padding: 0 !important;
      padding: 0px 40px 40px 40px !important;
      border-top: none !important;
    }
  }
}

.model-time-modal {
  width: 40% !important;
  .ant-modal-content {
    .ant-modal-body {
      padding: 0 !important;
    }
    .ant-modal-footer {
      padding: 0 !important;
      padding: 0px!important;
      border-top: none !important;
      display: none;
    }
  }
}

.modal-space-rejected-reupload {
  width: 75% !important;
  .ant-modal-content {
    .ant-modal-body {
      padding: 0 !important;
    }
    .ant-modal-footer {
      padding: 0 !important;
      padding: 0px!important;
      border-top: none !important;
      display: none;
    }
  }
}

.modal-silo-request {
  .ant-modal-content {
    .ant-modal-body {
      padding-top: 0px;
      padding-right: 0px;
      padding-left: 0px;
    }
    .ant-modal-footer {
      padding: 0 !important;
      padding: 0px 48px 32px 48px !important;
      border-top: none !important;
    }
  }
}

.hover-btn-text {
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  color: #737373;
  justify-content: flex-start;
  padding: 0;
  margin: 10px;
  padding: 0 10px;
}
.cancel-btn-text {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  font-family: Avenir;
  line-height: 100%;
  letter-spacing: 0.04em;
  color: #777777;
  &:hover {
    color:#777777;
  }
}

.help-modal-heading {
  font-family: Avenir;
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 100%;
  text-align: center;
  color: #555555;
}

.help-modal-text {
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #8B90A0;
}

.image-zoom {
  height: calc(100vh - 202px) !important;  
  width: auto !important;
  object-fit: scale-down;
  &.admin {
    width: 100% !important;
    height: calc(100vh -  282px) !important;
  }
}

.zoomed-image {
  width: auto !important;
  height: calc(100vh - 270px) !important;
}

.jNbxeJ {
  border: none !important;
}

.gLxTqq {
  border: none !important;
}

.add-in-space-popover {
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: #333333;
}
.space-pp-txt {
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  align-self: center;
  color: #737373;
  .ant-checkbox {
    display: none !important;
  }
}

.space-pp-btn {
  &.ant-btn-background-ghost {
    background: transparent !important;
    border-color: #fff;
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    text-align: right;
    box-shadow: none !important;
    color: #276DD7 !important;
  }
  &.red {
    &.ant-btn-background-ghost {
      background: transparent !important;
      border-color: #fff;
      font-family: Avenir;
      box-shadow: none !important;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      text-align: right;
      color: #D93025 !important;
    }
  }
}

.space-pp-btn-txt {

}

.small-btn {
  width: unset !important;
  height: unset !important;
  padding: 0 !important;
  background: #d93025 !important;
  border-radius: 4px !important;
  &:hover {
    background:#D93025 !important;
    border: 1px solid #d93025 !important;
    color: #ffffff !important;
  }
  &.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
    background: #D93025 !important;
    opacity: 0.5 !important;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  div {
    &:hover {
      color: #ffffff !important;
    }
  }
}

.small-btn-txt {
  font-family: Avenir;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  padding: 9px 24px;
}

.load-container {
  position: absolute;
  text-align: center;
  left: 25vw;
  right: 25vw;
  top: 20vh;
  z-index: 10;
  background: white;
  border-radius: 4px;
  padding: 20px;
  width: 500px;
  margin: auto;
}

.load-container-qa-page {
  position: absolute;
  text-align: center;
  left: 25vw;
  right: 25vw;
  top: 24vh;
  z-index: 10;
  background: white;
  border-radius: 4px;
  padding: 20px;
  width: 500px;
  margin: auto;
}

.load-container-silo {
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  top: -2vh;
  z-index: 10;
  // background: white;
  // border-radius: 4px;
  // width: 90vw;
  // height: 70vh;
  // &.silo-snapshot-loader {
  //   height : 100% !important;
  //   width:100% !important;
  // }
}

.loader-config {
  box-shadow:  0px 19px 40px 20px rgba(0, 0, 0, 0.04);
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  top: 30%;
  z-index: 10;
  background: white;
  border-radius: 4px;
  padding: 30px;
  width: 500px;
  // height: 100%;
  // width: 100%;
  margin: auto;
}

.model-loader {
  &.small {
    font-size: 40px;
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 20px;
  }
  &.large {
    font-size: 40px;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 28%;
    right: 0;
  }
}

.load-message {
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: #333333;
  &.large {
    font-size: 18px;
    font-weight: 700;
    }
  }

 .success-modal-text-black {
  font-family: Avenir;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 38px;
  text-align: center;
  color: #555555;
  &.normal {
    font-weight: 500;
    font-size: 20px;
    text-align: left;
  }
 }

.upload-box-height {
  // line-height: 120% !important;
  background-color: rgba(244, 244, 244, 0.5) !important;
  border: 1px dashed #d8d7d7 !important;
  border-radius: 4px !important;
  padding-bottom: 40px;
  text-align: center;
  &:hover {
    border: 1px dashed #276DD7 !important;
    }
  }
.camera-placement-align {
  display: flex;
  justify-content: space-between;
}



@media screen and (min-height: 751px) {

  .camera-controls-div {
    position: absolute;
    top: 10;
    left: 10;
  }
  .camera_controls_container-3d {
    height: 420px;
    width: 150px;
    background-color: rgba(0,0,0,0);
  }
  .display-down-arrow {
    display: none;
  }
}

.camera_controls_fov {
  background: #FAFAFA;
  border: 1px solid #E4E4E4;
  width: 222px;
  border-radius: 4px;
  position: fixed;
  top: 50px;
  left: 45px;
  z-index: 1000;
}

.material-photos-btn {
  background-color: rgba(244, 244, 244, 0.5) !important;
  border: 1px solid #E3E3E3;
  border-radius: 4px;
  height: unset !important;
  width: 100% !important;
  padding: 0;
  &.flag {
    background-color: #FFFFFF !important;
    &:hover {
      border: 1px solid #E3E3E3;
    }
  }
  &:hover {
    border: 1px solid #E3E3E3;
  }

}

.material-photos-btn-txt {
  text-overflow: ellipsis;
  width: inherit;
  white-space: nowrap;
  overflow: hidden;
  font-style: normal;
  font-weight: 300;
  font-family: Manrope;
  padding: 14px 15px;
  font-size: 12px;
  line-height: 140%;
  color: #bfbfbf;
  display: flex !important;
  justify-content: space-between;
  align-items: flex-end;
}

.material-upload-box {
  .ant-upload.ant-upload-select {
    display: contents;
  }
  .ant-upload-list {
    position: unset !important;
  }
  .ant-upload-list-item {
    margin-top: 10px !important;
  }
}

.display-camera-type {
  display: block;
  width: 200px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  background-color: rgba(0,0,0,.5);
  border-radius: 10px;
  z-index: 10;
  margin-top: 0;
}

.margin-reset-camera {
  margin-top: 30px;
  z-index: 10;
}

@media screen and (max-height: 729px) {
  .display-reset-btn {
    display: none;
  }
}

@media screen and (max-height: 750px) {
  .display-down-arrow {
    display: block;
    color: white;
    cursor: pointer;
    z-index: 20;
    background: black;
    border-radius: 50px;
    height: 20px;
    width: 20px;
    margin: auto;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .display-camera-type {
    margin-top: 20px;
  }
  .camera-controls-div {
    position: absolute;
    top: 10px;
    left: 10px;
  }
  .camera_controls_container-3d {
    height: 420px;
    width: 150px;
    background-color: rgba(0,0,0,0);
  }
}

.camera_silo_container {
  background: #FAFAFA;
  border: 1px solid #E3E3E3;
  box-sizing: border-box;
  border-radius: 4px;
  width: 170px;
}

.fix-reset-camera {
  width: 200px !important;
}

.reset-camera-btn {
  position: absolute !important;
  bottom: 150px !important;
  left: 38px !important;
  z-index: 3;
  text-transform: uppercase !important;
}

.align-camera-btn {
  position: absolute !important;
  bottom: 180px !important;
  left: 38px !important;
  z-index: 3;
  text-transform: uppercase !important;
}

.medium-input-box{
  width: 100%;
  .ant-input-number-input{
    height: 40px !important;
  }
}

/* width */
.custom-scroll::-webkit-scrollbar {
  width: 6px;
  border-radius: 2px;
}
/* Track */
.custom-scroll::-webkit-scrollbar-track {
  background: transparent
}
/* Handle */
.custom-scroll::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.81);
}
/* Handle on hover */
.custom-scroll::-webkit-scrollbar-thumb:hover {
  background: rgba(85, 85, 85, 0.73);
}

.medium-custom-select{
  min-width: 180px;
  .ant-select-selection, .ant-select-selector {
    border-radius: 4px !important;
    border: solid 1px #e3e3e3 !important;
    background-color: rgba(244, 244, 244, 0.5) !important;
    height: 40px !important;
    font-size: 16px !important;
    font-family: Avenir;
    line-height: 120%;
    vertical-align: middle;
    color: #333333;
    align-items: center;
    display: flex;
  }
}
.flex-cookie-settings {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.select-aspect {
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  padding: 15px !important;
  letter-spacing: 0.02em;
  &.delete {
    color: #737373;
    &:hover{
      background: rgba(217, 48, 37, 0.04);
      color: #D93025;
    }
  }
  &.edit {
    color: #737373;
    &:hover{
      background: #F2F6FD;
      color:#276DD7;
    }
  }
}

.delete-button {
  color: #737373;
  &:hover{
    color: #D93025;
  }
}

.custom-aspects {
  color: rgb(115, 115, 115);
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
  align-items: center;
  &.s-modal {
    margin-top: 6px;
    .ant-input-number {
      &:hover{
        border-color: #40a9ff;
      }
    }
    .ant-input-number:focus, .ant-input-number-focused {
      border-color:#40a9ff;
      border-right-width: 1px !important;
      outline: 0;
      -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }
  }

  .ant-input-number {
    &:hover{
      border-color: #40a9ff;
    }
  }
  .ant-input-number:focus, .ant-input-number-focused {
    border-color:#40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
}

.custom-input-box {
  font-family: Manrope;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 100%;
  color: #737373;
  display: flex;
  align-items: baseline;
  &.f-14 {
    font-size: 14px;
  }
}

.custom-res-message {
  display: inline-grid;
  border-radius: 4px;
  padding: 8px;
}

.res-radio-settings {
  display: inline-flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.apply-btn {
  margin-top: 20px;
   width: unset !important;
   height: unset !important;
   padding: 0 !important;
   background: #276DD7 !important;
   border-radius: 4px !important;
   &:hover {
     background:#276DD7 !important;
     border: 1px solid #276DD7 !important;
     color: #ffffff !important;
   }
   div {
     &:hover {
       color: #ffffff !important;
     }
   }
}

.apply-btn-text {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  padding: 8px 30px;
  line-height: 100%;
  text-align: center;
  font-family: Avenir;
  color: #FFFFFF;
  &:hover {
    color:#ffffff !important;
  }
}

.dpi-heading {
  font-family: Avenir;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 120%;
  margin-top: 18px;
  color: #333333;
}

.dpi-settings {
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 32px !important;

  .ant-radio-button-wrapper {
    .ant-radio-button-wrapper-checked {
      &:hover {
        color:white !important;
      }
    }

    &:hover {
      color: #333333 !important;
    }

  }
}
.dpi-radio {
  margin-top: 2px;
  .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper {
    border-right-color: #333333 !important;
    background: #333333 !important;
    border-color: #333333 !important;
  }
}

.fov-settings {
  margin-bottom: 20px;
}

.aspect-ratio-btn {
  color:#276DD7;
  background: rgba(39, 109, 215, 0.04);
  border: 1px solid #276DD7;
  font-size: 14px;
}

.modal-heading-text {
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: #333333;
}

.request-silo-img {
  // width: 350px;
  // height: 200px;
  width: 100%;
  height: 300px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  // margin-bottom: 28px;
  object-fit: scale-down;
  cursor: default;
  // margin: auto;
  // display: block;
}

.cross-icon-position {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #333333;
  font-size: 16px;
  background-color: transparent;
}

.request-silo-scrollable {
  height: 370px;
  overflow-y: scroll;
  overflow-x: hidden;
  &.flag-height {
    height: 250px;
    @media (max-height: 725px) {
      height: 150px !important;
    }
  }
  &.check-height {
    height: 100%;
  }
  &.default-height {
    position: relative;
    height: 350px;
  }
}

/* width */
.request-silo-scrollable::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.request-silo-scrollable::-webkit-scrollbar-track {
  background: transparent
}

/* Handle */
.request-silo-scrollable::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.81);
}

/* Handle on hover */
.request-silo-scrollable::-webkit-scrollbar-thumb:hover {
  background: rgba(85, 85, 85, 0.73);
}
.pdp-carousel-scroll {
  width: 90%;
}

.pdp-scrollable {
  // width: 360px;

  width: 100%;
  display: flex;
  z-index: 1;
  overflow-x: scroll;
  @media (max-width: 1240px) {
    // width: 360px;
    width: 100%;
  }
  @media (max-width: 1125px) {
    // width: 300px;
    width: 100%;
  }

}

/* width */
.pdp-scrollable::-webkit-scrollbar {
  height: 5px;
}

/* Track */
.pdp-scrollable::-webkit-scrollbar-track {
  //background: #e1e1e1;
  background: transparent;
}

/* Handle */
.pdp-scrollable::-webkit-scrollbar-thumb {
  // background: rgba(136, 136, 136, 0.81);
  background: transparent;
}

/* Handle on hover */
.pdp-scrollable::-webkit-scrollbar-thumb:hover {
  // background: rgba(85, 85, 85, 0.73);
  background:transparent;
}

.pdp-scrollable-admin{
  width: 70%;
  overflow-x: scroll;

}

/* width */
.pdp-scrollable-admin::-webkit-scrollbar {
  height: 5px;
}

/* Track */
.pdp-scrollable-admin::-webkit-scrollbar-track {
  //background: #e1e1e1;
  background: transparent;
}

/* Handle */
.pdp-scrollable-admin::-webkit-scrollbar-thumb {
  // background: rgba(136, 136, 136, 0.81);
  background: transparent;
}

/* Handle on hover */
.pdp-scrollable-admin::-webkit-scrollbar-thumb:hover {
  // background: rgba(85, 85, 85, 0.73);
  background:transparent;
}

.silo-scrollable {
  overflow-x: scroll;
  overflow-y: hidden;
  @media (max-width: 1550px) {
    width: 560px;
  }
  @media (max-width: 1110px) {
    width: 330px;
  }
}

.silo-scrollable::-webkit-scrollbar {
  height: 5px;
}

/* Track */
.silo-scrollable::-webkit-scrollbar-track {
  //background: #e1e1e1;
  background: transparent;
}

/* Handle */
.silo-scrollable::-webkit-scrollbar-thumb {
  // background: rgba(136, 136, 136, 0.81);
  background: transparent;
}

/* Handle on hover */
.silo-scrollable::-webkit-scrollbar-thumb:hover {
  // background: rgba(85, 85, 85, 0.73);
  background:transparent;
}

.left-arrow-container {
  position: absolute;
  bottom: 7px;
  border-radius: 4px;
  background: #333333;
  opacity: 0.4;
  width: 50px;
  background: rgb(0,0,0);
  background: linear-gradient(90deg, rgba(0,0,0,0.12368697478991597) 0%, rgba(101,97,97,1) 35%, rgba(5,4,4,0.5242471988795518) 100%);
  height: 100px;
  @media (max-width: 1550px) {
    left: 529px;
  }
  @media (max-width: 1110px) {
    left: 300px;
  }

}

.right-arrow-container {
  position: absolute;
  width: 50px;
  bottom: 7px;
  border-radius: 4px;
  left: 20px;
  background: #333333;
  opacity: 0.4;
  z-index: 2;
  background: black;
  transform: rotate(180deg);
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.123687) 0%, #656161 35%, rgba(5, 4, 4, 0.524247) 100%);
  height: 100px;
  @media (max-width: 1110px) {
    left: 20px;
  }
}

.silo-request-res {
  width: fit-content;
  position: relative;
  bottom: 41px;
  &.left {
    left: 10px;
  }

  &.right {
    right: 10px;
  }
}

.summary-bottom-text {
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
  border-radius: 4px;
  width: fit-content;
  position: relative;
  bottom: 47px;
  padding: 10px;
  &.left {
    left: 10px;
  }
  &.right {
    right: 10px;
    position: absolute;
    &.light{
      background: rgba(100, 100, 0, 0.9);
    }
  }
}

.price-bottom-text {
  background: rgba(2, 2, 2, 0.08);
  backdrop-filter: blur(10px);
  border-radius: 4px;
  width: fit-content;
  position: relative;
  bottom: 47px;
  padding: 10px;
  &.left {
    left: 10px;
  }
  &.right {
    right: 10px;
    position: absolute;
  }
}

.price-container{
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.silo-request-res-txt {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 23px;
  padding: 10px;
  color: #FFFFFF;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
  border-radius: 4px;
  width: fit-content;
}

.take-silo-shot-modal {
  width: unset !important;
  .ant-modal-body{
    padding: 0px !important;
  }
}

#silo-inspector {
  width: 72vw;
  background: #FFFFFF !important;
  canvas {
    outline: 1px solid #f4f4f4;
    border: 1px solid #e3e3e3;
    border-top: 1px solid #f4f4f4;
    &:focus {
      border: 1px solid #e3e3e3;
      border-top: 1px solid #f4f4f4;
      outline: 1px solid #f4f4f4;
    }
    &::selection {
      color:#f4f4f4 !important;
      background: #f4f4f4 !important;
    }
  }
}

.silo-tool-container-modal{
  display: flex;
  width: fit-content;
}

#sliderFocal{
  .ant-slider-dot-active {
    border-color: #276dd7 !important;
  }
  .ant-slider-dot {
    border-color: #276dd7 !important;
  }
}

#camera_controls_slider{
  .ant-slider-dot {
    border-color: #276dd7 !important;
  }
  .ant-slider-dot-active {
    border-color: #276dd7 !important;
  }
}

#camera_silo_slider {
  height: 200px;
  .ant-slider-vertical .ant-slider-track {
     background-color: #c4c4c4 !important;
  }
  .ant-slider-handle:focus {
    border-color: none;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .ant-slider-dot-active {
    border-color: #c4c4c4 !important;
  }
  .ant-slider-rail{
    background-color: #e3e3e3;
  }
}

.ant-popover {
  z-index: 1050 !important;
}

.discard-snapshot {
  width: unset !important;
  height: unset !important;
  padding: 0 !important;
  background: rgba(217, 48, 37, 0.04) !important;
  border: none !important;
  border-radius: 35px !important;
  &.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
    background:rgba(217, 48, 37, 0.04) !important;
    opacity: 0.5;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  &:hover {
    background: rgba(217, 48, 37, 0.04) !important;
    border: none !important;
    color: #d93025 !important;
  }

  &.square {
    border-radius: 4px !important;
    font-size: 14px !important;
    line-height: 120%;
    padding: 12px 24px !important;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    vertical-align: middle;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    &:hover {
     background:rgba(217, 48, 37, 0.04) !important;
     border: none !important;
     color:  #d93025 !important;
   }
   &.red {
    color: #D93025;;
  }
  &.font-14 {
    font-size: 14px !important;
    line-height: 120%;
    padding: 12px 24px !important;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    vertical-align: middle;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
  }
  }
  div {
    &:hover {
      color: #d93025 !important;
    }
  }
}

.perspective-bg {
  background: white;
  border-radius: 4px;
  padding: 8px;
  margin-right: 10px;
  cursor: pointer;

  display:flex;
  flex-direction: column;
  justify-content: center;
  width: 100px;
  height: 100px;
}

.perspective-img-dimensions {
  border-radius: 4px;
  margin: 0 auto;
  text-align: center;
  height: 64px;
  width: 64px;
  object-fit: contain;
  padding-bottom: 4px;
}

.persp-img-txt {
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  line-height: 100%;
  text-transform: capitalize;
  color: #737373;
}

.help-silo-heading {
  font-family: Avenir;
  font-style: normal;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0px;
  color: #555555;
  font-weight: 900;;
}

.help-silo-body {
  font-family: Avenir;
  font-style: normal;
  font-size: 16px;
  line-height: 25px;
  text-transform: capitalize;
  font-weight: 500;
  color: #737373;
}

.help-btn-txt{
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  padding: 16px 24px;
  line-height: 100%;
  text-align: center;
  font-family: Avenir;
  color: #FFFFFF;
  &:hover {
    color:#ffffff !important;
  }
}

.left-button-scr {
  cursor: pointer;
  font-size: 20px;
  position: absolute;
  color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  &.black{
    color:black !important;
  }
}

#left-button-scr {
  display: none;
  // visibility: hidden;
}
#right-button-scr {
 display: none;
//  visibility: hidden;
}

#left-scroll-btn {
  display: none;
}
#right-scroll-btn {
  display: none;
}

#left-scr-button-quick {
  display: none;
}
#right-scr-button-quick {
  display: none;
}
.pdp-carousel-scroll {
  position: absolute;
  display: flex;
  bottom: 0px;
  left: 0px;
  background: rgba(0,0,0,0.02);
  width: 60%;
  &.width-56{
    padding: 10px 2px 10px 2px !important;
    width: 56% !important;
  }
  &.width-45{
    padding: 18px !important;
    width: 45% !important;
  }
}

.pdp-carousel-scroll-admin-side {
  position: absolute;
  display: flex;
  bottom: 0px;
  left: 0px;
  background: rgba(0,0,0,0.02);
  width: 90%;
  &.width-56{
    padding: 10px 2px 32px 2px !important;
    width: 56% !important;
    @media (max-width: 1440px) {
      padding: 10px 4px 48px 4px !important;
    }
  }
  &.width-45{
    padding: 18px !important;
    width: 45% !important;
  }
}

.pdp-carousel-scroll-admin {
  position: absolute;
  display: flex;
  bottom: 20px;
  left: 20px;
}
.right-button-scr {
  cursor: pointer;
  font-size: 20px;
  color: white;
  transform: translate(100%, 100%);
  -ms-transform: translate(100%, 100%);
  text-align: center;
  &.black{
    color: black !important;
  }
}

.right-button-scr-product {
  cursor: pointer;
  font-size: 20px;
  position: absolute;
  color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.icon-con {
  // position: absolute;
  width: 64px;
  height: 64px;
  bottom: 26px;
  z-index: 1;
  //right: 135px;
  //right: 15%;
  left: 530px;
  //right: 62%;
  border-radius: 4px;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.123687) 0%, #656161 35%, rgba(5, 4, 4, 0.524247) 100%);
  opacity: 0.4;
  @media (max-width: 1125px) {
   // right: 38%;
   left: 300px;
  }
  @media (max-width: 1240px) {
    // right: 38%;
    left: 400px;
   }
}

.icon-con-product {
  position: absolute;
  background-image: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
  width: 48px;
  height: 64px;
  bottom: 14px;
  z-index: 1;
  left: 94%;
  border-radius: 4px;
  opacity: 1;
  @media (max-width: 1125px) {
   left: 88%;
  }
}

.icon-con-product-admin {
  position: absolute;
  background-image: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
  width: 48px;
  height: 64px;
  bottom: 21px;
  z-index: 1;
  left: 94%;
  border-radius: 4px;
  opacity: 1;
  @media (max-width: 1125px) {
   left: 88%;
  }
}

.image-details{
  position: absolute;
  left:18px;
  top: 80px;
}

.job-desk-layout{
  margin: 20px;
}

.artist-product-data{
  margin: 20px;
  & .ant-tabs-top > .ant-tabs-nav{
    margin: 0px;
  }
}

.job-desk-description{
  display: flex;
  padding: 8px;
  background-color: rgba(0,0,0,0.04);
  border-radius: 4px;
  margin-top: 20px;
}

.step-diagram-wrapper{
  position: absolute;
  right:60px
}

.step-diagram{
  background: rgba(39, 109, 215, 0.04);
  border-radius: 4px;
  padding:8px;
  & span{
    color:#276DD7
  }

}

.product-artist-description{
  display: inline;
  padding: 8px;
  background-color: rgba(0,0,0,0.04);
  border-radius: 4px;
}

.items-table{
  &.ant-tabs-ink-bar {
    background: white !important;
  }

  &.ant-table-thead > tr > th{
    background-color: #FFFFFF !important;
    border-right: 0px !important;
    border-left: 0px !important;
  }
  &.ant-table-tbody > tr > td{
    background-color: #FFFFFF !important;
    border-right: 0px !important;
    border-left: 0px !important;
  }
  &.ant-table.ant-table-bordered > .ant-table-container, .ant-table-wrapper{
    border-left: 0px !important;
    border-top: 1px solid #f0f0f0 !important;
  }
}

.items-table .ant-tabs-tab-active,.items-table .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #276DD7 !important;
  justify-content: center;
  background-color: white !important;
}

.icon-con-product-left{
  position: absolute;
  background-image: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
  width: 48px;
  height: 64px;
  bottom: 14px;
  z-index: 1;
  border-radius: 4px;
  left: -4px;
  transform: rotate(180deg);
  opacity: 1;
  z-index: 2;
}

.icon-con-product-left-admin{
  position: absolute;
  background-image: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
  width: 48px;
  height: 64px;
  bottom: 21px;
  z-index: 1;
  border-radius: 4px;
  left: -4px;
  transform: rotate(180deg);
  opacity: 1;
  z-index: 2;
}

.icon-con-left {
  // position: absolute;
  width: 64px;
  height: 64px;
  bottom: 20px;
  z-index: 1;
  border-radius: 4px;
  left: 0px;
  transform: rotate(180deg);
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.123687) 0%, #656161 35%, rgba(5, 4, 4, 0.524247) 100%);
  opacity: 0.4;
  z-index: 2;
}

.outside-view-name {
  font-size: 15px;
}

.silo-content{
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.confiugrator-container {
  height: calc(100vh - 500px);
  background:#f4f4f4;
}

.small-configurator {
  // height: calc(100vh - 200px);
  height: calc(100vh - 318px);
}

.silo-snapshot-container {
  display: block;
  background-color: white;
  margin: 20px 0;
  margin-right: 20px;
  border: 1px solid #E3E3E3;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.silo-footer {
  border: 1px solid #e3e3e3;
  background: #FFFFFF;
  box-shadow: 0px -8px 25px rgba(0, 0, 0, 0.04);
  height: 60px;
  position: relative;
  z-index: 2;
}

.silo-controls {
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    color: #1890ff;
    background: #fff;
    border-color: #1890ff;
  }
}
.snapshot-controls {
  display:flex;
  justify-content: space-between;
  align-items: center;
}

.delete-silo{
  position: absolute;
  top: 4%;
  right: 4%;
  color : #CF1322;
  font-size: 18px;
}

.plus-outline-silo-button{
  margin-right: 5px;
}

.modal-okay-silo {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 80%;
  text-align: center;
  font-family: Manrope;
  padding: 9px 32px !important;
}

.lighting-div{
  .ant-divider-horizontal{
    margin: 2px 0px!important;
  }
}
.silo-background-bordered{
  border: 2px solid black;
}
.silo-background-not-bordered{
  border: 0.5px solid #D9D9D9;
}

.silo-settings-container {
  border-radius: 4px;
  height: unset !important;
  &.lighting{
    height: unset !important;
  }
}

.default-silos-snapshots {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0% 0.5%;
  height: 90%;
  width: 99%;
  margin-top: 1%;
  margin-left: 1%;
  overflow-y: scroll;
}

.card-silo-default{
  width: 23%;
  margin: 0.45% 1%;
  img {
  height: 23vh; 
  }
  .ant-card-meta-detail {
    padding: 8px;
  }
  .ant-card-body {
    div {
      border-radius: 4px 4px 0px 0px;    
    }
  }
}

.card-silo-preview-no-border{
  width : 18%;
  height: 46%;
  margin-right: 0.8%;
  margin-top: 0.8%;
  box-shadow: 0px 2px 0px 0px #0000000B;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
  cursor: pointer;
  .ant-card-body{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.color-temp-slider{
  *{
    background: transparent !important;
    background-color: transparent !important; 
    :hover{
      background: transparent !important;
      background-color: transparent !important;
    }
  }
  .ant-slider-handle{
    margin-top: -7px; 
    background-color: #000000 !important;
    border :solid 2px #000000 !important;
  }
  .ant-slider-dot {
    margin-top: -2px;   
    border: solid 1px #2e68c0 !important;
    // background: #e89697 !important;
  }
  
}

.card-silo-preview{
  width : 18%;
  height: 46%;
  margin-right: 0.8%;
  margin-top: 0.8%;
  box-shadow: 0px 2px 0px 0px #0000000B;
  border: 1px solid #D9D9D9;
  border-radius: 4px; 
  img {
    width: 83%; 
    height: 83%;
    object-fit: contain;
    position: absolute !important;
    top: 0% !important;
  }
  .ant-card-body{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  &:hover{
    box-shadow: 3px 3px 3px 3px #00000005;
  }
  cursor: pointer;
}

.card-silo-preview-bordered{
  width : 18%;
  height: 46%;
  margin-right: 0.8%;
  margin-top: 0.8%;
  box-shadow: 0px 2px 0px 0px #0000000B;
  border: 2px solid black;
  border-radius: 4px;
  img {
    width: 83%; 
    height: 83%;
    object-fit: contain;
    position: absolute;
    top: 0%;
  }
  .ant-card-body{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  cursor: pointer;
}

.card-silo-edit {
  width: 23%;
  height: fit-content;
  margin: 1% 0.5% 0% 0.5%;
  img {
    height: 26vh;
    width: 26vh;
    object-fit: contain;
  }
  .ant-card-meta-detail {
    padding: 8px;
  }
  .ant-card-body {
    border-radius: 4px ;
  }
}

.silo-heading-main {
  background:#F0F0F0; 
  padding:8px;
  width:fit-content;
  border-radius:4px;
  display: block;
}

.silo-name-edit{
  position: absolute;
  left: 2%;
  top: 1%;
  color: #1F1F1F;
}

.snapshot-position-controls {
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 10px 20px 0 20px;
  border-top: 1px solid #e3e3e3;
  background: #f4f4f4;
}

.container-360-spin {
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
}

.flag-if-btn {
  width: unset !important;
  height: unset !important;
  padding: 0 !important;
  background: #FFFFFF !important;
  border: 1px solid #737373 !important;
  border-radius: 4px !important;

  &.h-44{
    height: 44px !important;
  }

  &.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
    background: #FFFFFF !important;
    opacity: 0.5 !important;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  &:hover {
    background:#FFFFFF !important;
    border: 1px solid #737373 !important;
    color: #737373 !important;
  }
  div {
    &:hover {
      color: #737373 !important;
    }
  }
}

.flag-if-selected-btn {
  width: unset !important;
  height: unset !important;
  padding: 0 !important;
  background: #D93025 !important;
  border: 1px solid #D93025 !important;
  border-radius: 4px !important;
  &.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
    background: #D93025 !important;
    opacity: 0.5 !important;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  &:hover {
    background: #D93025 !important;
    border: 1px solid #D93025 !important;
    color: #ffffff !important;
  }
  div {
    &:hover {
      color: #ffffff !important;
    }
  }
}

.flag-img {
  height: 25px;
  width: 25px;
  object-fit: contain;
}

.flag-img-small {
  height: 18px;
  width: 18px;
  object-fit: contain;
}
.note-flag {
  background: rgba(39, 109, 215, 0.04);
  border: 1px dashed #E4E4E4;
  box-sizing: border-box;
  border-radius: 4px;
}

.flag-form {
  display: flex;
  justify-content: space-between;
}

.flag-text {
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  text-transform: capitalize;
  color: #333333;
  &.grayish-black {
    color: #555555;
    text-transform: none;
  }
}

.flag-panorama-thumbnail-styles {
  position: relative;
  width: 100%;
  height: 160px;
  border: 1px solid #f0f0f0;
  cursor: pointer;
  border-radius: 4px;
  z-index: 1;

  @media (max-height: 725px) {
    height: 120px;
  }

  &.single {
    width: 100% !important;
    height: 400px !important;

    @media (max-height: 725px) {
      height: 150px !important;
    }
  }
}

.custom-flag-style {
  width: 100%;
  margin: 0;
}

.flag-setting {
  .ant-upload.ant-upload-select {
    width: 100%;
    margin: 0;
  }
}

.flag-override-panorama {
  position: relative;
  width: 100%;
  height: 155px;
  border: 1px solid #f0f0f0;
  cursor: pointer;
  border-radius: 4px;
  z-index: 1;
  &.max-3ds {
    height: 120px;
    canvas {
      left: 0;
    }
  }

  &.max-3ds-single {
    height: 235px;
  }

  @media (max-height: 725px) {
    height: 155px;
  }
  canvas {
    left: 0;
  }

  // margin-bottom: 8px;
}

.flag-card-pd {
  padding: 8px 8px 0px 8px !important;
  border-radius: 4px;
}

.flag-col-margin {
  padding: 8px 0;
  &.single {
    padding: 0;
  }
}

.flag-image-thumbnail {
  width: 210px;
  border-radius: 4px;
  height: 187px;
  object-fit: scale-down;
  border: 1px solid #f0f0f0;
  @media (max-height: 725px) {
    height: 110px !important;
  }
  &.margin {
    padding: 10px;
  }
}

.flag-image-thumbnail-single {
  width: 100%;
  border-radius: 4px;
  height: 300px;
  object-fit: cover;
  &.product {
    object-fit: scale-down;
    border: 1px solid #f0f0f0;
    margin-top: 0;
  }
  @media (max-height: 725px) {
    height: 150px !important;
  }
}

.flag-checkbox-settings {
  top: 15px;
  left: 15px;
  position: absolute;
  cursor: pointer;
  z-index: 999;
  width: 18px;
  height: 18px;
}

.lifestyle-image-settings {
  cursor: default;
  width: 100%;
  object-fit: contain;

  &.preview {
    filter: blur(8px);
    cursor: default;
  }
}

.new-lifestyle-image-settings {
  cursor: default;
  width: 100%;
  object-fit: contain;

  ////////////////
  //max-width: 100%; /* Ensures images do not exceed their container width */
  max-height: 300px; /* Maximum height of any image */
  min-height: 300px; /* Minimum height of any image */
  height: auto; /* Ensures aspect ratio is maintained */
  display: block; /* Prevents whitespace issues */

  &.preview {
    filter: blur(8px);
    cursor: default;
  }

  &.hide-display {
    display: none !important;
  }
}

.img-more-info{
  background: rgba(255, 255, 255, 1);
  border-radius: 2px;
  padding: 3px 4px 3px 4px;
  cursor: pointer;
  // position: absolute;
  top: 12px;
  z-index: 1;
  right: 12px;
  border-radius: 4px;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
}

.lifestyle-image-card {
  text-align: center;
  border-radius: 4px;
}

.new-image-grid-card {
  text-align: center;
  border-radius: 4px;
  /////////////////////
  margin: 8px; /* Space around each item */
  box-sizing: border-box; /* Include padding and border in item's total width */
  position: relative; /* Positioning context for absolute buttons */
  overflow: hidden; /* Ensures buttons don't overflow item */
  max-height: 300px; /* Limit item height */
  object-fit: cover;
}

.flag-image {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 15px;
  right: 10px;
  border-radius: 4px;
  z-index: 1;
  cursor: pointer;
  &.fs {
    top: 60px;
    right: 20px;
    z-index: 999;
  }
  &.single {
    top: 54px;
    right: 12px;
  }
}

.flag-image-silo {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  z-index: 1;
  cursor: pointer;
}

#vs-container-flag{
  cursor: grabbing;
  canvas {
    width: 100% !important;
    height: 400px !important;

    @media (max-height: 725px) {
      height: 150px !important;
    }
  }
}
#vs-container-admin{
  canvas {
    width: 100% !important;
    height: 520px !important;
  }
}
#vs-container-admin-fullscreen {
  canvas {
    width: 100% !important;
    height: 100vh !important;
  }
}

.upload-bundle-btn {
  background: white !important;
  border-radius: 35px !important;
  border: 1px solid #333333 !important;
  height: unset !important;
  width: unset !important;
  padding: 0 !important;
  &:hover {
    background: white !important;
    border: 1px solid #333333 !important;
  }
}

.upload-bundle-btn-text {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  padding: 11px 32px 11px 32px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 1px;
  font-family: Avenir;
  color:#333333 !important;
  &:hover{
    color: #333333 !important;
  }
}

.flagged-txt {
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  margin-bottom: 1px;
  text-transform: capitalize;
  color: #FF4A4D;
  &.bold {
    font-weight: 900;
  }
  &.gray {
    color: #999999;
    &.small {
      font-size: 14px;
    }
  }
  &.black {
    color: #333333;
    &.small {
      font-size: 14px;
    }
  }
  &.upload {
    color: #276DD7;
    text-transform: none;
    &.small {
      font-size: 14px;
    }
  }
  &.small {
    font-size: 14px;
  }
}

.flagged-bg {
  padding: 10px 16px;
  background: #FEF6F6;
  border-radius: 4px;

  &.border {
    border: 1px dashed #FF4A4D;
    box-sizing: border-box;
    &.custom {
      padding: 0px 16px;
    }
    &.pd-16 {
      padding: 16px;
    }
  }
}

.flagged-bg a {
  &:hover {
    text-decoration: underline;
  }
}

.flagged-bg-blue {
  padding: 10px 16px;
  background: white;
  border-radius: 4px;
  &.border {
    border: 1px dashed #276DD7;
    box-sizing: border-box;
  }
}

.flag-card-settings {
  font-family: Manrope;
  cursor: pointer;
  color: white;
  height: 40px;
  width: 40px;
  font-size: 12px;
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.flag-complaint-heading {
  font-family: Avenir;
  text-transform: uppercase;
  font-size: 16px;
  color: #737373;
  line-height: 120%;
  vertical-align: middle;
  margin-top: 48px;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.upload-a-settings {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &:hover {
    text-decoration: underline;
  }
}

.upload-image-settings-reject {
  width: 200px;
  height: 200px;
  border-radius: 4px;
  margin-right: 20px;
  object-fit: scale-down;
}

.upload-image-settings {
  width: 25px;
  height: 25px;
  border-radius: 4px;
  margin-right: 20px;
  object-fit: scale-down;
}

.flag-scene-card-bg {
  background: #141414;
  border-radius: 4px;
  width: max-content;
  padding: 8px 16px;
}

.flag-scene-card-txt {
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: 0.02em;
  color: #FFFFFF;

  &.grey {
    color: #777777;
  }
  &.blue {
    font-size: 18px;
    color: #276DD7;
    &.font-12 {
      font-size: 12px;
    }
  }
  &.margin {
    padding: 10px;
  }
}

.position-scene-name {
  z-index: 10;
  top: 41%;
  left: 35%;
  position: absolute;
  &.single {
    top: 46.5%;
    left: 45.5%;
  }
}

.scene-card-bg {
  margin: 10px;
  width: 64px;
  height: 64px;
  padding: 10px;
  position: relative;
  bottom: 80px;
  left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #333333;
  &.card-render {
    bottom: 58px;
  }
  &.no-settings {
    position: unset;
    left: unset;
    bottom: unset;
    display: inline-block;
    margin-left: 0;
    margin-bottom: 0px;
    margin-top: 0;
  }
  &.scene-thumbnail {
    z-index: 2;
    padding: unset;
    bottom: 125px;
    margin-left: 0;
    left: 20px;
    width: 100%;
    justify-content: flex-start;
    margin-bottom: 0;
  }
  &.flag {
    width: 100px;
    height: 100px;
    bottom: 132px;
    cursor: pointer;
    &.bt-0 {
      bottom: 0;
    }
  }
}

.scroll-upload {
  // .ant-upload-list {
  //   height: 52px;
  //   overflow-y: scroll;
  // }
}

/* width */
.scroll-upload .ant-upload-list::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
}


/* Track */
.scroll-upload .ant-upload-list::-webkit-scrollbar-track {
    background: transparent !important;
}

/* Handle */
.scroll-upload .ant-upload-list::-webkit-scrollbar-thumb {
    background: rgba(136, 136, 136, 0.81) !important;
}

/* Handle on hover */
.scroll-upload .ant-upload-list::-webkit-scrollbar-thumb:hover {
    background: rgba(136, 136, 136, 0.81) !important;
}

.tools {
  margin-top: 20px;
  position: absolute;
  right: 20px;
  top: 50px;
  z-index: 1;
  margin-right: 0 !important;
  &.compare {

  }
  &.material-tool {
    margin-top: 0;
    margin-right: 3% !important;
    top: 0px;
  }
  &.material-modal {
    margin-top: 0;
    left: 20px;
    top: 20px;
    right: unset;
  }
}

.admin-tool-btn {
  width: unset;
  height: unset;
}

.admin-tool-icon {
  padding: 8px 10px 6px;
  width: unset;
  height: unset;
}

.comparison-btn {
  position: absolute;
  z-index: 1;
  top: 52px;
  right: 100px;
  &.admin-compare {
    top: 21px;
    right: 234px;
    &.comp-view {
      right: 0px;
    }
  }
  &.customer-compare {
    top: 20px;
    right: 20px;
  }
}

.fullscreen-360 {
  position:absolute;
  top:138px;
  z-index: 999;
  cursor: pointer;
  opacity: 0.8;
  border-radius: 4px;
  right: 20px;
  &.dim {
    height: 40px;
    width: 40px;
  }
  &.exit {
    top: 20px;
    &.dim {
      height: 40px;
      width: 40px;
    }
  }
  &.exit-pos {
    top: 20px;
    position: fixed;
  }
}

.snapshot-modal-img {
  height: 450px;
  width: 100%;
  object-fit: contain;
  border: 8px solid #FAFAFA;
  background: #FAFAFA;
  &.no-border {
    // background: transparent;
    // border: none;
    height: 330px;
  }
}

.img-container{
  position: absolute;
  right: 0;
  height: 100%;
  align-items: center;
}

.snapshot-overlay-img{
  display: flex;
  flex-direction: row;
  max-width: 100%;
  max-height: 100%;
}

.snapshot-overlay-img-silo{
  position: absolute;
  top: 25%;
  left:40%;
  height: 45%;
  width: 30%;
}

#overlay-temp-top-silo {
  position: absolute;
  top: 25%;
  left:40%;
  // width: 45%;
  height:  3px;
  background: white;
  box-shadow: white;
  opacity: 0.3;
}

#overlay-temp-bottom-silo  {
  position: absolute;
  bottom: 30%;
  left:40%;
  // width: 45%;
  height:3px;
  background: white;
  box-shadow: white;
  opacity: 0.3;
}
#overlay-temp-left-silo {
  position: absolute;
  top: calc(25% + 25px);
  left:40%;
  width: 3px;
  height:calc(45% - 50px);
  background: white;
  box-shadow: white;
  opacity: 0.3;
}

#overlay-temp-right-silo {
  position: absolute;
  top: calc(25% + 25px);
  right:15%;
  width: 3px;
  height:calc(45% - 50px);
  background: white;
  box-shadow: white;
  opacity: 0.3;
}



.overlay-temp-blur{
  display:none
}

#overlay-temp-top {
  position: absolute;
  top: 0%;
  left:0%;
  // with: 45%;
  height:  3px;
  background: white;
  box-shadow: white;
  opacity: 0.3;
}

#overlay-temp-bottom {
  position: absolute;
  // bottom: 30%;
  left:0%;
  // width: 45%;
  height:4px;
  background: white;
  box-shadow: white;
  opacity: 0.3;
}

#overlay-temp-left {
  position: absolute;
  top:0;
  left: 0%;
  width: 3px;
  height:calc(45% - 50px);
  background: white;
  box-shadow: white;
  opacity: 0.3;
}

#overlay-temp-right {
  position: absolute;
  top: 0;
  right:0%;
  width: 3px;
  height:calc(45% - 50px);
  background: white;
  box-shadow: white;
  opacity: 0.3;
}

.snapshot-modal-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
  color: #333333;
  margin: 0;
  font-family: Manrope;
  margin-bottom: 20px;
}

.custom-input-contain {
  margin-left: 0px;
  .ant-input-number {
    &:hover{
      border-color: #40a9ff;
    }
  }
  .ant-input-number:focus, .ant-input-number-focused {
    border-color:#40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
}

.custom-input-contain-gray {
  margin-left: 10px;
  .ant-input-number {
    &:hover{
      border-color: #c4c4c4;
    }
  }
  .ant-input-number:focus, .ant-input-number-focused {
    border-color:#c4c4c4;
    border-right-width: 1px !important;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px lightgray !important;
    box-shadow: 0 0 0 2px lightgray !important;
  }
}

.ss-images {
  max-width: 100%;
  width: 100%;
  height: calc(100vw * 0.105);
  object-fit: contain;
  border-radius: 4px;
  background-color: #FAFAFC;
}

.artist-product-heading {
  margin-bottom: 0;
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  vertical-align: middle;
  font-size: 32px;
  text-transform: capitalize;
  line-height: 140%;
  color: #333333;
}

.note-text {
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size:  18px;
  line-height: 20px;
  vertical-align: middle;
  text-transform: capitalize;
  color: #262626;

  &.gray {
    // background-color: #F2F6FD;
    color: #555555;
    &.urls {
      text-transform: lowercase;
    }
  }
  &.black {
    color: #333333;
  }
  &.red{
    color: #FF4A4D;
    &.urls {
      text-transform: lowercase;
    }
  }
  &.blue {
    color: #276DD7;
    &.small {
      text-transform: unset;
      font-size: 14px;
    }
  }
  &.white {
    color: white;
    font-size: 12px;
  }
  &.green {
    color: #29B862;
    padding: 5px;
    font-size: 16px;
    text-transform: unset;
  }
  &.error-red {
    color: #E54547;
    font-size: 16px;
    padding: 5px;
    text-transform: unset;
  }
}

.note-bg-artist {
  padding: 10px;
  background: #F2F6FD;
  border: 1px solid #F2F6FD;
  box-sizing: border-box;
  border-radius: 4px;
  &.res {
    padding: 13.5px 27.5px;
    width: max-content;
    display: flex;
  }
  &.pd-8 {
    padding: 8px;
  }
  &.pd-16 {
    padding: 16px;
  }

  &.pd-20 {
    padding: 16px;
  }
  &.gray {
    background: #F2F6FD;
    border: 1px solid #FAFAFC;
    &.urls {
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      max-width: 400px;
      text-overflow: ellipsis;
    }
    &.width {
      width: fit-content;
      margin-bottom: 10px;
    }
  }
  &.red {
    background: #FEF6F6;
    border: 1px solid #FEF6F6;
  }
  &.dark-blue {
    background: #276DD7;
    width: max-content;
    border: 1px solid #276DD7;
    z-index: 2;
  }
  &.deep-red {
    background: #E54547;
    width: 40px;
    height: 40px;
    border: 1px solid #E54547;
    padding: 5px;
  }
  &.green {
    background: #29B862;
    width: 40px;
    height: 40px;
    border: 1px solid #29B862;
    padding: 5px;
    z-index: 2;
  }
}

.item-history>span:hover{
  text-decoration: underline;
}

.rejection-history-view>span:hover{
  text-decoration: underline;
}

.category-artist {
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #777777;

  vertical-align: middle;
  &.medium {
    font-size: 16px;
    &.align-left {
      text-align: left;
    }
  }
  &.gray {
    background-color: white;
    color: #555555;
  }
  &.red {
    color: #ff4d4f
  }
  &.blue {
    color: #276DD7;
  }
  &.white {
    color: white;
  }
  &.green {
    color: #25A759
  }
  &.red {
    color: #D40003;
  }
  &.yellow {
    color: #a2a229;
  }
}

.resolution-btn {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  padding: 8px;
  &.small-card-pos {
    position: absolute;
    top: 16px;
    right: 10px;
    padding: 6px;
  }
}

.img-auto-white-switch{
  position: absolute;
  top: 17px;
  right: 8px;
  z-index: 1;
  display: flex;
  .ant-switch-checked {
    background-color: rgba(0, 0, 0, 0.8) !important;
    min-width: 55px;
    height: 26px;
    .ant-switch-handle{
      top: 4px;
    }
    .ant-switch-handle::before{
      background-color: rgb(255, 255, 255) !important;
    }
  }
  .ant-switch {
    .ant-switch-handle::before{
      background-color: rgb(0, 0, 0) ;
    }
    .ant-switch-handle{
      top: 4px;
    }
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 1px 1px rgba(0, 0, 0, 0.2);
    min-width: 55px;
    height: 26px;
  }
}

.position-flag-dim {
  position: absolute;
  top: 14px;
  left: 8px;
  z-index: 1;
  display: flex;
}

.img-format-tag {
  position: absolute;
  top: 14px;
  left: 110px;
  z-index: 1;
  display: flex;
}

.flag-tag {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 2px;
  padding: 8px;
  margin-left: 24px;
  &.three-sixty{
    margin: 0;
    position: absolute;
    left: 20px;
    top: 20px;

  }
}

.artist-upload-txt {
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  vertical-align: middle;
  text-transform: capitalize;
  text-align: center;
  letter-spacing: 0.02em;
  color: #777777;
  &.large {
    font-size: 18px;
    .ant-form-item-label  {
      color: black;
      font-size: 18px !important;
      label{
        font-size: 18px !important;
      }
    }
  }
  &.small {
    font-size: 14px;
    &.align-text {
      text-align: left;
      margin-bottom: 16px;
    }
    &.white {
      color: white;
    }
  }
  &.scene-name {
    padding: 8px;
    display: flex;
    justify-content: center;
  }
  &.align-text {
    text-align: left;
    display: block;
    .ant-legacy-form-item-label {
      line-height: 120% !important;
      font-family: Manrope !important;
      font-style: normal !important;
      font-weight: 500 !important;

      .ant-legacy-form-item-no-colon {
        font-size: 14px !important;
        color: #555555 !important;
      }
    }
    &.upload-text {
      font-size: 14px !important;
      margin-top: 24px;
      color: #333333 !important;
      margin-bottom: 12px;
      line-height: 120% !important;
      font-family: Manrope !important;
      font-style: normal !important;
      font-weight: 600 !important;
    }
  }

  &.large {

    text-align: left;
    display: block;
    .ant-legacy-form-item-label {
      line-height: 120% !important;
      font-family: Manrope !important;
      font-style: normal !important;
      font-weight: 500 !important;

      .ant-legacy-form-item-no-colon {
        font-size: 14px !important;
        color: #555555 !important;
      }
    }
  }
}

.artist-form-button {
  text-align: right;
  margin-bottom: 30px !important;

}

.artist-form {
  .ant-legacy-form-item {
    margin-bottom: 30px !important;
  }
}

.upload-dragger-artist {
  height: auto !important;
  &:hover {
    border-color: #40a9ff !important;
  }
}

.dragger-upload-div {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  min-height: 160px;
  height: auto;
  background-color: #fafafa !important;
  border: 1px dashed #d8d7d7 !important;
  border-radius: 4px !important;

  &:hover {
    border: 1px dashed #276DD7 !important;
  }
  .ant-upload.ant-upload-drag {
    border: none !important;
    border-radius: 0 !important;
  }


  span {

    .ant-upload-list {
        position: unset !important;
    }
  }

  &.disable-form-property {
    &.no-border {
      background-color:transparent !important;
      border: none !important;
      &:hover {
        border: none !important;
      }
    }

    .ant-upload-list {
      position: unset !important;;
    }
  }
  .ant-upload-list {
    .ant-upload-list-text-container {
      .ant-upload-list-item {
        .ant-upload-list-item-progress {
          .ant-progress-line {
            .ant-progress-outer{
              .ant-progress-inner {
                .ant-progress-bg{
                  height: 4px !important;
                }
              }

            }
          }
        }
      }
    }
  }

}

.re-upload-btn {
  .ant-upload-select {
    padding: 0 !important;
    height: unset !important;
    border-radius: 0px !important;
    border: none !important;
    background-color: transparent !important;
  }

  .ant-upload-list {
    position: absolute;
    background: transparent;
    bottom: -30px;
    right: unset;
  }
}

.collapse-artist {
  .ant-collapse-item {
    .ant-collapse-header {
      background: #FAFAFC;
      border: 1px solid #FAFAFC;
      border-radius: 4px;
    }
    margin-bottom: 16px;
  }

}

.collapse-panel {
  &.bg-fc {
    .ant-collapse-item {
      .ant-collapse-header {
        background: #FAFAFC;
        border: 1px solid #FAFAFC;
        border-radius: 4px;
      }
      .ant-collapse-content > .ant-collapse-content-box {
        padding: 0 !important;
      }
      margin-bottom: 0px;
    }
  }

  &.bg-grey {
    .ant-collapse-item {
      .ant-collapse-header {
        background: #FAFAfC;
        border: 1px solid #FAFAFC;        ;
        border-radius: 4px;
      }
      .ant-collapse-content > .ant-collapse-content-box {
        padding: 0 !important;
      }
      margin-bottom: 0px;
    }
    .ant-collapse-header {
      padding: 0;
      .ant-collapse-arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        top: 0;
      bottom: 0;
      }
    }
  }

  &.bg-df {
    .ant-collapse-item {
      .ant-collapse-header {
        background: #dfdfdf;
        border: 1px solid #dfdfdf;
        border-radius: 4px;
      }
      .ant-collapse-content > .ant-collapse-content-box {
        padding: 0 !important;
      }
      margin-bottom: 0px;
    }
  }

  &.bg-f7 {
    .ant-collapse-item {
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      .ant-collapse-header {
        background: #F7F7F8;
        border: unset;
        // border-radius: 4px;
      }
      .ant-collapse-content > .ant-collapse-content-box {
        padding: 0 !important;
      }
      margin-bottom: 0px;
    }
  }

}

.collapse-validation {
  .ant-collapse-item {
    .ant-collapse-header {
      background: #F4F4F4;
      border: 1px solid #F4F4F4;
      border-radius: 4px;
    }
    .ant-collapse-content{
      .ant-collapse-content-box{
        padding: 4px 0;
      }
    }
  }
  .ant-collapse-item {
    border-bottom: 2px solid white !important;
  }
}

.collapse-validation-collapse{
  padding-top: 4px;
  .ant-collapse-item {
    background: #FAFAFA;
    .ant-collapse-header {
      background: #F4F4F4;
      border: 1px solid #F4F4F4;
      border-radius: 4px;
    }
    .ant-collapse-content{
      .ant-collapse-content-box{
        padding: 4px 16px 16px;
      }
    }
  }
  .ant-collapse-item {
    border-bottom: 2px solid white !important;
  }
}

.val-sub-heading {
  padding: 10px;
  background: #F4F4F4;
  border: 1px solid #F4F4F4;
  border-radius: 4px;
}

.product-photo-artist {
  width: 100%;
  height: 160px;
  object-fit: cover;
  &.large {
    height: auto;
  }
  &.floorplan {
    object-fit: contain;
    height: 420px;
  }
}

.product-photo-artist-complaint {
  padding: 15px;
  width: 100%;
  height: 100px;
  object-fit: cover;
  &.large {
    height: auto;
  }
  &.floorplan {
    object-fit: contain;
    height: 420px;
  }
}

.product-artist-card {
  max-width: 160px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  margin-right: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  &.large {
    max-width: 100%;
  }
  &.floorplan {
    max-width: 100%;
    text-align: center;
  }
  &.w-auto {
    max-width: max-content;
  }
}

.reference-img-card {
  max-width: 100px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  margin-right: 8px;
  margin-bottom: 8px;
  cursor: pointer;
}

.reference-img-photo {
  width: 100%;
  height: 100px;
  object-fit: cover;
}

.loading-bg {
  color:  #25D6A4 !important;
}

.model-time-h {
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  vertical-align: middle;
  text-transform: capitalize;
  color: #555555;
}

.reject-heading {
  font-family: Avenir;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 120%;
  text-align: center;
  vertical-align: middle;
  color: #FF4A4D;
}

.switch-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  padding: 12px 0px 12px 0px;
  margin-left: 12px;
  border: 0px 0px 1px 0px;
}

.collapse-silo-lighting {
  .ant-radio-group{
    width: 100% !important;
  }
  &.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box{
    background-color: #FFFFFF;
  }
}

.bordered-card {
  border: 1px black solid !important;
  height: fit-content;
  .ant-card-body{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
}

.non-bordered-card{
  height: fit-content;
  .ant-card-body{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
}

.silo-background{
  padding: 12px 0px 0px 0px;
  margin-left: 12px;
}

.modeling-time {
  padding: 15px;
  .note-text {
    font-size: 14px;
  }
}

.modeling-time-value {
  font-size: 18px;
  margin-left: 15px;
  padding: 8px 12px 8px 12px;
  border: 1px solid #E4E4E4;
  border-radius: 4px;
  vertical-align: middle;
}
.unhappy-image-property {
  cursor: pointer;
  height: 100px;
  width: 100px;
  max-width: 100%;
  border-radius: 4px;
  object-fit: cover;
  display: flex;
}

.unhappy-selected-card {
  margin-right: 10px;
  // width: 64px;
  // height: 64px;
  padding: 10px;
}

.unhappy-div {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.quick-render-error {
  display: block;
  margin: 0 auto;
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translate(0, -50%);
  margin-right: 20px;
  margin-left: 20px;
  text-align: center;
}

.error-icon {
  font-size: 50px;
  margin-bottom: 20px;
  color: #ff4d4f;
  &.black {
    color: #333333;
  }
}

.pdp-card {
  margin-right: 10px;
  height: 64px;
  width: 64px;
  padding: 10px;
}

.bg-model-position {
  z-index: 1;
  position: absolute;
  top: 138px;
  left: 20px;
  display: flex;
  justify-content: space-between;
}

.approve-render {
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: #29B862;
  &.approved {
    color: #FFFFFF;
  }
}

.approve-render-bg {
  background: #F7FDF9;
  cursor: pointer;
  width: auto;
  padding: 10px 24px;
  border: 1px solid #29B862;
  box-sizing: border-box;
  border-radius: 4px;
  z-index: 2;
  position: absolute;
  top: 20px;
  left: 20px;
  vertical-align: middle;
  display: flex;
  justify-content: space-between;
  &.dark-green {
    background: #29B862;
    border: 1px solid #29B862;
  }
  &.white {
    background: #FFFFFF;
    border: 1px solid #141414;
  }
  &.compare {
    top: 138px;
  }
}

.tabpane-flag-override {

}

.tab-flag-override .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab {
  padding: 8px 20px 8px 20px;
  font-size: 14px !important;
  line-height: 120% !important;
  &.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: #276DD7 !important;
    &:hover {
      color: #276DD7 !important;
    }
  }
  &:hover {
    color: #276DD7 !important;
  }
}

.tab-flag-override .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-ink-bar{

  background: #276DD7 !important;

}

.download-links-pos {
  position: absolute;
  z-index: 1;
  top: 20px;
  right: 68px;
}

.reference-file-form {
  border: 1px solid #f4f4f4 !important;
  z-index: 1;
  margin-right: 16px;
  border: 1px solid #f4f4f4;
  border-radius: 4px;
  &.scene-replace {
    justify-content: center;
    align-items: center;
    &.no-bg {
      background:transparent;
    }
    &.bg-grey {
      background: #FAFAFC;
    }
  }
  &.scene-3ds {
    margin-right: 0;
    display: grid;
    justify-content: center;
    align-content: center;
    background: #FAFAFC;
    border: 2px dashed #f4f4f4 !important;
    border-radius: 4px;
    margin-bottom: 0px !important;
    .ant-legacy-form-item-control-wrapper {
      border: 1px dashed #f4f4f4 !important;
    }
    &.no-border {
      border: none !important;
    }
    &.solid-border {
      border: 1px solid #f4f4f4 !important;
    }
    &.no-bg {
      background:transparent;
    }
  }
  .ant-legacy-form-item-control-wrapper {
    border: 1px solid #f4f4f4 !important;
  }
}

.flag-scene-upload-box-color {
  background-color:white !important;
  border: none !important;
  border-radius: 4px !important;
  padding-bottom: 40px;
  border-radius: 4px;
  height: 155px;
  text-align: center;
  &:hover {
    border: none !important;
  }
  .ant-upload-select {
    background-color: transparent !important;
  }
  .ant-upload.ant-upload-select {
    min-height: 155px !important;
    border: none!important;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      border: none !important;
    }
  }
  .ant-upload-list {
    position: relative !important;
    margin: auto;
    width: 100% !important;
    margin-top: 0px;
    top: -50px;
    // margin-top: 5px;
    .ant-upload-list-item {
      margin-bottom: 18px;
      font-family: Avenir;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 120%;
      text-transform: uppercase !important;
    }

    .ant-upload-list-item-progress {
      .ant-progress-line {
        .ant-progress-outer{
          .ant-progress-inner {
            .ant-progress-bg{
              height: 6px !important;
            }
          }

        }
      }
    }
  }
}

.flag-upload-box-color {
  background-color:white !important;
  border: none !important;
  border-radius: 4px !important;
  padding-bottom: 40px;
  border-radius: 4px;
  height: 155px;
  text-align: center;
  &:hover {
    border: none !important;
  }
  .ant-upload-select {
    background-color: transparent !important;
  }
  .ant-upload.ant-upload-select {
    min-height: 155px !important;
    border: none!important;
    &:hover {
      border: none !important;
    }
  }
  .ant-upload-list {
    position: unset !important;
    margin: auto;
    width: 200px !important;
    // margin-bottom: 5px;
    margin-top: 5px;
    .ant-upload-list-item {
      margin-bottom: 18px;
    }
  }
}

.image-upload-box {
  background-color:white !important;
  border: none !important;
  border-radius: 4px !important;
  padding-bottom: 40px;
  border-radius: 4px;
  height: 199px;
  text-align: center;
  &:hover {
    border: none !important;
  }
  .ant-upload-select {
    background-color: transparent !important;
  }
  .ant-upload.ant-upload-select {
    min-height: 201px !important;
    border: none!important;
    &:hover {
      border: none !important;
    }
  }
  .ant-upload-list {
    position: unset !important;
    margin: auto;
    width: 200px !important;
    margin-top: 5px;
    .ant-upload-list-item {
      margin-bottom: 18px;

      font-family: Avenir;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 120%;
    }
  }
}

.image-uploading-box {
  background-color:white !important;
  border: none !important;
  border-radius: 4px !important;
  padding-bottom: 40px;
  border-radius: 4px;
  height: 199px;
  text-align: center;
  &:hover {
    border: none !important;
  }
  .ant-upload-select {
    background-color: transparent !important;
  }
  .ant-upload.ant-upload-select {
    min-height: 201px !important;
    border: none!important;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      border: none !important;
    }
  }
  .ant-upload-list {
    position: relative !important;
    margin: auto;
    width: 100% !important;
    margin-top: 0px;
    top: -80px;
    .ant-upload-list-item {
      margin-bottom: 18px;

      font-family: Avenir;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 120%;
    }

    .ant-upload-list-item-progress {
      .ant-progress-line {
        .ant-progress-outer{
          .ant-progress-inner {
            .ant-progress-bg{
              height: 6px !important;
            }
          }

        }
      }
    }
  }
}

.image-panorama-upload-box {
  background-color:white !important;
  border: none !important;
  border-radius: 4px !important;
  padding-bottom: 40px;
  border-radius: 4px;
  height: 199px;
  text-align: center;
  &:hover {
    border: none !important;
  }
  .ant-upload-select {
    background-color: transparent !important;
  }
  .ant-upload.ant-upload-select {
    min-height: 273px !important;
    border: none!important;
    &:hover {
      border: none !important;
    }
  }
  .ant-upload-list {
    position: unset !important;
    margin: auto;
    width: 70% !important;
    margin-top: 5px;
    .ant-upload-list-item {
      margin-bottom: 18px;

      font-family: Avenir;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 120%;
    }

    .ant-upload-list-item-progress {
      .ant-progress-line {
        .ant-progress-outer{
          .ant-progress-inner {
            .ant-progress-bg{
              height: 6px !important;
            }
          }

        }
      }
    }
  }
}

.image-panorama-uploading-box {
  background-color:white !important;
  border: none !important;
  border-radius: 4px !important;
  padding-bottom: 40px;
  border-radius: 4px;
  height: 199px;
  text-align: center;
  &:hover {
    border: none !important;
  }
  .ant-upload-select {
    background-color: transparent !important;
  }
  .ant-upload.ant-upload-select {
    min-height: 273px !important;
    border: none!important;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      border: none !important;
    }
  }
  .ant-upload-list {
    position: relative !important;
    margin: auto;
    width: 70% !important;
    margin-top: 0px;
    top: -100px;
    .ant-upload-list-item {
      margin-bottom: 18px;

      font-family: Avenir;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 120%;
      text-transform: uppercase !important;
      .ant-upload-list-item-info {
        .ant-upload-span {
          .ant-upload-list-item-name {
            width: 70px;
          }
        }
      }
    }

    .ant-upload-list-item-progress {
      .ant-progress-line {
        .ant-progress-outer{
          .ant-progress-inner {
            .ant-progress-bg{
              height: 6px !important;
            }
          }

        }
      }
    }
  }
}

.image-replace-field .ant-upload-select-text {
  padding: 0px !important;
  background:white ;
  padding: 8px;
}

.upload-card-properties {
  border-radius: 4px;
  background-color:#ffffff;
  padding: 8px;
  &.show{
    background-color: #fafafa;
    height: 214px;
    cursor: pointer;
    &.scene {
      height: 64px;
    }
  }
}

.eye-out-color {
  color: #276DD7;
  font-size: 24px;
}
.show-m-text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  font-family: Avenir;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  letter-spacing: 0.02em;
  width: auto;
  color: #276DD7;
  &.show-less {
    margin-bottom: 10px;
    justify-content: flex-end;
  }
}

.show-more-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position:absolute;
  margin:0;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  &.row {
    flex-direction: row;
  }
}

.upload-image-property{
  // cursor: pointer;
  height: 155px;
  max-width: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: 4px;
  display: inline-block;
  &.max-3ds {
    height: 120px;
  }
  &.max-3ds-single {
    height: 235px;
  }
  &.cover-area {
    height: 220px;
  }
}


.flag-note-pos {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
}

.note-text-pos {
  position:absolute;
  margin:0;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.scene-flag-columns {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  background: #FAFAFC;
  margin-bottom: 16px;
  &.white {
    background: white;
    padding: 0;
  }
  &.flag-width {
    width: 100%;
  }
}

.scene-flag-columns-single {
  padding: 16px;
  background: #FAFAFC;
}
.image-group {
  display: flex;
  justify-content: space-between;

}

.upload-flag-scene {
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &.border {
    border: 1px solid #f4f4f4;
    border-radius: 4px;
  }
}
.product-tab .ant-tabs-tab {
    font-size: 16px !important;
  }
.product-tab .ant-tabs-tab-active,.product-tab  .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #276DD7 !important;
  justify-content: center;
  background-color: #F2F6FD !important;
}
.product-tab .ant-tabs-ink-bar {
  background: #276DD7;
}

.product-txt-heading {
  font-size: 18px;
  font-weight: 500;
  font-family: Manrope;
  line-height: 20px;
}

.product-tab .ant-tabs-tab-btn:hover, .product-tab .ant-tabs-tab:hover {
  color: #276DD7 !important;
}

.product-tab .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab {
  padding: 10px 30px 10px 30px;
}

.empty-scene-template .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab {
  padding: 0px !important;
}

.product-tab {
  &.library .ant-tabs-nav-list {
    position: relative;
    display: flex;
    // flex: 0.9 0 auto;
    text-align: center;
    transform: none;
    will-change: transform;
  }
  &.library .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab {
    // width: 100% !important;
    justify-content: center;
  }

  &.library .ant-tabs-tab {
    // flex: 1;
    display: flex;
    text-align: center;
  }

  &.library .ant-tabs-nav > div:nth-of-type(1) {
    display: flex !important;
    // width: 100% !important;
  }
}
.product-tab .ant-tabs-nav .ant-tabs-tab {
  margin: 0px !important;
}
.stats-table{
  & th {
    border: 1px #E4E4E4;
    border-style: solid;
    border-spacing: 0;
    border-collapse: collapse;
    color: #555555;
    font-weight: 500 !important;
    padding: 10px 15px 10px 10px;
  };
  & td {
    min-width: 11vw;
    width: 11vw;
    border: 1px #E4E4E4;
    border-style: solid;
    border-spacing: 0;
    border-collapse: collapse;
    padding: 10px 15px 10px 10px;
  }
}

.dimension-table{
  & th,& td {
  min-height: 20vw;
}
}

@media screen and (max-width: 1400px) {
  .product-tab .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab {
    padding: 8px 20px 8px 20px;
    font-size: 14px !important;
  }
  .space-side-margins {
  margin: 0px 40px 0px 40px

  }

  @media screen and (max-width: 1400px) {
    .modal-okay.square.comparison-btn.admin-compare.comp-view span{
      margin-left: 6px !important;
      margin-right: 6px !important;
    }
  }

  .room-status {
    font-size: 16px;
  }

  .product-status {
    font-size: 16px;
  }
}

.rejection-modal::-webkit-scrollbar {
  width: 8px
}

.validation-heading {
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  vertical-align: middle;
  display: flex;
  align-items: center;
  color: #333333;
}


#item-modal-dropdown, #item-modal-dropdown:visited, #item-modal-dropdown:focus, #item-modal-dropdown:hover, #item-modal-dropdown:active {
  height: 40px;
  width: 205px;
  background-color: #FAFAFC;
  border: none;
  font: #555555;
}

#item-modal-dropdown span .anticon.anticon-down {
  margin-left: 5px !important;
}

.draw-full-circle
{
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid #009D19;
  background-color: #009D19;
  color: white;
  &.yellow {
    border: 1px solid #EAA500;
    background-color: #EAA500;
  }
  &.red {
    border: 1px solid #D93025;
    background-color: #D93025;
  }
  &.orange {
    border: 1px solid #FF5F15;
    background-color: #FF5F15;
  }
}

.list-validation {

  padding: 4px 16px 16px;

}

.list-item-validation {
  border-bottom: none !important;
  justify-content: flex-start !important;
}
.rejection-modal::-webkit-scrollbar {
  width: 8px
}

.blue-note-bg {
  background: rgba(39, 109, 215, 0.06);
  border-radius: 4px;
  padding: 10px 16px;
}

.mark-style {
  mark {
    background-color: transparent !important;
  }
}

.popup-silo-shot {
  width: 288px;
  height: 155px;
  background-color: #FAFAFC;
  object-fit: contain;
}

.silo-shot-popup {
  width: 320px;
  padding: 20px 16px;
  margin: 0 auto;
}

.position-snapshot-fill {
  position: absolute;
  transform: translateY(50%);
  right: 0;
  left: 0;
}

.select-font {
  .ant-select-item-option-content {
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    /* identical to box height, or 157% */

    display: flex;
    align-items: center;

    color: #333333;
  }
}

.admin-override-space360-4k {
  .ant-modal-footer {
    border-top: unset;
    padding-bottom: 40px;
    text-align: center;
  }
 }
@media screen and (max-width: 1400px) {
  .retries-div {
    max-width: 75%;
  }
}
@media screen and (max-width: 1000px) {
  .retries-div {
    max-width: 100%;
  }
}

@media screen and (min-width: 1400px) {
  .retries-div {
    max-width: 50%;
  }
}

.black-slider {
  .ant-slider-track {
    background-color: #141414 !important;
  }
  .ant-slider-handle {
    background-color: #141414 !important;
    border: 2px solid #141414 !important;
    width: 16px !important;
    height: 16px !important;

    &:focus {
      box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.12);
    }
  }
  .ant-slider-dot-active {
    border-color: #c4c4c4 !important;
  }
  .ant-slider-rail{
    background-color: #e3e3e3;
  }

}

.black-fov-slider {
  width: 180px;
  .ant-slider-track {
    background-color: #141414 !important;
  }
  .ant-slider-handle {
    background-color: #141414 !important;
    border: 2px solid #141414 !important;
    width: 16px !important;
    height: 16px !important;

    &:focus {
      box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.12);
    }
  }
  .ant-slider-dot-active {
    border-color: #c4c4c4 !important;
  }
  .ant-slider-rail{
    background-color: #e3e3e3;
  }
}

.black-slider .ant-slider {
  &:hover .ant-slider-track {
    background-color: #141414 !important;
  }
}

.black-fov-slider .ant-slider {
  &:hover .ant-slider-track {
    background-color: #141414 !important;
  }
}

.input-fov {
  font-family: Manrope;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 120%;
  color: #555555;
  display: flex;
  background: #fafafa;
  align-items: baseline;
}


.customize-mask {
  font-size: 20px;
  opacity: 1;
}
.customize-mask .anticon {
  font-size: 32px;
}

.customize-mat-icon {
  font-size: 32px !important;
  &.anticon {
    font-size: 32px !important;
  }
}


.image-thumbnail {
  width: 48px;
  height: 48px;
  border-radius: 4px;
  z-index: 10;
  cursor: pointer;
}

.thumbnail-btn {
  cursor: pointer;
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  /* identical to box height, or 150% */

  letter-spacing: 0.02em;
  vertical-align: middle;

  color: #FFFFFF;
}

.thumbnail-btn-bg{
  cursor: pointer;
  margin: auto;
  margin-top: 32px;
  padding: 13.5px 16px;
  background: rgba(20, 20, 20, 0.8);
  opacity: 0.8;
  border: 1px solid #FFFFFF;
  border-radius: 4px;
  display: table;

  &.blur {
    z-index: 1;
    margin: 0;
    border: none;
    width: max-content;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &.mat {
      padding: 10px 12px;
      top: 40%;
    }
  }

}


.retry-btn {
  margin-top: 20px;
  display: flex;
  align-items: center;
  margin: auto;
  justify-content: center;
  text-align: left;
  color: #1e90ff;
  border: 1px solid #1e90ff;
  position: relative;
  background: transparent;
  top: 20px;
  &.multiple {
    top: 0;
  }
  &.flag {
    margin: 0 auto 20px;
  }
  &:hover {
    background: #276DD7;
    color: white !important;
  }
}

.retry-error-message {
  color: #ff4d4f;
  text-align: right;
  font-size: 12px;
  font-family: Manrope;
  &.left {
    text-align: left;
  }
  &.center {
    text-align: center;
  }
}
.product-thumbnail-card {
  margin-right: 10px;
  width: 64px;
  height: 64px;
  padding: 10px;
}

.product-thumbnail-img {
  cursor: pointer;
  height: 40px;
  width: 40px;
  object-fit: contain;
  display: inline-block;
}

.tag-bg {
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.3);
  width: auto;
  margin: auto;

  position: absolute;
  left: 4px;
  top: 4px;

  &.large {
    left: 20px;
    top: 20px;
    padding: 5px;
  }
}


.manrope {
  font-family: Manrope;
  font-style: normal;
  &.f-36 {
    font-weight: 600;
    font-size: 36px;
    letter-spacing: 0;
    line-height: 120%;
  }

  &.f-30 {
    font-weight: 600;
    font-size: 30px;
    letter-spacing: 0;
    line-height: 120%;
  }

  &.f-32 {
    font-weight: 600;
    font-size: 32px;
    letter-spacing: 0;
    line-height: 120%;
  }
  &.f-28 {
    font-weight: 500;
    font-size: 28px;
    line-height: 140%;
    display: flex;
    align-items: center;
    text-align: center;
  }

  &.f-24 {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    display: flex;
    align-items: center;
    text-align: center;
  }

  &.f-20 {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    &.margin-top-20{
      margin-top: 20px;
    }
    &.black{
      color: #1F1F1F
    }
  }

  &.f-18 {
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    &.dollar{
      font-weight: 600;
      color: #276DD7;
    }
    &.black{
      color: #1F1F1F
    }
  }

  &.f-16 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    &.w-700{
      font-weight: 700;
    }
    &.w-600{
      font-weight: 600;
    }
    &.w-500{
      font-weight: 500;
    }
    &.black{
      color: #1F1F1F
    }
  }

  &.f-14 {
    font-weight: 500;
    font-size: 14px !important;
    line-height: 20px;
    letter-spacing: 2%;
    &.w-400{
      font-weight: 400;
    }
    &.w-600{
      font-weight: 600;
    }
    &.w-500{
      font-weight: 500;
      line-height: 14px !important;
    }
    &.33{
      color:#333333
    }
    &.black{
      color: #1F1F1F
    }
  }

  &.f-12 {
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: 0.02em;
    &.w-300 {
      font-weight: 300;
    }
    &.w-500{
      font-weight: 500;
    }
    &.w-600{
      font-weight: 600;
    }
    &.l-140{
      line-height: 140%;
    }
    &.black{
      color: #1F1F1F
    }
  }

  &.lh-120 {
    line-height: 120%;
  }

  &.lh-140 {
    line-height: 140% !important;
  }

  &.f-10 {
    font-weight: 500;
    font-size: 10px;
    line-height: 120%;
    letter-spacing: 0.02em;
    &.black{
      color: #1F1F1F
    }
  }

  &.f-8 {
    font-weight: 500;
    font-size: 8px;
    line-height: 120%;
    letter-spacing: 0.02em;
  }

  &.lh-32 {
    line-height: 32px;
  }

  &.text-no-wrap {
    text-wrap: nowrap !important;
  }

  &.clamp-text {
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: nowrap;
    display: block;
    text-overflow: ellipsis;
    overflow:hidden;
    width: 145px;
    &.w-100 {
      max-width: 100%;
      width: unset;
    }
    &.w-50 {
      max-width: 50%;
      width: unset;
    }
    &.w-60 {
      width: 60%;
      max-width: 50%;
      width: unset;
    }
    &.w-90 {
      width: 90%;
      text-align: left;
    }
    &.w-50px {
      max-width: 50px;
      width: unset;
    }
    &.w-200px {
      max-width: 200px;
      width: unset;
    }
  }

  &.clamp-text-400 {
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: nowrap;
    display: block;
    text-overflow: ellipsis;
    overflow:hidden;
    min-width: 100px ;
    max-width: 400px;
  }

  &.clamp-w-width {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.clamp-w-150 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
  }

  &.w-400 {
    font-weight: 400;
  }

  &.w-600 {
    font-weight: 600;
  }

  &.w-700 {
    font-weight: 700;
  }

  &.w-900 {
    font-weight: 900;
  }

  &.normal-style {
    font-style: normal !important;
  }

  &.italic {
    font-style: italic;
  }

  &.green {
    color: #009D19;
  }

  &.green-75{
    color: #75d8a5;
  }

  &.black-55 {
    color: #555555 !important;

    .ant-legacy-form-item-label > label {
      color: #555555 !important;
    }
  }

  &.blue {
    color: "#276DD7"
  }

  &.black-14 {
    color: #141414;
  }

  &.black-00 {
    color: #000000;
  }

  &.blue-96 {
    color: #096DD9;
  }

  &.black-33 {
    color: #333333;
  }

  &.red {
    color: #FF4A4D;
  }

  &.red-error {
    color: #E54547;
  }

  &.red-d9 {
    color: #D93025;
  }

  &.red-ff {
    color: #ff4d4f;
  }

  &.red-03 {
    color: #D40003;
  }

  &.grey-8c {
    color: #8C8C8C;
  }

  &.black-26 {
    color: #262626;
  }
  
  &.yellow {
    color: #EDAD15;
  }
  &.yellow-a{
    color: #a2a229;
  }

  &.orange {
    color: #AD6800;
  }

  &.grey-73 {
    color: #737373;
    a {
      &:hover {
        text-decoration: underline;

      }
    }
  }

  &.black-73 {
    color: #00000073;
    &:hover {
      color: #00000073;
      text-decoration: underline;
    }
  }

  &.black-d9 {
    color: #000000d9;
  }

  &.grey-99 {
    color: #999999;
  }

  &.grey-6b {
    color: #6B6B6B;
  }

  &.grey-77 {
    color: #777777;
    a {
      &:hover {
        text-decoration: underline;

      }
    }
  }

  &.grey-66 {
    color: rgba(0, 0, 0, 0.45);
  }

  &.lh-28 {
    line-height: 28px;
  }

  &.lh-14 {
    line-height: 14px;
  }


  &.white {
    color: #FFFFFF;
  }

  &.capitalize {
    text-transform: capitalize;
  }

  &.blue {
    color: #276DD7;
    &.link {
      text-decoration: underline;
      cursor: pointer;
    }
    &.hover-change {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.j-end {
    display: flex;
    align-items: flex-end;
    text-align: center;
  }

  &.j-center {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &.upper-case {
    text-transform: uppercase;
  }

  &.underline {
    text-decoration: underline;
  }

}

.err-bg {
  background: #FEF6F6;
  border-radius: 16px;
  padding: 6px 8px;
  width: fit-content;
}

.adjust-height{
    height: 40px !important;
    &.h43px{
      height: 43px !important;
    }
    &.h200px {
      height: 200px !important;;
    }
    &.h500px {
      height: 500px !important;;
    }
    &.h700px {
      height: 700px !important;;
    }
}

.library-search {
  padding: 14px 16px;
  &.font-10 {
    .ant-input {
      font-size: 10px !important;

    }
  }
  &.font-12 {
    .ant-input {
      font-size: 12px !important;

    }
  }
}

.folder-name-input {
  padding: 14px 10px;
}

.note-bg-specs {
  background: #F2F6FD;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  color: #555555;
  padding: 16px;
  &.font-14 {
    font-size: 14px;
  }
  &.font-12 {
    font-size: 12px;
  }

  &.font-16 {
    font-size: 16px;
  }
  &.grey-77 {
    color: #777777;
  }

  &.pulse-effect {
      box-shadow: 0 0 0 0 rgba(52, 172, 224, 1);
      animation: pulse-blue 2s infinite;
    }


}

@keyframes pulse-blue {
  0% {
    //transform: scale(0.95);
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(52, 172, 224, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(52, 172, 224, 0);
  }

  100% {
    transform: scale(1);
    //transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
  }
}

.material-img {
  width: 160px;
  height: 160px;
  object-fit: cover;
  cursor: pointer;
  border-radius: 4px;
  &.blur {
    filter: blur(4px);
  }
  &.pattern-select {
    height: 85px;
    width: 85px;
  }
  &.reject {
    width: 48px;
    height: 48px;
    margin-right: 16px;
  }
  &.model {
    width: 122px;
    height: 80px;
  }
  &.library {
    width: 100%;
    height: 275px;
    object-fit: cover;
  }
}

.position-cards {
  display: flex;
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.material-card {
  padding: 8px;
  display: flex;
  margin-right: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  &.select {
    width: fit-content;
    height: fit-content;
    margin-bottom: 8px;
  }
  &.library{
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 0px;
  }
}

.upload-pd {
  padding: 0;
  cursor: pointer;
}

.tree-select-material {

  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 2%;
  line-height: 100%;
  &.f-10 {
    font-size: 10px;
  }
  &.f-12 {
    font-size: 12px;
  }
  &.f-16 {
    font-size: 16px;
  }
  &.bg {
    .ant-select-selector {
      background-color: #DFDFDF;
      border-color: #dfdfdf;
      height: 47px !important;

    }
  }

  &.adjust-height-46{
    .ant-select-selector{
      height: 46px !important;
    }
  }

  &.item-height-32{
    .ant-select-selection-item {
      height: unset !important;
      line-height: 32px !important;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }

  &.item-height-35{
    .ant-select-selection-item {
      height: unset !important;
      line-height: 35px !important;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
  .ant-select-selector {
    height: 40px !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .ant-select-selection-search {
      height: unset !important;
      padding: 14px 16px !important;
      padding-left: 0px !important;
      line-height: 45px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .ant-select-selection-search-input {
        height: unset !important;
        padding: 0px 16px !important;
        padding-left: 0px !important;
        line-height: 45px;
      }
    }
    .ant-select-selection-item {
      height: unset !important;
      line-height: 45px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .ant-select-selection-placeholder{
      height: unset !important;
      line-height: 45px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }

  &.lh-50 {
    .ant-select-selector {
      height: 50px !important;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .ant-select-selection-search {
        height: unset !important;
        padding: 14px 16px !important;
        padding-left: 0px !important;
        line-height: 45px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .ant-select-selection-search-input {
          height: unset !important;
          padding: 0px 16px !important;
          padding-left: 0px !important;
          line-height: 45px;
        }
      }
      .ant-select-selection-item {
        height: unset !important;
        line-height: 45px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .ant-select-selection-placeholder{
        height: unset !important;
        line-height: 45px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }
  }
  &.filter {
    width: 100% !important;

    .ant-select-selector {
      background-color: #DFDFDF;
      border-color: #dfdfdf;
      border-radius: 4px;
      color: #555555;
      min-height: 45px;
      height: auto !important;
    }
    &.bg-light {
      .ant-select-selector {
        background-color: #F7F7F8;
        border-color: #F7F7F8;
        .ant-select-selection-search {
          margin-inline-start: 4px;
          font-size: 12px;
          height: 10px !important;
          padding: 0px 0px 0px !important;
          .ant-select-selection-search-input {
            padding: 0px !important;
          }
        }
        .ant-select-selection-overflow {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
      }
    }
    .ant-select-selection-item {
      height: 35px !important;
      border-color: #dfdfdf;
      background-color: #FAFAFA;
      font-size: 12px;
      border-radius: 16px;
    }
    .ant-select-selection-overflow-item-suffix {
      height: 10px !important;

    }
  }
}

.selectable-categories > div > .ant-select-selection-overflow {
  line-height: 2.3;
}


.ant-select-tree .ant-select-tree-treenode.filter-node .ant-select-tree-title {
  color: inherit !important;
  font-family: Manrope;
}

.select-tags {
  .ant-select-selector {
    padding: 7px 9px !important;
  }
  .ant-select-selection-placeholder{
    padding: 7px 5px !important;
  }
}

.material-field {
  width: 100%;
}

.upload-box-references {
  background-color: rgba(244, 244, 244, 0.5) !important;
  border: 1px dashed #d8d7d7 !important;
  border-radius: 4px !important;
  padding-bottom: 2px;
  text-align: center;
  &:hover {
    background: rgba(244, 244, 244, 0.5)  !important;
    border: 1px dashed  #276DD7 !important;
    color: white !important;
  }
  .ant-upload-select {
    background-color: transparent !important;
  }
  .ant-upload.ant-upload-select {
    min-height: 0px !important;
    border: none!important;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      border: none !important;
    }
  }

  .ant-upload-list-item-info {
    margin-top: 2px;
    margin-bottom: 2px;
  }
  .ant-upload-list {
    position: unset !important;
    margin: auto;
    width: 80% !important;
    margin-top: 5px;
    .ant-upload-list-item {
      margin-bottom: 18px;
    }
  }
}

.center-align-h {
  margin: auto;
  text-align: center;
}

.center-align-box {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
}

.center-align-v {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.delete-material {
  cursor: pointer;
}

.mat-list-border {
  padding: 18px 0;
  border-bottom: 1px solid #E4E4E4;
}

.sticky-banner {
  background: #FFFFFF;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.04);
  position: sticky;
  padding: 26px 3% 26px 3%;
  width: 100%;
  z-index: 1;
  bottom: 0;
  &.prev-next-pd {
    padding: 24px 50px;
  }
}

.rejected-mat {
  width: 80px;
  height: 80px;
  object-fit: contain;
  border-radius: 4px;
}

.select-mat {
  width: 64px;
  height: 64px;
  object-fit: contain;
  border-radius: 4px;

}

.select-mat-small {
  width: 40px;
  height: 40px;
  object-fit: contain;
  border-radius: 4px;

}

#material-inspector{
  width: 80vw;
  height: calc(72vh - 125px);
  border: 0px !important;
  border-radius: 8px;
  object-fit: cover;
  margin: auto;
}

.selected-mat {
  width: 320px;
  height: 320px;
  border-radius: 16px;
  object-fit: cover;
  margin: auto;
  &.admin {
    width: fit-content;
    height: calc(72vh - 125px);
  }

  &.blur {
    filter: blur(8px);
  }

  &.modal-img {
    width: 280px;
    height: 280px;
  }
  &.approved-material {
    width: 340px;
    height: 340px;
  }
}

.material-img-bg {
  background: #FAFAFA;
  padding: 10px 0;
}

.lib-mat {
  width: 120px;
  height: 120px;
  object-fit: contain;
  border-radius: 4px;
  &.small {
    width: 60px;
    height: 60px;
  }
  &.cover {
    object-fit: cover;
  }
}

.material-lib-card {
  width: 130px;
  height: 166px;
}

.select-view {
  line-height: 188% !important;
  .ant-radio-button-wrapper {
    .ant-radio-button-wrapper-checked {
      &:hover {
        color:white !important;
      }
    }

    &:hover {
      color: #333333 !important;
    }

  }
}

.selected-view {
  .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper {
    border-right-color: #333333 !important;
    background: #333333 !important;
    border-color: #333333 !important;
  }
}

.approve-cta-btn {
  background: white !important;
  border-radius: 4px !important;
  border: 1px solid #25A759 !important;
  height: fit-content !important;
  width: unset !important;
  padding: 0 !important;
  color: #25A759 !important;
  &:hover {
    background: #25A759  !important;
    border: 1px solid #25A759  !important;
    color: white !important;
  }

  &.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
    background: white !important;
    opacity: 0.5;
    border: 1px solid #25A759  !important;
    color: #25A759 !important;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  &.ant-tooltip-disabled-compatible-wrapper {
    background: white !important;
    opacity: 0.5;
    border: 1px solid #25A759  !important;
    color: #25A759 !important;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  &.f-16 {
      padding: 12px 32px !important;
  }
}

.approved-cta-btn {
  border-radius: 4px !important;
  height: fit-content !important;
  width: unset !important;
  padding: 0 !important;
  background: #25A759  !important;
  border: 1px solid #25A759  !important;
  color: white !important;
  &:hover {
    background: #25A759  !important;
    border: 1px solid #25A759  !important;
    color: white !important;
  }

  &.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
    opacity: 0.5;
    background: #25A759  !important;
    border: 1px solid #25A759  !important;
    color: white !important;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  &.ant-tooltip-disabled-compatible-wrapper {
    opacity: 0.5;
    background: #25A759  !important;
    border: 1px solid #25A759  !important;
    color: white !important;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  &.f-16 {
      padding: 12px 32px !important;
  }
}

.reject-cta-btn {
  background: white !important;
  border-radius: 4px !important;
  border: 1px solid  #D50003 !important;
  height: fit-content !important;
  width: unset !important;
  padding: 0 !important;
  color:  #D50003 !important;
  &:hover {
    background:  #D50003  !important;
    border: 1px solid  #D50003  !important;
    color: white !important;
  }

  &.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
    background: white !important;
    opacity: 0.5;
    border: 1px solid  #D50003  !important;
    color:  #D50003 !important;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  &.ant-tooltip-disabled-compatible-wrapper {
    background: white !important;
    opacity: 0.5;
    border: 1px solid  #D50003  !important;
    color:  #D50003 !important;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  &.f-16 {
      padding: 12px 32px !important;
  }
}

.rejected-cta-btn {
  background: #D50003 !important;
  border-radius: 4px !important;
  border: 1px solid  #D50003 !important;
  height: fit-content !important;
  width: unset !important;
  padding: 0 !important;
  color: white !important;
  &:hover {
    background:  #D50003  !important;
    border: 1px solid  #D50003  !important;
    color: white !important;
  }

  &.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
    background: #D50003 !important;
    opacity: 0.5;
    border: 1px solid  #D50003  !important;
    color:  white !important;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  &.ant-tooltip-disabled-compatible-wrapper {
    background: #D50003 !important;
    opacity: 0.5;
    border: 1px solid  #D50003  !important;
    color:  white !important;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  &.f-16 {
      padding: 12px 32px !important;
  }
}

.reject-mat-img {
  height: 120px;
  border: 1px solid #d2d2d2;
  width: 120px;
  object-fit: contain;
  border-radius: 16px;
}

.material-reject-uploader {
  &:hover {
    border: 1px dashed #276DD7 !important;
  }
}

.ant-upload-list-item-name {
  width: 20px;
}

.reject-admin-bg {
  background: #141414;
  border-radius: 4px;
  padding: 16px;
  width: auto;

  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  &.in-progress {
    top: 50%;
  }
}

.mat-img-modal {
  .ant-card-body {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
.admin-override-space360-4k {
  .ant-modal-footer {
    border-top: unset;
    padding-bottom: 40px;
    text-align: center;
  }
 }
@media screen and (max-width: 1400px) {
  .retries-div {
    max-width: 75%;
  }
}
@media screen and (max-width: 1000px) {
  .retries-div {
    max-width: 100%;
  }
}

@media screen and (min-width: 1400px) {
  .retries-div {
    max-width: 50%;
  }
}

.model-type-radio {
  label {
    border: 1px solid #E4E4E4;
    border-radius: 4px;
    padding: 16px;
  }
  &.black-border {
    border: 1px solid #555555;
    border-radius: 4px;
    padding: 16px;
  }
  &.grey-border {
    border: 1px solid #E4E4E4;
    border-radius: 4px;
    padding: 16px;
  }
}

.success-img {
  width: 50px;
  position: absolute;
  height: 50px;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  right: 0;
  z-index: 1;
  &.small {
    height: 40px;
    width: 40px;
    top: 48%;
  }

  &.smaller {
    height: 24px;
    width: 24px;
    top: 48%;
  }
}

.clip-text {
  width: 75px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &.w-50 {
    width: 50px;
  }
}

.align-col-left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.align-col-left2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.mat-tab {
  &.ant-tabs {
    flex-grow: 1;
    margin-right: 0px;
    width: 100%;
    text-align: center;
  }
}

.flag-compare {
  position: absolute;
  top: 138px;
  right: 60px;
  z-index: 1;
  &.true {
    top: 138px;
  }
}

.flag-in-progress {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
}

.loader-validation {
  color: #276DD7;
  font-size: 32px;
  margin-bottom: 10px;
}




.val-note-bg {
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &.green {
    background: #F7FDF9;
  }
  &.red {
    background: #FEF6F6;
  }
}

.validation-check {
  color: #29B862;
  &.error {
    color: #E54547;
  }
}

.flag-help {
  position: absolute;
  top: 40px;
  left: 0;
  text-decoration: underline;
  color: #205FCF;
  font-family: Avenir;
  cursor: pointer;
}

.flag-image-help {
  width: 330px;
  height: 200px;
  object-fit: contain;
}
.space-upload-tab .ant-tabs-tab-active,.space-upload-tab  .ant-tabs-tab-active .ant-tabs-tab-btn,.space-upload-tab .ant-tabs-ink-bar{
  color: #276DD7 !important
}

.space-upload-tab .ant-tabs-nav {
  margin-left: 10px;
}

.mat-tab .ant-tabs-nav {
  font-size: 16px !important;
  width: 100% !important;
}

.mat-tab .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab  {
  display: block !important; // centers text inside tabs
  flex: 1 !important;
  text-align: center !important;
}

.mat-tab .ant-tabs-nav > div:nth-of-type(1) {
  display: unset !important;
  width: 100% !important;
}


.adjust-pattern {
  // margin-bottom: 15px;
  .ant-slider-track {
    background-color:#e3ebf8 !important;
  }
  .ant-slider-handle {
    border: solid 2px #276DD7 !important;
    background-color: white;
    &:focus {
        border-color:#276DD7 !important;
        outline: none;
        -webkit-box-shadow: 0 0 0 5px rgba(0, 47, 255, 0.034) !important;
        box-shadow: 0 0 0 5px rgba(0, 47, 255, 0.034) !important;
    }
  }
}

.card-bg-config {
  background-color: #fafafa;
  border-radius: 4px;
  border-right: 2px solid #276DD7;
}

.mats {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  opacity: 1;
  object-fit: cover;
  box-shadow:  inset 20px 16px 20px 20px #000000
}

.mat-overlay {
  width: 32px;
  height: 32px;
  margin: auto;
  position: relative;
  opacity: 0.7;
  border-radius: 16px;
  &::after {
    content: "";
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    left: 0;
    width: 32px;
    height: 32px;
    border-radius: 16px;
  }

}


.edit-dim {
  margin-bottom: 0 !important;
}

.mat-search-bar {
  padding: 11px 15px !important;
  height: 42px !important;
  border-radius: 4px !important;
}

.info-settings {
  position: absolute;
  top: 32px;
  left: 32px;
  color: #A2A2A2;
  background: #141414;
  font-size: 24px;
  padding: 12px;
  border-radius: 4px;
  z-index: 1;
  &.text {
    width: 200px;
  }
  &.sticky {
    position: absolute;
    bottom: 24px;
    left: 102px;
    top: unset;
    z-index: 11;
  }
}

.black-bg-text {
  background: #141414;
  padding: 12px;
  border-radius: 4px;
  // width: 200px;
  &.sticky {
    position: fixed;
    width: 25%;
    bottom: 110px;
    @media (min-width: 1600px) {
      width: 18%;
    }
    @media (max-width: 1200px) {
      width: 30%;
    }
    .title {
      font-size: 18px;
      @media (max-width: 1366px) {
        font-size: 14px;
      }
    }
    .text {
      font-size: 16px;
      @media (max-width: 1366px) {
        font-size: 12px;
      }
    }
  }
}
.tag-txt {
  color: white;
  padding: 6px;
  font-family: Manrope;
  font-weight: 700;
  font-size: 10px;
  line-height: 24px;
  &.large {
    font-size: 14px;
    line-height: 24px;
  }
}

.ordering-img-card {
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 25px;
  width: auto;
  max-width: 310px;
  height: auto;
  max-height: 310px;
  padding: 10px;
}

.qa-img-card {
  cursor: not-allowed;
}


.ordering-img-card .ant-card-body{
  text-align: -webkit-center;
}

.ordering-img {
  height: auto;
  height: 240px;
  width: 240px;
  object-fit: contain;
  display: inline-block;
}


.scene-render-name {
  border: solid;
  border-color: white;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 800;
  max-width: 250px !important; 
  white-space: nowrap; 
  overflow: hidden;
}

.scene-render-name-editable{
  background: #eeeeee;
  padding: 4px;
  outline: none;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 800;
  max-width: 250px !important; 
  white-space: nowrap; 
  overflow: hidden;
}

.rig-override-post-approval.ant-switch-checked{
  background-color: #276DD7
}

.load-scene-img {
  display: block;
  margin-top: 5px;
}

.list-gap {
  li {
    padding: 10px;
  }
}

.sub-list-gap {
  li {
    padding: 5px;

  }
}

.white-check {
  color: white;
  position: relative;
  top: unset;
  left: 0;
  right: 0;
  bottom: 27px;
  z-index: 1;
}


.collapse-button {
  z-index: 2;
  position: absolute;
  top: 47%;
  right: -20px;
}

.vertical-scroll-config {
  height: calc(100vh - 202px) !important;
  overflow-y: scroll;
  overflow-x: hidden;
  &.pattern-materials {
    height: calc(100vh - 320px) !important;
  }
}

.vertical-scroll-config::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.vertical-scroll-config::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.vertical-scroll-config::-webkit-scrollbar-thumb {
  background: #e1e1e1;
}

/* Handle on hover */
.vertical-scroll-config::-webkit-scrollbar-thumb:hover {
  background: #e1e1e1;
}

#grid {
  pointer-events: none;
}


@media screen and (min-width: 1024px)  {
  .silo-camera-alignment-1 {
      position: absolute;
      right: 3%;
  }
}

@media screen and (min-width: 1400px) {
  .silo-camera-alignment-2 {
      position: absolute;
      right: 5.5%;
  }
}

@media screen and (min-width: 1500px) {
  .silo-camera-alignment-3 {
      position: absolute;
      right: 8.5%;
  }
}


@media screen and (min-width: 1700px) {
  .silo-camera-alignment-4 {
      position: absolute;
      right: 15%;
  }
}

@media screen and (max-width: 1024px) {
  .silo-camera-alignment-5 {
      position: absolute;
      right: 2%;
  }
}

.grid-main-box {
  height:80px;
  width:79px;
  border-radius:4px;
  margin-right: 8px;
  cursor: pointer;
}

.grid-box-top-left {
  height:41px;
  width:40px;
  color: #756969;
}

.grid-box-top-right {
  height:41px;
  width:40px;
}

.grid-box-bottom-left {
  height:38px;
  width:40px;
}

.grid-box-bottom-right {
  height:38px;
  width:40px;
}

.custom-grid-aspects, .custom-overlay-aspects {
  color: rgb(115, 115, 115);
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.s-modal {
    margin-top: 6px;
    .ant-input-number {
      &:hover{
        border-color: #40a9ff;
      }
    }
    .ant-input-number:focus, .ant-input-number-focused {
      border-color:#40a9ff;
      border-right-width: 1px !important;
      outline: 0;
      -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }
  }

  .ant-input-number {
    &:hover{
      border-color: #40a9ff;
    }
  }
  .ant-input-number:focus, .ant-input-number-focused {
    border-color:#40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
}

.grid-controls-heading {
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: #737373;
}

.overlay-controls-heading{
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: #737373;
  margin-bottom: 15px;
  margin-top: 15px;
}

.modal-heading-bold {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
  color: #333333;
  font-family: Manrope;
  margin: 0;
  margin-bottom: 16px;
}

.grid-color-container {
  height: 30px;
  width: 30px;
  margin: 2px;
  cursor: pointer;
}

.grid-color-box, .overlay-color-box {
  height:24px;
  width: 24px;
  border-radius: 2px;
  margin-top: 2px;
  margin-left: 2px;
  border: 1px solid black;
}

.overlay-color-container {
  margin-right: 6px;
  cursor: pointer;
}


.restore-product-container {
  text-align: center;
  position: absolute;
  z-index: 100;
  width: -webkit-fill-available;
  bottom: 140px;
  display: flex;
  justify-content: space-around;
}

.restore-product-btn {
  background-color: white;
  border: 1px solid #DDDDDD;
  border-radius: 5px;
  width: fit-content;
  padding-left: 10px;
  padding-right: 12px;
  display: flex;
  padding-top: 6px;
  cursor: pointer;
}

.restore-product-txt {
  color: rgb(217, 48, 37);
  font-size: 14px;
  font-family: Manrope;
  font-weight: 500;
  margin-top: 3px;
  margin-left: 5px;
}

.sc-products-tab-content-scrollable {
  overflow-y: scroll;
  overflow-x: clip;
  height: calc(100vh - 150px);
}

/* width */
.sc-products-tab-content-scrollable::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.sc-products-tab-content-scrollable::-webkit-scrollbar-track {
  background: #e1e1e1;
}

/* Handle */
.sc-products-tab-content-scrollable::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.81);
}

/* Handle on hover */
.sc-products-tab-content-scrollable::-webkit-scrollbar-thumb:hover {
  background: rgba(85, 85, 85, 0.73);
}

.scrollable-items {
  width: 95%;
  overflow-x: scroll;
  padding-bottom: 4px;
}

.scrollable-items::-webkit-scrollbar {
  height: 5px;
}

/* Track */
.scrollable-items::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.scrollable-items::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.81);
}

/* Handle on hover */
.scrollable-items::-webkit-scrollbar-thumb:hover {
  background: rgba(85, 85, 85, 0.73);
}

.space-upload-tab .ant-tabs-nav {
  margin-left: 10px;
}

.ant-upload-list-item{
  margin-top: 20px !important;
  // margin-bottom: 10px !important;
}
.product-edit-form {
  .ant-form-item, .ant-legacy-form-item {
    margin-bottom: 0 !important;
  }
}

.edit-icon-data {
  cursor: pointer;
  margin-left: 16px;
  margin-top: 0;
}

.edit-icon-data-small{
  cursor: pointer;
  margin-left: 7px;
  margin-top: 4px;
  align-self: center;
}

.edit-icon-blue{
  .anticon{
    color: #205FCF !important;
    font-size: 16px;
  }
}

.editable-text-settings {
  padding: 3px 12px;
  background: #F9F9F9;
  outline: none;
}

.save-button {
  width: unset !important;
  height: fit-content !important;
  border: 1px solid #276DD7 !important;
  background: rgba(39, 109, 215, 0.07);
  font-family: Manrope;
  border-radius: 4px !important;
  padding: 0 !important;
  color: #276DD7;
  &:hover {
    background:#205FCF !important;
    border: 1px solid #276DD7 !important;
    color: #ffffff !important;
    &.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
      background: rgba(39, 109, 215, 0.07);
      opacity: 0.5 !important;
      text-shadow: none;
      color: #276DD7;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
  &:focus {
    background:#205FCF !important;
    border: 1px solid #276DD7 !important;
    color: #ffffff !important;
  }
  &:active {
    background:#205FCF !important;
    border: 1px solid #276DD7 !important;
    color: #ffffff !important;
  }
  &.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
    background: rgba(39, 109, 215, 0.07);
    opacity: 0.5 !important;
    text-shadow: none;
    color: #276DD7;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  &.font-16 {
    font-size: 16px !important;
    line-height: 120%;
    padding: 10px 22px !important;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    vertical-align: middle;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    &.w-900 {
      font-weight: 900;
    }
  }

  &.okay {
    background: #276DD7 !important;
    color: white;
  }

  &.font-14 {
    font-size: 14px !important;
    line-height: 120%;
    padding: 6px 18px !important;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    vertical-align: middle;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    &.pd {
      padding: 13px 75px !important;
      @media (max-width: 1366px) {
        padding: 13px 25px !important;
        font-size: 12px !important;
      }
      @media (max-width: 1440px) {
        font-size: 14px !important;
        padding: 13px 50px !important;

      }
    }
  }

  &.font-12 {
    font-size: 12px !important;
    line-height: 120%;
    padding: 8px 14px !important;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    vertical-align: middle;
    display: flex;
    align-items: center;
    align-self: center;
    text-align: center;
    text-transform: capitalize;
    &.w-900 {
      font-weight: 900;
    }
  }
}

.existing-model-box {
  text-align: center;
  margin: auto;
  background: #FAFAFA;
  border: 1px dashed #A2A2A2;
  border-radius: 4px;
  padding: 24px 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.reference-photos{
  .ant-upload-list-picture-card-container {
    height: 155px;
    width: 155px;
  }
}
.app-model-label {
    background: #FAFAFC;
    border: 1px solid #E4E4E4;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.material-edit-item {
  white-space: break-spaces;
  label {
    margin-bottom: 0 !important;
  }
  .ant-form-item-label {
    width: 100%;
  }

  .ant-form-item-control {
    width: 100%;
  }
}


.background {
  border-radius: 4px;
  &.fafc {
    background: #FAFAFC;
    border: 1px dashed #E4E4E4;
  }
  &.light-blue {
    background: rgba(39, 109, 215, 0.06);
    border: 1px dashed #276DD7;
  }

  &.link {
    background: rgba(39, 109, 215, 0.08);
    border-radius: 4px;
  }
  &.pd-8 {
    padding: 8px;
  }

  &.pd-1 {
    padding: 20px;
  }
  &.pd-2 {
    padding: 80px 0;
  }
  &.pd-3 {
    padding: 12px 0;
  }

  &.pointer {
    cursor: pointer;
  }

  &.not-allowed{
    cursor: not-allowed;
    pointer-events: none;
  }

  &.flex-column {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &.flex-row {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
}

.modal-material-box-height{
  height: 225px;
}

.dashboard-img {
  object-fit: contain;
  height: 280px;
  opacity: 0.5;
  filter: blur(0px);
  &.material {
    object-fit: cover;
  }
}

.react-transform-component {
  margin: auto !important;
}

.upload-picture-card {
  .ant-upload-list {
    display: flex;
    flex-wrap: wrap;

    .ant-upload.ant-upload-select-picture-card {
      height: 160px !important;
      width: 160px !important;
      background: rgba(244, 244, 244, 0.5);
      border: 1px dashed #D8D7D7 !important;
      box-sizing: border-box;
      border-radius: 4px !important;
    }


    .ant-upload-list-picture-card-container {
      height: 160px !important;
      width: 160px !important;
      .ant-upload-list-item-progress {
        top: 90px !important;
      }
      .ant-upload-list-item {
        margin-top: 0 !important;
      }

      .ant-upload-list-picture-card .ant-upload-list-item-uploading.ant-upload-list-item {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .ant-upload-list-item-name {
          width: 90%;
        }

      .ant-upload-select {
        padding: 0 !important;
      }
    }

  }
}

.upload-picture-card-135 {
  .ant-upload-list {
    display: flex;
    flex-wrap: wrap;

    .ant-upload.ant-upload-select-picture-card {
      height: 135px !important;
      width: 135px !important;
      background: rgba(244, 244, 244, 0.5);
      border: 1px dashed #D8D7D7 !important;
      box-sizing: border-box;
      border-radius: 4px !important;
    }


    .ant-upload-list-picture-card-container {
      height: 135px !important;
      width: 135px !important;
      .ant-upload-list-item-progress {
        top: 90px !important;
      }
      .ant-upload-list-item {
        margin-top: 0 !important;
      }

      .ant-upload-list-picture-card .ant-upload-list-item-uploading.ant-upload-list-item {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .ant-upload-list-item-name {
          width: 90%;
        }

      .ant-upload-select {
        padding: 0 !important;
      }
    }

  }
}

.upload-picture-card-88 {
  width: 100%;
  .ant-upload-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    position: unset !important;
    .ant-upload.ant-upload-select-picture-card {
      height: 88px !important;
      width: 88px !important;
      background: rgba(244, 244, 244, 0.5);
      border: 1px dashed #D8D7D7 !important;
      box-sizing: border-box;
      border-radius: 4px !important;
      padding: 0px !important;
    }




    .ant-upload-list-picture-card-container {
      height: 89px !important;
      width: 88px !important;
      .ant-upload-list-item-progress {
        top: 50px !important;
      }
      .ant-upload-list-item {
        margin-top: 0 !important;
      }

      .ant-upload-list-picture-card .ant-upload-list-item-uploading.ant-upload-list-item {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .ant-upload-list-item-name {
          width: 90%;
        }

      .ant-upload-select {
        padding: 0px !important;
      }
    }

  }
}

.upload-picture-card-200 {
  .ant-upload-list {
    display: flex;
    flex-wrap: wrap;

    .ant-upload.ant-upload-select-picture-card {
      height: 200px !important;
      width: 272px !important;
      background: rgba(244, 244, 244, 0.5);
      border: 1px dashed #D8D7D7 !important;
      box-sizing: border-box;
      border-radius: 4px !important;
    }


    .ant-upload-list-picture-card-container {
      height: 200px !important;
      width: 272px !important;
      .ant-upload-list-item-progress {
        top: 90px !important;
      }
      .ant-upload-list-item {
        margin-top: 0 !important;
      }

      .ant-upload-list-picture-card .ant-upload-list-item-uploading.ant-upload-list-item {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .ant-upload-list-item-name {
          width: 90%;
        }

      .ant-upload-select {
        padding: 0 !important;
      }
    }

  }
}

.picture-card-custom {
  margin-bottom: 0 !important;
  .ant-form-item-control-input-content {
    line-height: 0 !important;
  }
}

.picture-card-custom-88 {
  display: flex !important;
  flex-wrap: nowrap !important;
  margin-bottom: 0 !important;
  .ant-form-item-control-input-content {
    line-height: 0 !important;
  }

  .ant-legacy-form-item-control {
    line-height: 0px !important;
  }

}

.bg {
  box-sizing: border-box;
  border-radius: 4px;
  &.red-ff {
    background: #FFD2CF;
    border: 1px solid #D93025;
  }
  &.grey {
    background: rgba(0, 0, 0, 0.04);;
    border: 1px solid rgba(0, 0, 0, 0.04);
    padding: 8px;
    width: fit-content;
  }

  &.pd-10-16 {
    padding: 10px 16px;
  }
}


#canvas-settings {
  max-width: 100%;
  max-height: 500px;
  background: #FAFAFC;
  overflow: hidden;
}

.availability-hours-select {
  width: 100%;
  margin-top: -15px;
  margin-bottom: -15px;
}

.availability-hours-select  .ant-select-selector{
  border-color: white !important;
  box-shadow: none !important;
  text-align-last: left;
}


.artist-settings-profile {
  margin-top: 25px;
  border-style: solid;
  border-color: #CCE2F9;
  border-radius: 8px;
  padding: 15px;
  border-width: 1.5px;
  background-color: #276DD70A;

}

.artist-personal-info {
  font-size: 16px;
  font-weight: 500;
  color: #555555;
  margin-bottom: 12px;
}

.upload-scanned-material{
  max-height: fit-content;
}

.upload-box{
  background-color: rgba(244, 244, 244, 0.5) !important;
  border: 1px dashed #d8d7d7 !important;
  border-radius: 4px !important;
  padding-bottom: 2px;
  text-align: center;

  &:hover {
    border: 1px dashed  #276DD7 !important;
    color: white !important;
  }
  .ant-upload-select {
    background-color: transparent !important;
  }
  .ant-upload.ant-upload-select {
    min-height: 0px !important;
    border: none!important;
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 0 !important;
    &:hover {
      border: none !important;
    }
  }

  .ant-upload-list-item-info {
    margin-top: 2px;
    margin-bottom: 2px;
  }
  .ant-upload-list {
    position: unset !important;
    margin: auto;
    width: 80% !important;
    margin-top: 5px;
    .ant-upload-list-item {
      margin-bottom: 32px;
      margin-top: 0px !important;
    }
  }
}

.upload-box-adjust-form-height{
  background-color: rgba(244, 244, 244, 0.5) !important;
  border: 1px dashed #d8d7d7 !important;
  border-radius: 4px !important;
  padding-bottom: 2px;
  text-align: center;

  &:hover {
    border: 1px dashed  #276DD7 !important;
    color: white !important;
  }
  .ant-upload-select {
    background-color: transparent !important;
  }
  .ant-upload.ant-upload-select {
    min-height: 0px !important;
    border: none!important;
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 0 !important;
    &:hover {
      border: none !important;
    }
  }

  .ant-upload-list-item-info {
    margin-top: 2px;
    margin-bottom: 2px;
  }
  .ant-upload-list {
    position: unset !important;
    margin: auto;
    width: 80% !important;
    margin-top: 25px;
    .ant-upload-list-item {
      margin-bottom: 32px;
      margin-top: 0px !important;
    }
  }
}

.material-download-option {
  display: flex;
  justify-content: flex-end;
  position: relative;
  right:200px;
  &.customer {
    right: 20px;
    position: absolute;
    top: 20px;
  }
  &.inspector{
    right:2px !important;
  }
}

.date-picker {
  font-family: Manrope;
  font-family: 14px;
  .ant-picker-large {
    padding: 10.5px 11px 10.5px;
  }
}

.date-footer {
  padding: 0 12px;
  line-height: 140%;
  text-align: left;
  margin-bottom: 10px;
  margin-top: 10px;
  color: #555555;
  font-size: 12px;
}

.onhover-underline:hover {
  text-decoration: underline;
}

.ant-picker-focused {
  border-color: #40a9ff !important;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);

}
.ant-picker:hover, .ant-picker-focused {
  border-color: #40a9ff !important;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2) !important;
}
.ant-picker-range .ant-picker-active-bar {
  background: #40a9ff !important;;
  background-color: #40a9ff !important;;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: #40a9ff !important;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background:#e6efff !important;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before, .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before, .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before, .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before, .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before, .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before, .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
  background: #b3d8ff !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background: #b3d8ff !important;
}

.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after, .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  background: #b3d8ff !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid #1890ff !important;
}

.ant-picker-header-view button:hover {
    color: #1890ff !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
  border-top: 1px dashed #7ec1ff !important;
  border-bottom: 1px dashed #738da5 !important;
}

tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after, tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after, .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  border-right: 1px dashed #7ec1ff !important;
}

tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after, tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after, .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
  border-left: 1px dashed #7ec1ff !important;
}


.count-bg {
  background: #FAFAFC;
  border: 1px solid #E4E4E4;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 20px 28px;
}

.sample-image-popup {
  width: 280px;
  height: 280px;
  &.contain {
    width: 100%;
    height: 280px;
    object-fit: contain;
  }
}


.note-w-border {
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 8px;
  background: rgba(39, 109, 215, 0.06);
  border: 1px solid rgba(39, 109, 215, 0.32);
  font-family: Manrope;
  &.font-14{

    font-size: 14px;
  }
  &.font-12{

    font-size: 12px;
  }
}
.download-model {
  width: 160px;
  height: 160px;

  background: #FAFAFA;

  border: 1px solid #9F9F9F;
  box-sizing: border-box;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover {
    border: 1px solid #276DD7;;
  }
}

.artist-details-heading {
  font-size: 14px;
  font-weight: 500;
  margin: 10px 0px;
  color: #555555;
}

.artist-details-info {
  margin: 0px;
  font-size: 14px;
  font-weight: 500;
  color: #141414;
}


.modal-okay-green {
  width: unset !important;
  height: fit-content !important;
  border: 1px solid #25d6a4 !important;
  background: #25d6a4 !important;
  border-radius: 35px !important;
  padding: 0 !important;
  color: white;
  &.round {
    border-radius: 50px !important;
    padding: 6px 10px !important;
  }
  &.square {
    border-radius: 4px !important;
    &:hover {
    background: #68c394 !important;
    border: 1px solid #25d6a4 !important;
    color: #ffffff !important;
  }
  }
  &:hover {
    background: #68c394 !important;
    border: 1px solid #25d6a4 !important;
    color: #ffffff !important;
  }
  &:focus {
    border: 1px solid #25d6a4 !important;
    background: #68c394 !important;
    color: white !important;
  }
  &:active {
    border: 1px solid #25d6a4 !important;
    background: #68c394 !important;
    color: white !important;
  }
  &.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
    background: #25d6a4 !important;
    opacity: 0.5 !important;
    text-shadow: none;
    color: white !important;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  &.square {
    border-radius: 4px !important;
      &:hover {
      background:#25d6a4 !important;
      border: 1px solid #25d6a4 !important;
      color: #ffffff !important;
      }
    &:focus {
      border: 1px solid #25d6a4 !important;
      background:#68c394 !important;
      color: white;
    }

    &:active {
      border: 1px solid #25d6a4 !important;
      background: #68c394 !important;
      color: white;
    }

    &.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
      background: #25d6a4 !important;
      opacity: 0.5 !important;
      text-shadow: none;
      color: white !important;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    &.ant-tooltip-disabled-compatible-wrapper {
      background: #25d6a4 !important;
      opacity: 0.5 !important;
      text-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
    &.font-16 {
    font-size: 16px !important;
    line-height: 120%;
    padding: 12px 24px !important;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    vertical-align: middle;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    &.w-900 {
      font-weight: 900;
    }
  }
  &.font-12 {
    font-size: 12px !important;
    line-height: 120%;
    padding: 12px 25px !important;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    vertical-align: middle;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    &.small {
      padding: 12px 15px !important;
    }
  }
  &.font-18 {
    font-size: 18px !important;
    line-height: 120%;
    padding: 16px 32px !important;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    vertical-align: middle;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    &.w-900 {
      font-weight: 900;
    }
  }
  &.font-14 {
    font-size: 14px !important;
    line-height: 120%;
    padding: 11px 32px !important;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    vertical-align: middle;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
  }
}

.payment-modal-border {
  flex-direction: row;
  padding: 12px 16px;
  position: static;
  width: 100%;
  background: #F8F8FB;
  border-radius: 4px;
  &.full {
    width: 100%;
    &.details{
      padding: 12px 0px 12px 16px !important;
    }
    &.price-wrapper{
      padding: 10px 30px;
      display: flex;
    }
  }
  &.max-content {
    width: max-content;
  }
}

.price-wrapper-payment{
  margin-top: 5px;

  .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header{
    padding: 0px ;
    padding-right: 32px ;
  }
  .ant-collapse{
    border: 0px solid black;
  }
  .ant-collapse > .ant-collapse-item{
    border-bottom: 0px ;
  }
  .ant-collapse-content > .ant-collapse-content-box{
    padding: 0;
  }
  .ant-collapse-content{
    border: 0px;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg{
    color:#276DD7;
    transform: rotate(90deg)
  }
  .ant-collapse > .ant-collapse-item-active > .ant-collapse-header .ant-collapse-arrow svg{
    color:#276DD7;
    transform: rotate(-90deg) !important
  }
}

.important-width {
  width: 100% !important;
}

.select-wo-margin {
  margin-bottom: 0px !important;
}

.bedding-img {
  width: 88px;
  height: 88px;
  border-radius: 4px;
  &.bedding-160 {
    width: 160px;
    height: 160px;
  }
  &.image-150 {
    width: 150px;
    height: 100px;
    object-fit: contain;
  }
}

.bedding-card {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  .ant-card-body {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
  }
  &.center {
    align-items: center;
  }
}

.center-on-screen {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.material-position {
  position: absolute;
  bottom: 20px;
  left: 0;
}

.store-search-bar {
  // background: #F4F4F4;
  padding: 20px 0px;
}

.space-card-img{
  object-fit: cover;
  width: 100%;
  height: 275px;
  z-index: 1;
  &.h-250 {
    height: 250px;
  }
}


.product-card-img {
  object-fit: contain;
  height: 275px;
  z-index: 1;
}

.tag-filter {
  margin-bottom: 6px;
  background: rgba(0, 0, 0, 0.06) !important;
  border-radius: 4px !important;
  padding: 12px !important;
  .ant-tag {
    background: rgba(0, 0, 0, 0.06) !important;
    border-radius: 4px !important;
    padding: 12px !important;
  }
}
.bg-color {
  &.light-blue {
    padding: 8px;
    background: #FAFAFC;
    border-radius: 4px;
    width: fit-content;
  }
}

.stacked-card {
  position: relative;
  cursor: pointer;
  max-width: 400px;
  margin: 20px 0;
}

.stacked-card::before,
.stacked-card::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.stacked-card::before,
.stacked-card::after,
.stacked-card .stacked-card-inner {
  background-color: #fff;
  border: 1px solid #E2E2E2;
  border-radius: 5px;
  transition: transform 0.3s;
}

.stacked-card::before,
.stacked-card-inner {
  z-index: 1;
}

.stacked-card-inner {
  position: relative;
  padding: 0rem;
}


.stacked-card-top-right::before {
  transform: translate(8px, calc(-1 * 8px));
}

.stacked-card-top-right::after {
  transform: translate(16px, calc(-1 * 16px));
}

.stacked-card-top::before {
  transform: translate(0px, calc(1 * 0px));
}

.stacked-card-top::after {
  transform: translate(8px, calc(-1 * 10px));
}

.stacked-card-top-right:hover::before,
.stacked-card-top-right:hover::after,
.stacked-card-top-right:hover .card-inner {
  // transform: translate(8px, 0);
  background-color: #F5F9FF;
  border: 1px solid #DBE7F9;
}

.stick-buttons {
  // bottom: 0px;
  padding: 24px 5%;
  position: static;
  background: #FFFFFF;
  box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.08);
}

.card-margins {
  margin-right: 16px;
  margin-bottom: 16px;
  margin-top: 10px;
  &.stack {
    margin-right: 36px;
    margin-bottom: 20px;
    margin-top: 20px;
    padding-top: 0px;
  }

}

.model-output-card {
  padding: 16px;
  width: 33%;
  white-space: break-spaces;
}

.image-radius {
  border-radius: 4px;
  width: 100%;;
}

.justify-space-start {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  &.wrap {
    flex-wrap: wrap;
  }
}

.bg-color {
  &.color-6b {
    background-color: #FBFBFB;
  }
}

.homepage-panel {
  background: #F0f0f0;
  border: 1px solid #F0f0f0;
  padding: 12px;
  border-radius: 4px;
}

.dimension-pos {
  position: absolute;
  right: 12px;
  bottom: 12px;
  // display: none;
}

.time-clock-bg {
  background: #f4f4f4;
  width: fit-content;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  &.text-bg {
    position: absolute;
    bottom: 10px;
    left: 20px;
    color: white;
    opacity: 0.9;
    margin: 0;
    padding: 8px;
    background: #3c3636;
    color: white;
  }
}

.form-labels {
  .ant-form-item-label > label {
    height: unset !important;
  }
}


.centered-popup {
  text-align: center;
}

.center-popup-text {
  width: 68%;
  text-align: justify;
}


.image-subscription {
  width: 300px;
  height: 236px;
}

.explore-img {
  width: 200px;
  height: 180px;
  object-fit: cover;
  cursor: pointer;
  .ant-card-body {
    width: 100%;
  }
}

.carousel-help {
  width: 100%;
  object-fit: contain;
  height: 100%;
}

.tour-modal {
  width: 80% !important;
}

.tour-explore-modal {
  width: 67% !important;
}

.logo-container {
  padding: 10px;
  background-color: #F8F6F1;
  border-radius: 4px;
  width: 180px;
  height: 80px;
  cursor: pointer;
}

.logo-image {
  object-fit: contain;
  width: 70px;
  height: 50px;
  margin-right: 10px;
}

.carousel-container-store {
  width: 100%;
  // padding: 0px 5%;
  margin-bottom: 20px;
  .react-multiple-carousel__arrow--right {
    position: absolute;
    right: 0;
    z-index: 1;
  }
  .react-multiple-carousel__arrow--left {
    position: absolute;
    left: 0;
    z-index: 1;
  }
}

.radio-btn-header {
  &.ant-radio-button-wrapper {
    font-family: 'Manrope';
    height: auto;
    padding: 10px 12px !important;
    margin: 0;
    color: #6B6B6B;;
    background: rgba(0, 0, 0, 0.06);
    font-size: 14px;
    line-height: 14px;
  }
  &.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: #276DD7 !important;
    border-color: #276DD7 !important;
    &:hover {
      border-color: #276DD7 !important;
      background: #276DD7 !important;
    }
  }
  &.ant-radio-button-wrapper-checked {
    background: #276DD7 !important;
    border-color: #276DD7 !important;
    &:hover {
      border-color: #276DD7 !important;
      background: #276DD7 !important;
    }
  }
}

.switch-bg {
  background: white;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 4px;
}

.breadcrumb-settings {
  margin-top: 10px;
  margin-bottom: 10px;
}

.ant-breadcrumb > span:last-child a {
  color: #276DD7 !important;
}

.banner-style {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
}

.store-row {
  background:#F4F4F4;
  padding:10px 5%;
}

.banner-container {
  width: 100%;
  height: 100%;
  // max-height: 219px;
  max-height: 100%;
}

.logo-website-container {
  left: 2%;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  right: 0;
  top: 0;
  bottom: 0;
}

.website-container {
  margin-top: 10px;
}

.logo-placement {
  // height: auto;
  height: 120px;
  object-fit: contain;
  object-position: left;
}

.logo-banner{
  width: 100%;
  height: max-content;
  object-fit: contain;
}

.brand-container {
  margin: 0 5%;
}


.radio-header {
  width: max-content;
}

.position-abs-icon {
  font-size: 20px;
}

.right-coll-icon {
  position: absolute;
  bottom: -25px;
  font-size: 16px;
  margin-bottom: 7px;
}

.left-coll-icon {
  position: absolute;
  bottom: -25px;
  font-size: 16px;
  writing-mode: vertical-lr;
  border-radius: 0 4 4 0;
  margin-bottom: 7px;
}

.sell-on-store-pd {
  padding: 40px;

}

.group-items-modal.ant-modal {
  width: fit-content !important;
}

.group-product-image > span > img:hover {
  filter: invert(1) sepia(1) saturate(5) hue-rotate(175deg) !important
}

.grouped-row {
  &.f6cfd1 > td {
    background-color: #f6cfd1 !important;
  }
  &.eef2f8 > td{
    background-color: #eef2f8 !important;
  }
  &.e9d0e0 > td{
    background-color: #e9d0e0 !important;
  }
}

.sell-on-store {
  padding: 0 !important;
  .ant-modal-content {
    .ant-modal-footer {
      text-align: center;
      padding: 24px !important;
      padding-top: 0px !important;
      border-top: none !important;
    }
  }
}

.company-logo-upload-txt {
  .ant-upload-select-text {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.banner-preview-card {
  padding: 20px;
  .ant-card-body {

  }
}
.artist-menu-icon{
  margin-bottom: 20px !important;
  .manrope{
    .f-14{
      position: absolute;
      top:12px
    }
  }
}


.hide-fit-text {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}
.modal-non-ar{
  position: absolute;
  top:30%;
  left:30%
}

.remove-antd-margin {
  &.ant-legacy-form-item {
    margin-bottom: 0px !important;
  }
}

.step-class {
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: #1890ff;
    border-color: #1890ff ;
  }
  .ant-steps-item-finish .ant-steps-item-icon {
    border-color: #009D19;
  }
  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #009D19;
  }
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: #009D19;
  }
}

.scroller-style {
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  flex-wrap: wrap;
  &.align-center {
    justify-content: center;
  }
}

.tagging-loader {
  position: absolute;
  left: 50%;
  top: 30%;
  font-size: 32px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tagging-img-card {
  .ant-card-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.restrict-height {
  padding: 0% 5% 0 5%;
  overflow-y: scroll;
  height: 500;
  width: 100%;
}

.fix-buttons {
  position: fixed;
  display: flex;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: row;
  width: calc(100vw);
  background: #FFFFFF;
  padding: 20px 5% 10px 7%;
  box-shadow: 0px -4px 24px rgba(0, 0, 0, 0.08);
}

#product_info_group_id:disabled {
  color: rgba(0,0,0,.25);
  background: #f5f5f5;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: rgba(0,0,0,.25);
  background: #f5f5f5;
  cursor: not-allowed;
}

.no-models-span-modal{
 padding: 12px;
 position: absolute;
 top: 10%;
 left:42%;
}

.no-models-span{
  padding: 12px;
  position: absolute;
  top: 46%;
  left:43%;
 }

.validation-uploaded-model-success-div .ant-card-bordered{
  border: 1px solid #29B862
}

.validation-uploaded-model-success-div .ant-card-body, .validation-uploaded-model-warning-div .ant-card-body, .validation-uploaded-model-rejection-div .ant-card-body{
  padding: 16px 24px !important;
}

.validation-uploaded-model-header-success, .validation-uploaded-model-success-div .anticon-check-circle{
  color: #29B862;
  margin-right: 4px;
}

.validation-uploaded-model-warning-div .ant-card-bordered{
  border: 1px solid #E19B12;
  background : #FEFBF6
}

.validation-uploaded-model-header-warning, .validation-uploaded-model-warning-div .anticon-info-circle{
  color: #E19B12;
  margin-right: 4px;
}

.validation-uploaded-model-rejection-div .ant-card-bordered{
  border: 1px solid #E54547;
  background : #FFFAFA
}

.validation-uploaded-model-header-rejection, .validation-uploaded-model-rejection-div .anticon-close-circle{
  color: #E54547;
  margin-right: 4px;
}

.step-class {
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: #1890FF;
    border-color: #1890FF ;
  }
  .ant-steps-item-finish .ant-steps-item-icon {
    border-color: #009D19;
  }
  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #009D19;
  }
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: #009D19;
  }
}

.position-empty-data {
  position: absolute;
  left: 0;
  right: 0;
  top: 35%;
  bottom: 0;
}

.lifestyle-border {
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.render-checkbox-img {
  height: 164px;
  object-fit: cover;
  width: 100%;
}

.render-checkbox {
  .ant-checkbox {
    padding-top: 16px;
    padding-left: 16px;
  }
  .ant-checkbox + span {
    padding: 0px !important;
  }
  .ant-checkbox-checked::after {
    border: none !important;
  }
}

.template-modal-setting {
  width: 70% !important;
  &.small {
    width: 50% !important;
  }
}

.approve-modal {
  width: 50% !important;
  top: 35% !important;
}

.complaint-card{
  .ant-card-body{
    width: 100%;
  }
}

.complaint-card-details{
  border-bottom:0.01px solid #E3E3E3;
  width: 100%;
  padding-top: 12px;
}

.display-flex-row{
  display: flex;
  flex-direction: row;
}

.lifestyle-image-settings-custom {
  cursor:grab;
  width: 100%;
  height: 100%;
  // height: 500px;
  object-fit: contain;

  &.fill{
    object-fit: fill !important;
  }

  &.h-100{
    height: 100px !important;
  }

  &.h-150{
    width: 16% !important;
    height: 150px !important;
  }
  &.h-250{
    height: 250px !important;
  }

}

.ant-checkbox-wrapper > span > span > .skill-matrix-tooltip {
  display: none
}

.complaint-status{
  padding-left:15px;

  &.green{
    color: #29B862;
  }
  &.yellow{
    color: #E19B12;
  }
  &.red{
    color: red;
  }
}

.complaint-renders{
  width:-webkit-fill-available;
  // height: 100%;
  height: 381px;
  padding: 15px;
  margin:15px 10px;
  border:1px solid #E3E3E3;
  &.flex{
    display: flex;
    flex-direction: column;
    justify-content:center;
  }
  &.margin-right-unset{
    margin-right: 10px !important;
    margin-left: 0px !important;
  }
  &.no-padding-margin{
    margin-bottom: 15px !important;
    margin-right: 10px !important;
    padding: 0px;
  }

}

.set-svg-dims{
  svg{
    width: -webkit-fill-available  !important;
    height: 100px  !important;
    color: #29B862 !important
  }
}
.in-progress-paddings{
  padding-bottom: 15px;
  padding-right: 15px;
  height: 100%;
  width: -webkit-fill-available;
  &.flex{
    padding: 15px;
    margin:15px 10px;
    display: flex;
    flex-direction: column;
    justify-content:center;
  }
}

.reference-files{
  margin: 8px;
  padding: 15px;
  border: 1px solid #E3E3E3;

  &.no-left-margin{
    margin: 8px 8px 8px 0px;
    padding: 15px;
    border: 1px solid #E3E3E3;
  }
}

.centre-in-screen{
  margin-top: 16vh;
  margin-bottom: 32%;
  &.m-top-24{
    margin-top: 24vh !important;
  }
  &.m-bottom-40{
    margin-bottom: 40px !important;
  }
  &.m-left-32{
    margin-bottom: unset !important;
    margin-left: 32%;
  }

}

.reorder-icon-container{
  display: flex; /* establish flex container */
  flex-direction: column; /* make main-axis vertical */
  justify-content: center; /* align items vertically, in this case */
  align-items: center; /* align items horizontally, in this case */
  height: 45px;
  width: 94%;
  background: rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.08);
}

.reorder-icon-container:hover{
  border: 1px solid black;
  cursor: pointer;
}

.icon-button-container{
  display: flex; /* establish flex container */
  flex-direction: column; /* make main-axis vertical */
  justify-content: center; /* align items vertically, in this case */
  align-items: center; /* align items horizontally, in this case */
  height: 40px;
  width: 40px;
  background: #ffffff !important;
  border-radius: 4px;
  border: 1px solid #276DD7 !important;
  .anticon {
    color: #276DD7 !important;
    font-size: 18px;
    line-height: 18px;
  }
  .reorder-icon {
    stroke: #276DD7 !important ;
  }
  &.mr-8 {
    margin-right: 8px;
  }
  &.ml-8 {
    margin-left: 8px;
  } 
}

.icon-button-container:hover{
  background: #276DD7 !important;
  cursor: pointer;
  .anticon{
    color: #ffffff !important;
  }
  .reorder-icon{
    stroke: #ffffff !important ;
  }
}

.red-icon-button-container{
  display: flex; /* establish flex container */
  flex-direction: column; /* make main-axis vertical */
  justify-content: center; /* align items vertically, in this case */
  align-items: center; /* align items horizontally, in this case */
  height: 40px;
  width: 40px;
  background: #ffffff !important;
  border-radius: 4px;
  border: 1px solid #D2281F !important;
  &.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
    text-shadow: none;
    opacity: 0.5 !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    cursor: not-allowed;
    color: #D2281F !important;
  }
  &:hover{
    background: #D2281F !important;
    cursor: pointer;
    .anticon{
      color: #ffffff !important;
    }
    .reorder-icon{
      stroke: #ffffff !important ;
    }
  }
  .anticon {
    color: #D2281F !important;
    font-size: 18px;
  }
  .reorder-icon {
    stroke: #D2281F !important ;
  }
  &.mr-8 {
    margin-right: 8px;
  }
}

.fixed-row-2{
  z-index: 2;
  position: fixed;
  right: 18%;
  left: 0;
  bottom: 0;
  flex-direction: row;
  width: 100%;
  background: #FFFFFF;
  padding: 20px 40px;
  height: 90px;
  box-shadow: 0px -4px 24px rgba(0, 0, 0, 0.08);

  .row-margins-2 {
    width: 100%;
    margin-top: 15px;
    margin-right: 0px;
    margin-left: 0%;
    display: flex;
    justify-content: flex-end;
  }
}

.draggable-div {

//  display: block;
}

.draggable-element {
  // float: left;
  // padding-left: 8px;
  // padding-right: 8px;
  // width: calc(25% - 16px);
  // height: 150px;
}
.dimensions-col{
  padding: 10px 24px !important;
  display: grid !important;
}

.dimensions-span{
  margin-bottom: 4px;
}

.selection-popup {
  .ant-select-selection-search {
    width: 100vw !important;
  }
  .ant-select-selection, .ant-select-selector {
    min-height: 40px !important;
    height: auto !important;
  }
}

.side-drawer-checkbox {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 5px;
  margin-top: 5px;
  &.wrap {
    flex-wrap: wrap;
  }
  &.align-center {
    align-items: center;
  }
  &.checkbox-margin {
    input[type=checkbox] {
      margin: 0px;
    }
  }
}

.side-drawer-card {
  margin-top:0%;
  margin-left:5%;
  position: absolute;
  height:100%;
  width: 90%;
  display: flex;
  flex-flow: column wrap;
  @media screen and (max-height: 600px){
    max-height: 600px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.side-drawer-heading {
  position: relative;
  display: flex;
  font-family: Manrope;
  font-style: normal;
  font-weight: 600;
  font-size: 0.9vw;
  color: #737373;
  height:fit-content;
  padding-top:2%;
  padding-bottom: 2%;
  justify-content: space-between;
}

.side-drawer-element {
  position: absolute;
  margin-top:10%;
  width:100%;
  height:80%;
}

.side-drawer-buttons-group {
  position: absolute;
  margin-top: 7%;
  width:100%;
}

.side-drawer-button {
  position: relative;
  justify-content: space-between;
  align-items: center;
  width: 100% !important;
  height: 6vmin !important;
  font-family: Manrope;
  font-size:2vmin;
  border: solid !important;
  border-width: 1px !important;
  background: transparent !important;
  border-radius: 3px !important;
  padding-top: 1vmin !important;
  padding-bottom: 1vmin !important;
  margin-left:1vmin !important;
  margin-right:1vmin !important;
  color: #737373 !important;
  margin-top:0% !important;
  top:0%;
  &:hover {
    color: #276DD7 !important;
  }
  &.upload-button {
    position: absolute;
    left: 0;
    color: #276DD7 !important;
    margin-left:0px !important;
  }
  &.okay-button {
    height: 5vmin !important;
    padding-top: 0.5vmin !important;
    padding-bottom: 0.5vmin !important;
    border: none !important;
    background: #276DD7 !important;
    color: white !important;
  }
  &.cancel-button {
    height: 5vmin !important;
    padding-top: 0.5vmin !important;
    padding-bottom: 0.5vmin !important;
    border: none !important;
    background: #D93025 !important;
    color: white !important;
  }
}

.side-drawer-note-text {
  position: relative;
  font-size: 0.8vw;
  height:fit-content;
  padding-top:1vh;
  padding-bottom:1vh;
  margin-left: 10px;
  .bold {
    font-weight: 900;
  }
}

.side-drawer-reset-button {
  margin-right: 10px;
  color:#276DD7;
  cursor: pointer;
}

.align-icon {
  padding-right: 5px;
  height: 1.2vw;
  padding-top:0.2vw;
  &.center {
    position: relative;
    height: 25px;
    margin-left: 5px;
    margin-bottom: 5px;
  }
  &.right{
    height: 22px;
    margin-bottom: 8px;
    margin-left: 2px;
  }
  &.fixed-height {
    height: 18px;
    padding-top: 1px;
  }
  &.medium-size {
    height: 22px;
    padding: 2px;
    margin-left: 2px;
  }
}

.side-drawer-menu-button {
  border-radius: 2px;
  border-color:#c4c4c4;
  &:hover{
    border-color: #276DD7 !important;
  }
}

.side-drawer-menu-icon {
  &.left-align  {
    margin-left:1vmin !important;
  }
  &.small {
    width: 2vw;
    height: 2vw;
  }
}

.side-drawer-text {
  position: relative;
  font-size: 1.8vmin;
  margin-top:0%;
  margin-bottom: 0px;
  height:fit-content;
  &.large {
    font-size: 1.8vmin;
    margin-left: 1vmin;
    color:#276DD7
  }
  &.medium {
    font-size: 16px;
    margin-left: 1vmin;
    color:#276DD7
  }
  &.disabled {
    color: #888888;
    font-weight: bold;
  }
  &.disabled-thin {
    color: #888888;
  }
}

input[type=checkbox]:not(:checked) {
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  border: 1px solid #276DD7;
  border-radius: 2px;
  width: 1vw;
  height: 1vw;
}

input[type=checkbox]:checked {
  width: 1vw;
  height: 1vw;
}

input[type=checkbox]:disabled {
  outline: none;
  border: 1px solid #888888;
  border-radius: 2px;
  width: 1vw;
  height: 1vw;
}

.side-drawer-lib-wrapper {
  position: absolute;
  height: 16vh;
  width: 100%;
  margin-top:1%;
  @media screen and (max-height: 850px){
    height: 14vh;
  }
  @media screen and (max-height: 800px){
    height: 13vh;
  }
  @media screen and (max-height: 780px){
    height: 11vh;
  }
  @media screen and (max-height: 650px){
    height: 9vh;
  }
}

.side-drawer-lib {
  display: flex;
  justify-content: flex-start !important;
  flex-wrap: wrap;
  position: relative;
  height: 100%;
  width: 100%;
  &.justify-center {
    display: block;
    padding-left: 10px;
    margin-top: 0%;
  }
}

.side-drawer-card-img {
  width: 8vw;
  height:5.5vw;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}

.side-drawer-card-text {
  height: 0.8vw;
  font-size: 0.6vw;
  padding-top: 0px;
  padding-left: 10px;
  padding-bottom: 10px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
  overflow:hidden;
  width: fit-content;
  max-width: 8vw;

}

.overflow-hidden {
  overflow: hidden
}

.position-relative {
  position: relative;
}

.size-fit-content {
  width: fit-content;
  height: fit-content;
}


.size-drawer-upload-box {
  position: relative;
  background-color: transparent !important;
  text-align: center;
  height: 6vmin !important;
  width: 100% !important;
  margin-top:0;
  .ant-upload.ant-upload-select {
    background-color: transparent !important;
    min-height: 0px !important;
    border: none!important;
  }
}

.side-drawer-slider-wrapper {
  margin-top:0%;
  position: relative;
  width:100%;
}

.side-drawer-loader-img {
  text-align: center;
  font-size:24px;
  position: absolute;
  top: 40%;
  left:40%;
}


.side-drawer-slider {
  width: 10vw;
}

.side-drawer-slider-input {
  width: 0.5vmin;
  margin-left: 11vw;
  position: absolute;
}


.side-drawer-input-fov {
  font-family: Manrope;
  font-style: normal;
  font-weight: normal;
  font-size: 1vw;
  color: #555555;
  display: flex;
  background: #fafafa;
  align-items: baseline;
  width: 5vw;
}
.z-1 {
  z-index: 1;
}
.fiu-selection-card {
  .ant-card-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.fiu-text {
  margin: 6px;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  width: unset;
  max-width: 100%;
}

hr {
  margin: 5px 0 10px 0;
}
.p-0{
  padding: 0;
}

.p-20{
  padding: 20px;
}

.m-0{
  margin: 0;
}

.mt-12 {
  margin-top: 12px;
}

.mt-5 {
  margin-top: 5px;
}


.mb-0{
  margin-bottom: 0px;
}

.mb-4{
  margin-bottom: 4px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-16 {
  margin-bottom: 16px;
}

.pd-10 {
  padding: 10px;
}
.mb-49 {
  margin-bottom: 49px;
}

.pd-16 {
  padding: 16px;
}

.pd-30 {
  padding: 30px;
}

.pd-8 {
  padding: 8px;
}


.pd-12 {
  padding: 12px;
}

.p-b-32 {
  padding-bottom: 32px;
}

.ml-16 {
  margin-left: 16px;
}

.ml-8 {
  margin-left: 8px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-32 {
  margin-left: 32px;
}


.ml-4 {
  margin-left: 4px;
}

.ml-100 {
  margin-left: 100px;
}

.ml-168 {
  margin-left: 168px;
}


.ml-50 {
  margin-left: 50px
}

.ml-5 {
  margin-left: 5px;
}

.ml-6 {
  margin-left: 6px;
}

.ml-0 {
  margin-left: 0px;
}

.ml-12 {
  margin-left: 12px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-28 {
  margin-bottom: 28px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30{
  margin-bottom: 30px;
}

.mb-40{
  margin-bottom: 40px;
}

.mb-60{
  margin-bottom: 60px;
}


.mb-90{
  margin-bottom: 90px;
}

.mt-0 {
  margin-top: 0px;
}

.mb-0 {
  margin-bottom: 0;
}

.mt-10 {
  margin-top: 10px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-8 {
  margin-top: 4px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-20{
  margin-top: 20px;
}

.mt-40{
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-4 {
  margin-top: 4px;
}

.mr-0 {
  margin-right: 0px;
}

.mr-2 {
  margin-right: 2px;
}

.mr-4 {
  margin-right: 4px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-8 {
  margin-right: 8px;
}

.mr-10{
  margin-right: 10px;
}

.mr-12{
  margin-right: 12px;
}

.mr-14{
  margin-right: 14px;
}

.mr-16{
  margin-right: 16px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-80 {
  margin-right: 80px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-2 {
  margin-left: 2px;
}

.w-100 {
  width: 100%;
  &.important {
    width: 100% !important;
  }
}

.w-80{
  width: 80%;
  &.important {
    width: 80% !important;
  }
}

.w-64{
  width:64%;
}

.w-50 {
  width: 50%
}

.w-49 {
  width: 49%
}

.w-32 {
  width: 32%
}

.w-16 {
  width: 16%
}

.float-right {
  float: right;
}

.overflowY-scroll{
  overflow-y: scroll;
}

.overflowX-scroll{
    overflow-x: scroll;
}

.br-top-4 {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.br-bottom-4 {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.text-grey{
  color: #666666;
}

.input-number-test-admin{
  height: 48px;
  margin-bottom: 10px;
}

.input-number-test-admin>.ant-input-number{
  height: 100% !important;
  width: 100% !important;
}
.dimensions-col{
  padding: 10px 24px !important;
  display: grid !important;
}

.dimensions-span{
  margin-bottom: 4px;
}

.selection-popup {
  .ant-select-selection-search {
    width: 100vw !important;
  }
  .ant-select-selection, .ant-select-selector {
    min-height: 40px !important;
    height: auto !important;
  }
}

.clipping-switch {
  position: absolute;
  right: 10%;
}

.clipping-slider {
  width: 60%;
}

.clipping-switch-div {
  display: flex;
  margin-top: 5% !important;
  justify-content: space-between;
  padding-left: 14px;
}

.clipping-setting-div {
  display: flex;
  margin-top: 5% !important;
  padding-left: 16px;
  padding-right: 30px;
  justify-content: space-between;

  &.align-center {
    align-items: center;
  }
}

.clipping-number-input {
  width: 30%;
  margin-right: 5%;
}

.clipping-switch-div button.ant-switch.clipping-switch.ant-switch-checked {
  background-color: #276dd7 !important;
}

.clipping-heading {
  margin : 1%;
}

.clipping-inches-text {
  justify-content :flex-end;
  align-items:center;
  display:flex;
  position:absolute !important;
  font-size:1vh !important;
  left: 76% !important;
  bottom: 43% !important
  ;
}

.render-override-settings {
  position:relative;
  text-align: left;
  margin-top: 20px;
  margin-left: 10px;
}

.render-override-checkbox {
  color:#276DD7;
  margin-right: 10px;
}

.silo-tool-step-container {
  width: 100vw;
  height: 10vh;
  padding: 3vh 4vw;
  background: #FFFFFF;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-silo-container{
  height: calc(90vh - 120px);
  display: flex;
  justify-content: center;
}

.background-f4f4f4 {
  background: #F4F4F4;
}

.background-fafafa{
  background: #FAFAFA;
}

.background-ffffff {
  background: #FFFFFF;
}

.set-angles-header {
  margin: 0.5% 0% 1% 1%;
  display: flex;
}

.silo-add-camera-resolution {
  display: flex;
  margin-left: auto;
}

.add-custom-angle-silo-button {
  position: absolute;
  right: 3%;
}

.margin-0 {
  margin: 0px;
}

.padding-14 {
  padding: 14px
}

.dropdown-resolution {
  padding: 1px 12px;
  border: 1px solid #BFBFBF;
  border-radius: 4px !important;
  background: #FFFFFF;
  cursor: pointer;
  &.rounded{
    border-radius: 100px !important;
    background: transparent ;
  }
}

.image-setting-header{
  margin:8px;
}

.silo-setting-individual {
  margin-top: 6px;
  margin-left: 8px;
}


#controls-row-silo {
  display: flex;
}

.zoom-silo-span {
  cursor: pointer;
  margin: 0px 15px;
  place-self: center;
}

.zoom-silo-settings {
  position: relative;
  left: 29vw;
  display: flex;
  height: 34px;
  border: 1px #d4d4d4 solid;
}

.silo-snapshot-settings-buttons {
  display:flex;
  position:absolute;
  right: 1vw;
}
.material-variation-model-viewer-tags {
  position:absolute;
  font-size:15px;
  padding: 8px 8px 8px 8px;
  border-radius: 2.5px;
  border: 1px solid black;
  cursor: pointer;
}

#material-variation-model-viewer {
  border: 1px solid #e4e4e4;
  width: 100%;
  height: 600px;
}

.material-variation-category-container {
  display:flex;
  margin-top: 25px;
  justify-content:space-evenly;
}

.material-variation-category-tabs {
  display: flex;
  justify-content: space-between;
  width: 49%;
  height: 3%;
  padding : 5px 5px 5px 10px;
  border-radius:5px;
  border: 2px solid #e4e4e4;
}

.material-variation-label-instructions{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius:5px;
  background-color: rgb(211, 211, 211);
  border: 2px solid #e4e4e4;
  padding:10px 10px 10px 10px;
  height:100px;
}

.material-canvas-image {
  height:110px;
  width:110px;
  border-radius: 4px;
  @media (max-width: 1450px) {
    height:80px;
    width:80px;
  }
  @media (max-width: 1200px) {
    height:60px;
    width:60px;
  }
}

.p-10 {
  &.p-right {
    padding-right: 10px;
  }
  &.p-left {
    padding-left: 10px;
  }
  &.p-bottom {
    padding-bottom: 10px;
  }
  &.p-all {
    padding: 10px 10px 10px 10px
  }
}

.p-20 {
  &.p-all {
    padding: 20px 20px 20px 20px
  }
}

.scroll-bottom {
  border-radius: 18px;
  position: fixed;
  top: calc( 75% - 120px ) ;
  background: rgba(0, 0, 0, 0.4) !important;
  color: #FFFFFF;
  left: 85%;
  z-index: 2;
  &:hover {
    color: #FFFFFF !important;
    border-color: white;
  }
}


.justify-flex {
  &.space-evenly {
    justify-content: space-evenly
  }
}

.checklist-modal {
  padding: 0 !important;
  .ant-modal-content {
    .ant-modal-footer {
      padding: 0 !important;
      text-align: center;
      padding: 0px 30px 30px 30px !important;
      border-top: none !important;
    }
  }
}

.checklist-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px 20px 20px 50px;
}

.upload-overlay {
  position:absolute;
  width: 96% !important;
  left: 0;
  right: 0;
  bottom: 10;
  margin: auto;
  border:1px dashed #777777;
  z-index:1;
  font-size:20px;
  height:700px;
  bottom:10px;
  display:flex;
  justify-content: center;
  align-items:center;
  flex-direction:column;
  &.align-bottom {
    width: calc(100% + 30px) !important;
    height:510px;
    bottom: 0px;
    transform: translate(-15px, 10px);
  }
}

.upload-test-button {
  margin-right: 2;
  margin-bottom: 15;
  margin-top: 10;
}

.styled-checkbox  {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  // Box.
  & + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white;
    box-shadow: 0 0 0 1px #276DD7;
  }

  // Box checked
  &:checked + label:before {
    background: #276DD7;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 9px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow:
      2px 0 0 white,
      4px 0 0 white,
      4px -2px 0 white,
      4px -4px 0 white,
      4px -6px 0 white,
      4px -8px 0 white;
    transform: rotate(45deg);
  }
}


.material-edit-treeselect{
  .ant-select-selector {
    height: 45px !important
  }
}
.collaborate-icon {
  align-items: center;
  text-align: center;
  min-width: 30px;
  width: fit-content;
  cursor: pointer;
  position: absolute;
  top: 12px;
  z-index: 1;
  left: 10px;
  border-radius: 4px;
  background: #262626;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
  &.right {
    right: 8px;
  }
  &.green {
    background: #52C41A;
  }
  &.white {
    background: white;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.08);
    &.flag {
      left: 50px;
    }
    &.flag-60 {
      left: 60px;
    }
    &.border {
      border: 1px solid rgb(240, 240, 240);
    }
  }
  &.width-unset {
    width: unset;
  }
}

.collaborate-img {
  padding: 4px;
  &.thumbnail-lifestyle {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 6px 8px 6px 8px;
  }
}

.align-button {
  position: relative;
  width:35% !important;
  background-color:rgba(210,210,210,0.5)  !important;
  border-radius:4px !important;
  height: 30% !important;
  margin-bottom: 6px !important;
  & img, span {
    vertical-align: -webkit-baseline-middle !important;
  }
  &.pointer{
    cursor: pointer !important;
  }
  &.not-allowed{
    cursor: not-allowed !important;
  }
  & img {
    position: absolute;
    top: 12.5%;
    left: 2%;
  }
  & span {
    position: absolute;
    top: 34%;
    left: 22%
  }
}

.custom-alert {
  padding: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background-color: #fafafa;
  color: #333;
  font-size: 14px;
  margin-bottom: 10px;
}

.custom-alert.info {
  background-color: #e6f7ff;
  border-color: #91d5ff;
  color: #1890ff;
}

.active-route-link-color{
  color: #000000D9;
}

.back-button-icon {
  cursor: pointer;
  margin-right: 16px;
}

.circle-bg {
  &.light-blue {
    background-color: lightblue;
  }
}
.sc-dropdown-menu-container {
  display:flex;
  flex-direction: row;
  margin-top: 20px;
}

.sc-dropdown-menu-tag {
  border:1px solid rgba(2, 2, 2, 0.04);
  background:rgba(2, 2, 2, 0.04);
  border-radius:2px;
  padding:5px 10px;
  margin-left: 12px ;
  cursor: pointer;
}

.sc-dropdown-menu-overlay {
  width: 250px;
  font-family: Avenir;
}

.sc-dropdown-suboption-container{
  display:flex;
  margin-top: 5px;
}

.camera-reticle {
  position: absolute;
  left: calc(50% - 20px);
  top : calc(50% - 20px) ;
  z-index:3;
  mix-blend-mode: difference;
  & img{
    width: 40px;
    pointer-events: none;
  }
  pointer-events: none;
}

.silo-tool-modal .ant-modal-body {
  padding: 4px 20px !important;
}

.presets-dropdown {
  padding: 12px 8px !important;
  width: 460px !important;
  background-color: #FFFFFF !important;
  border: 1px solid #D9D9D9 !important;
  border-radius: 5px !important;
  display: flex !important;
}

.model-inspector-viewer {
  height: calc(100vh - 300px);
  display: none;
  &.fullscreen {
    height: 100vh;
  }
}

.model-inspector-frame {
  width: 100%;
  height: 100%;
  border: none !important
}

.customer-main-layout-pd {
  margin-left: 80px;
  padding-left: 24px;
  padding-right: 24px;
}


.sc-add-product {
  display:flex;
  height:100%;
  justify-content: center;
  padding: 0;
  margin: 0;
  align-items: center;
  
}

.wrapper-style {
  width : 300;
  border-style: solid;
  border-width: 1px;
  border-color: #a9aaab;
  border-radius: 2px;
  }

.generate-asset-btn {
  text-wrap: nowrap;
  line-height: 100%;
  width: fit-content; 
  color:#276DD7; 
  background-color: #E6F7FF;
  padding-left: 10px;
  padding-right: 10px;
  padding: 6px 10px;
  border-radius: 4px; 
  cursor: pointer;
}

.new-tag {
  text-wrap: nowrap;
  line-height: 100%;
  width: fit-content; 
  color:#096DD9; 
  background-color: #E6F7FF;
  border-radius: 4px; 
  padding: 3px
}


.not-allowed-setting {
  cursor: not-allowed;
}

.pointer-none-setting {
  pointer-events: none;
}

.custom-input-suffix {
    .ant-input-suffix {
        margin-left: 0px;
      }
  }

.pointer {
  cursor: pointer;
}

.outline-dark-blue-btn {
    width: unset !important;
  height: fit-content !important;
  padding: 0 !important;
  background: #FFFFFF !important;
  color: #276DD7 !important;
  border: 1px solid #276DD7 !important;
  border-radius: 35px !important;
  &.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
    background: #FFFFFF !important;
    text-shadow: none;
    opacity: 0.5 !important;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  &:hover {
    background:#276DD7 !important;
    border: 1px solid #276DD7 !important;
    color: white !important;
  }
  &.square {
    border-radius: 4px !important;
    &:hover {
      background:#276DD7 !important;
      border: 1px solid #276DD7 !important;
      color: white !important;
    }
    &:active {
      background:#276DD7 !important;
      border: 1px solid #276DD7 !important;
      color: white !important;
    }
    &:focus {
      background:#276DD7 !important;
      border: 1px solid #276DD7 !important;
      color: white !important;
    }
   &.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
     background: white !important;
     opacity: 0.5 !important;
     text-shadow: none;
     -webkit-box-shadow: none;
     box-shadow: none;
   }
   &.ant-tooltip-disabled-compatible-wrapper {
     background:white !important;
     opacity: 0.5 !important;
     text-shadow: none;
     -webkit-box-shadow: none;
     box-shadow: none;
   }
  }

  &.font-14 {
    font-size: 14px !important;
    line-height: 20px;
    padding: 11px 32px !important;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    vertical-align: middle;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    &.pd {
      padding: 13px 75px !important;
      @media (max-width: 1366px) {
        padding: 13px 25px !important;
        font-size: 12px !important;
      }
      @media (max-width: 1440px) {
        font-size: 14px !important;
        padding: 13px 50px !important;

      }
    }
    &.pd-10-24 {
      padding: 10px 24px !important;
    }

    &.pd-10-18{
      padding: 10px 18px !important;
    }
  }

  &.font-12 {
    font-size: 12px !important;
    line-height: 120%;
    padding: 12px 24px !important;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    vertical-align: middle;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    &.small {
      padding: 12px 15px !important;
    }
  }

  &.w-900 {
    font-weight: 900;
  }

  &.w-600 {
    font-weight: 600;
  }

  &.adjust-size{
    font-size: 15px;
    line-height: 120%;
    padding: 7px 7px !important;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    vertical-align: middle;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    @media (max-width: 1600px) {
      font-size: 12px;
      padding: 4px 4px !important;
    }
    @media (max-width: 1350px) {
      font-size: 10px;
      padding: 4px 4px !important;
    }
    @media (max-width: 1200px) {
      font-size: 8px;
      padding: 4px 4px !important;
    }
  }
}

.camera-mode-sc {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.p-5 {
  &.p-right {
    padding-right: 5px;
  }

  &.p-top {
    padding-top: 5px;
  }

  &.p-bottom {
    padding-bottom: 5px;
  }
}

.m-10 {
  &.m-top {
    margin-top: 10px;
  }
}


.size-controls-sc{
  position: absolute;
  bottom: 24px;
  left: 24px;
  background-color: #ffffff;
  width: 327px;
  margin-top: 12px;
  margin-right: 8px;
  z-index: 1;
}

.size-controls-container {
  padding: 24px;
  background: 0.8;
  max-height: 500px; /* Set a fixed height */
  overflow-y: scroll; /* Enable vertical scrolling */
}

/* For WebKit browsers (Chrome, Edge, etc.) */
.size-controls-container::-webkit-scrollbar {
  width: 6px; /* Reduce scrollbar width */
}

.size-controls-container::-webkit-scrollbar-track {
  background: transparent; /* Background of the scrollbar track */
}

.size-controls-container::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.81); /* Color of the scrollbar thumb */
  border-radius: 4px; /* Rounder edges for the thumb */
}

.size-controls-container::-webkit-scrollbar-thumb:hover {
  background: rgba(85, 85, 85, 0.73);
}

.size-controls-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-top: 8px;
}

.size-controls-text {
  font-weight: 700;
  font-family: Manrope;
  font-size: 14px;
  line-height: 22px;

  &.slider-text {
    font-weight: 500;
    width: 80px;
  }
  &.reset {
    font-weight: 500;
    color: #276DD7;
    cursor: pointer;
  }
}

.size-controls-cross-button {
  position: absolute;
  right: -8px;
  top: -11px;
  border-radius: 50%;
  background: #D2281F;
  color: #FFFFFF;
  padding-left: 4px;
  padding-right: 4px;
  cursor: pointer; 
  &:hover {
    opacity: 0.6;
  }
}

.size-controls-number-input {
  width: 55% !important;
  height: 32px;
  margin-right: 5%;
  margin-left: 10px;
}

.size-controls-pin-icon-horizontal {
  padding-left: 5px;
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .horizontal-divider {
    width: 8px;
    height: 2px;
    background: #BFBFBF;
    opacity: 0.5;
    &.enabled{
      background: #276DD7;
      opacity: 1;
    }
  }
}

.size-controls-pin-icon-vertical {
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .pin-icon {
    cursor: pointer;
    transform: translateX(-50%);
  }
  .vertical-divider {
    width: 2px;
    height: 45px;
    background: #BFBFBF;
    opacity: 0.5;
    &.enabled {
      background: #276DD7;
      opacity: 1;
    }
  }
 
}

.ant-radio-wrapper {
  font-weight: 500;
}

.flip-product-button {
  border-radius: 4px;
  font-size: 16px;
  border: .5px solid #80808059;
  padding: 4px 12px;
  cursor: pointer;
  margin: 10px 0px;
  width: 100px;
  font-weight: 500 !important;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.snap-product-button {
  border-radius: 4px;
  font-size: 12px;
  border: .5px solid #80808059;
  padding: 4px 12px;
  cursor: pointer;
  margin-top: 10px;
  width: 135px;
  font-weight: 500 !important;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: #FFFFFF;
  color: #000000;
  &.selected {
    background-color: #276DD7;
    color: #FFFFFF;
  }
}

.silo-shot-setting {
  position: absolute;
  right: 2%;
  border: 1px solid;
  border-radius: 4px;
  padding: 1vh 3vw;
  display: flex;
  width: 8vw;
  place-content: center;
}

.silo-shot-setting-span {
  position: absolute;
  right: 2%;
  border: 1px solid;
  border-radius: 4px;
  padding: 1vh 0vw;
  display: flex;
  width: 8vw;
  place-content: center;
}

.silo-shots-setting-div{
  position: relative;
  display: flex;
  min-height: 4.5vh;
  align-items: center;
}

.setting-silo-dropdown {
  width: 7.5vw;
  padding: 5% 1%;
  text-align: center;
}

.carousel-container-lighting {
  display: flex; 
  height: 20%;
  width: 20vw;
  margin: auto;
  // place-content: center;
  .react-multiple-carousel__arrow{
    background: rgba(0,0,0,0.25) ;
    min-width: 34px !important;
    min-height: 34px !important;
   &:hover{
    background: rgba(0,0,0,0.8) ;
   } 
  }
}

.img-carousel-silo {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: contain;
  border: 1px solid #D9D9D9;
}

.item-carousel-silo { 
  width:5vw !important; 
  height:5vw;
  margin: 0.3vw !important;
}

.img-carousel-silo-selected { 
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: contain;
  border: 1px solid black;
}

.three-sixty-img {
  filter: blur(8px);
}
.info-icon-text {
  display: flex;
  height:fit-content;
  font-size: 13px;
  padding-top:30px;
  .bold {
    font-weight: 900;
  }
  text-transform: none;
}

.search-bar-width { 
  width: 34%;
  &.w-40 {
    width: 40%;
  }
}

.search-bar-border {
  border-radius: 4px !important;
}

.outer-container {
  width: 100%;
  height: 230px;
  background: #FFFFFF;
  border: 1px solid #E3E2E2;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.outer-container:hover {
  transition: box-shadow .3s;
  box-shadow: 0 0 11px rgba(33, 33, 33, .2);
}

.outer-container:hover .icon-container {
  opacity: 1;
}


.inner-container {
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;

  .img-cont {
      width: 100%;
      max-height: 230px;
  }
}

.icon-container {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  right: 0px;
  top: 10px;
  text-align: center;
}

.expand-icon {
  position: absolute;
  right: 0px;
  top: 0px;
  margin-right: 20px;
  background: rgba(0, 0, 0, 0.2);
  color: #FFFFFF;
  font-size: 28px;
  border-radius: 2px;
  cursor: pointer;
}

.img-details-container {
  position: absolute;
  left: 0px;
  bottom: 0px;
  margin-left: 20px;
  margin-bottom: 20px;
  background: #FFFFFF;
  border-radius: 4px;
  padding: 8px;
  line-height: 100%;
  border: 1px solid #E3E2E2;
}

.img-modal {
  .ant-modal-body {
      min-height: 256px;
  }

  .ant-modal-close-icon {
      color: #FFFFFF;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 2px;
      padding: 5px;
  }
}

.img-loader {
  height:100%;
  min-height: 256px;
  background: white;
}

.render-img {
  max-height: 90vh;
  min-width: 25vw;
}
.ant-select-tree-title {
    color: black !important; /* Override the default grey color */
}

.menu-item-disabled {
  color: #ccc; /* Greyed out color */
  pointer-events: none; /* Disable click events */
  opacity: 0.5; /* Reduced opacity */
}

.image-banner {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust background color and opacity as needed */
  color: white; /* Text color */
  padding: 10px; /* Padding around the text */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Center align text horizontally */
  align-items: center; /* Center align text vertically */
  text-align: center; /* Center align text within the banner */
  pointer-events: none; /* Prevent the banner from capturing mouse events */
  z-index: 1; /* Ensure the banner is above the image */
  width: 100%;
}

.image-banner-text {
  margin: 0;
  // font-size: 10px; /* Adjust font size */
  white-space: nowrap; /* Prevent text from wrapping */
}

.justified-grid-gallery {
    --space: 4px;
    --min-height: 190px;
    --last-row-background: rgb(188, 234, 153);

    display: flex;
    flex-wrap: wrap;
    gap: var(--space);
    list-style: none;
    margin: 0 !important;
    padding: 0 !important;
}

.justified-grid-gallery > * {
    position: relative;
    overflow: hidden;
    margin: 0 !important;
    padding: 0 !important;
}

.justified-grid-gallery > * > img {
    position: absolute;
    width: 100%;
    height: 100%;
}

.justified-grid-gallery::after {
    content: " ";
    flex-grow: 1000000000;
    //background: var(--last-row-background);
}

.image-setting-feedback { 
  width: 70px;
  height: 70px;
  border-radius: 4px;
  margin-right: 10px;
  object-fit: scale-down;
}

.free-rotation-toggle {
  position: relative;
  height: fit-content;
  top: 10px;
  margin-bottom: 30px;
}


.ReactGridGallery_tile-viewport {
  border-radius: 4px;
  border: 1px solid rgba(204, 204, 204, 1);

}


.ReactGridGallery_custom-overlay {
  opacity: 1 !important;
}

.ReactGridGallery_tile {
  &:hover {
    .hover-info {
      opacity: 1 !important;
    }
  }
}

.pointer-event-auto {
  pointer-events: auto !important;
}


.custom-overlay {
  position: relative;
  width: 100%;
  height: 100%;
}

.hover-info {
  position: absolute;
  bottom: 0px; /* Adjust as needed */
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 0;
  width: 100%;
  opacity: 0;
  transition: opacity 0.3s ease;
 
}


.custom-overlay:hover .hover-info {
  opacity: 1 !important;
}


.outline-blk-btn {
  width: unset !important;
  height: fit-content !important;
  padding: 0 !important;
  background: #FFFFFF !important;
  color: #000000 !important;
  border: 1px solid #000000 !important;
  border-radius: 35px !important;
  &.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
    background: #FFFFFF !important;
    text-shadow: none;
    opacity: 0.5 !important;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  &:hover {
    background:#000000 !important;
    border: 1px solid #000000 !important;
    color: white !important;
  }
  &.square {
    border-radius: 4px !important;
    &:hover {
      background:#000000 !important;
      border: 1px solid #000000 !important;
      color: white !important;
    }
    &:active {
      background:#000000 !important;
      border: 1px solid #000000 !important;
      color: white !important;
    }
    &:focus {
      background:#000000 !important;
      border: 1px solid #000000 !important;
      color: white !important;
    }
   &.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
     background: white !important;
     opacity: 0.5 !important;
     text-shadow: none;
     -webkit-box-shadow: none;
     box-shadow: none;
   }
   &.ant-tooltip-disabled-compatible-wrapper {
     background:white !important;
     opacity: 0.5 !important;
     text-shadow: none;
     -webkit-box-shadow: none;
     box-shadow: none;
   }
  }

  &.width-fit-content {
    width: fit-content !important;
  }

  &.font-14 {
    font-size: 14px !important;
    line-height: 20px;
    padding: 11px 32px !important;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    vertical-align: middle;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    &.pd {
      padding: 13px 75px !important;
      @media (max-width: 1366px) {
        padding: 13px 25px !important;
        font-size: 12px !important;
      }
      @media (max-width: 1440px) {
        font-size: 14px !important;
        padding: 13px 50px !important;

      }
    }

    &.pd-10-24 {
      padding: 10px 24px !important;
    }

    &.pd-10-18{
      padding: 10px 18px !important;
    }
  }

}
.button-checked-disabled {
  background: #808080 !important;
  color: white !important;
  cursor: not-allowed;
}
.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.children-full {
  & > * {
    width: 100% !important;
    height: 100% !important;
  }
}

.w-full{
  width: 100% !important;
}

.h-full{
  height: 100% !important;
}

.children-full{
  & > * {
    width: 100% !important;
    height: 100% !important;
  }
}

.mx-none{
  margin-left: 0px;
  margin-right: 0px;
}

.flex{
  display: flex !important;
}

.img-fav-icon {
  background: rgba(255, 255, 255, 1);
  border-radius: 2px;
  padding: 3px 4px 0px 4px;
  cursor: pointer;
  margin-right: 8px;
  z-index: 1;
  border-radius: 4px;
  align-content: center;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
}

.img-fav-icon-modal {
  background: rgba(255, 255, 255, 1);
  border-radius: 2px;
  padding: 7px;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
  }
.embed-product-web {
  @media (max-width: 849px) {
    display: none;
  }
}

.embed-product-mobile {
  display: flex; /* Enable flexbox */
  flex-direction: column; /* Stack the div and buttons vertically */
  justify-content: space-between; /* Space between the top div and buttons */
  align-items: center; /* Center elements horizontally */
  height: 85vh; /* Maintain the original height */
  overflow: hidden; /* Keep overflow hidden as before */

  @media (min-width: 850px) {
    display: none; /* Hide on larger screens */
  }
}

.model-viewer-public {
  height: 100%;
  height: 100svh;
  width: 100vw;
  position: relative;
  overflow: hidden;

  .viewer-iframe-container {
    height: 85%;
    width: 100vw;

    @media (min-width: 1367px) {
      height: 90%;
    }
  }
  
  .view-ar-btn {
    width: 100%;
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .add-model-button{
    width: 160px;
    background-color: white !important;
    border: 1px solid #000 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 5px;
    color: #000 !important;
    height: 40px;
    img{
      height: 100%;
      margin-right: 5px;
    }

    &.font-12-em {
      font-size: 1.15em !important;

      @media screen and (min-width: 600px) and (max-width: 1024px) and (min-height: 600px) {
        font-size: 1.5em !important;
      }
    }

    @media screen and (min-width: 600px) and (max-width: 1024px) and (min-height: 600px) {
      width: 220px;
      height: 50px;
    }

    @media screen and (max-height: 600px) {
      position: absolute;
      top: -30px;
    }
  }
}

@supports not (-webkit-appearance: none) {
  .embed-product-mobile {
    height: 100vh !important; /* Example height for Firefox */
  }
}

.product-details-carousel {
  // @media (min-width: 850px) {
  //   display: none;
  // }
  width: 100vw;
  height: 100%;
  overflow: hidden;
  align-content: center;
  .ant-carousel .slick-dots {
    visibility: visible !important;
  }
  .ant-carousel .slick-dots li {
    background: #b3cde0 !important;
  }
  
  .ant-carousel .slick-dots li.slick-active button {
    background: #011f4b !important; 
  }

  .slick-track {
    display: flex;
    align-items: center;
  }
  
  .ant-carousel {
    height: 100% !important;
  }
}

/* Flex container for the buttons */
.button-container {
  width: 100%; /* Full width of the parent container */
  display: flex; /* Enable flexbox */
  justify-content: space-between; /* Space between the buttons */
  padding: 0 5px; /* Optional padding on the left and right */
}

.view-3d-btn, .view-ar-btn {
  width: 25vw; /* Button width */
  height: 44px; /* Ensure tappable size */
  touch-action: manipulation; /* Prevents delays on click */
  transform: translateZ(0);
  -webkit-transform: translateZ(0); /* For hardware acceleration */
}



@media (min-width: 992px) {
  .web-desktop-width {
    max-width: 500px;
    width: 500px;
    margin: 0px auto;
  }
  .hide-on-mobile {
    display: block; 
  }
  .show-on-mobile {
    display: none;
  }
}

@media(min-width: 992px) and (max-width: 1016px) {
  .hide-on-mobile {
    margin: auto; 
  }
}

@media (max-width: 991px) {
  .hide-on-mobile {
    display: none;
  }
  .show-on-mobile {
    display: block;
  }
  .justify-on-mobile {
    flex-direction: column;
    align-items: center;
  }
}

.mt-24 {
  margin-top: 24px;
}

.h-50 {
  height: 50px !important;
}


.generate-ai-btn {
  width: unset !important;
  height: unset !important;
  padding: 0 !important;
  background: linear-gradient(93.3deg, #7883dc 9.81%, #276dd7 52.44%, #70c9ff 95.08%) !important;
  color: white !important;
  border: unset !important;
  border-radius: 4px !important;

  &.ant-btn[disabled],
  .ant-btn[disabled]:hover,
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:active {
      background: linear-gradient(93.3deg, #7883dc 9.81%, #276dd7 52.44%, #70c9ff 95.08%) !important;
      text-shadow: none;
      opacity: 0.5 !important;
      -webkit-box-shadow: none;
      box-shadow: none;
  }

  &:hover {
      background: linear-gradient(93.3deg, #7883dc 9.81%, hwb(216 15% 16%) 52.44%, #70c9ff 95.08%) !important;
      border: unset !important;
      color: white !important;
  }

  &.ant-tooltip-disabled-compatible-wrapper {
      background: linear-gradient(93.3deg, #7883dc 9.81%, #276dd7 52.44%, #70c9ff 95.08%) !important;
      opacity: 0.5 !important;
      text-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
  }

  &.font-16 {
      font-size: 16px !important;
      line-height: 24px;
      padding: 11px 32px !important;
      font-family: Manrope;
      font-style: normal;
      font-weight: 700;
      vertical-align: middle;
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: capitalize;
  }

  &.font-14 {
      font-size: 14px !important;
      line-height: 120%;
      padding: 11px 24px !important;
      font-family: Manrope;
      font-style: normal;
      font-weight: 500;
      vertical-align: middle;
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: capitalize;
  }
}

.blur-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color:  rgba(0, 0, 0, 0.4);
  color: white;
  z-index: 2
}

.ReactGridGallery_tile:hover .ai-overlay {
  display: none;
}
.selection-ui {
  background-color: rgba(255, 255, 255, 1);
  box-shadow: rgba(0, 0, 0, 0.2) 3px 3px 5px;
  position: absolute;
  bottom: 10px;
  padding: 1vh 0.2vw;
  border-radius: 6px;
  left: 50%;
  transform: translateX(-50%);
  display: none;
  height: fit-content;
  z-index: 2;
}

.selection-ui input {
  margin: 0 0.8vw;
}

.selection-ui input:first-of-type {
  margin-right: 0.8vw;
}

.selection-ui input:last-of-type {
  margin-left: 0.8vw;
}

.selection-ui-button {
  width: 1.5vw;
}

.selection-ui-divider {
  width: 1px;
  height: 1.5vw;
  background-color: rgba(0, 0, 0, 0.2);
}


.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #276DD7; 
  border-color: #276DD7;
}

.qaChecklistBox{
  flex-direction: column;
  display: flex;
  border: 0.1px solid #D9D9D9;
  padding: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.scene-tag {
  background: #E6FFFB;
  color: #08979C;
}

.tag-section {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.tag {
  background: #F0F0F0;
  padding: 5px 10px;
  color: black;
  border-radius: 5px;
  margin: 5px;
}

.card-name {
  word-wrap: break-word;
}

.entity-name {
  color: black;
  text-align: start;
  padding: 2px 8px;
  height: 50px;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.scene-empty-container {
  display: flex;
  justify-content: center;
  height: 62vh;
  background-color: #ffffff;
}

.scene-empty-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 20px;
  flex: 1;
  margin-top: 20vh;
}

.scene-empty-icon {
  font-size: 40px;
  color: #000;
  margin-bottom: 15px;
}

.scene-empty-title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
  color: #000;
}

.scene-empty-subtitle {
  font-size: 14px;
  color: #000000;
  margin-bottom: 20px;
}

.scene-empty-button:hover {
  background-color: #1f5cb2;
}

.scene-empty-divider {
  font-size: 16px;
  color: #aaa;
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scene-empty-link-wrapper {
  margin-top: 15px;
  font-size: 14px;
  color: #666;
}

.scene-empty-link {
  color: #276dd7;
  text-decoration: none;
  font-weight: 500;
}

.scene-empty-link:hover {
  text-decoration: underline;
}

.scene-empty-divider-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
}

.scene-empty-line {
  width: 1px;
  height: 30vh;
  background-color: #D9D9D9;
}

.scene-empty-divider-text {
  font-size: 20px;
  color: #000000;
  line-height: 28px;
  margin: 5px 0;
}

.compant-table-icons {
  font-size: 20px;
  color: #276DD7;
}

.contract-button {
  height: 38px;
  padding: 8 12px;
  border-radius: 4px;
  align-items: center;
  display: flex;
  justify-content: space-around;
  background-color: white;
  border: 1px solid #000000;
  font-size: 14px;
  font-weight: 700;
}

.contract-date {
  padding: 4px 8px;
  background-color: #FAFAFA;
  margin-right: 24px;

  &.urgent{
    background-color: #FFF1F0;
    color: #FF4D4F;
  }

  &.moderate{
    background-color: #FFFBE6;
    color: #FAAD14;
  }
}
.vertical-correction-switch-popover .ant-popover-inner-content {
  max-width: 400px !important;
}

.vertical-correction-switch-popover-disabled .ant-popover-arrow {
  left: 80% !important;
}
.disabled-ai-btn {
  background-color: #d3d3d3;
  cursor: not-allowed !important;
  pointer-events: none; /* Prevent click */
  color: #888; /* Optional: change text color */
  opacity: 0.6; /* Optional: give a faded look */
}

.timed-out-ai-job-container {
  border-radius: 4px;
  border: 1px solid #D93025;
  background: #FAFAFA;
  padding: 16px;
}